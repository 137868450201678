.add-by-link_header > div {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.add-by-link_header > div > input {
    border: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 15px;
    height: 42px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #36434B;
    background: #F5F5F5;
    border-radius: 6px;
}

.add-by-link_header-music {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.add-by-link_header-music > img {
    margin-right: 10px;
}

.add-by-link_header-music_text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #36434B;
}

.add-by-link_header-music_text > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #FEA65A;
}

.add-by-link_header-music_duration {
    margin-left: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #9F9F9F;
}