.home-fine-grave-page {
  background: #f2f5f8;
  width: 100%;
}

.homepage-findgrave {
  max-width: 1280px;
  margin: 0px auto;
  padding-bottom: 50px;
  background: #f2f5f8;
}
