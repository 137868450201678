.image {
  border-radius: 2px;
  width: 228px;
  height: 138px;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
  border: 1px solid gray;
}

.selected-image {
  opacity: 0.5 !important;
  border-radius: 2px;
  width: 228px;
  height: 138px;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
  border: 1px solid gray;
}

@media screen and (max-width: 750px) {
  .image {
    width: 100px;
    height: 100px;
  }

  .selected-image {
    width: 100px;
    height: 100px;
  }
}
