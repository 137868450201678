.homepage-investigate_third {
    width: 100%;
    margin-bottom: 168px;
}

.homepage-investigate_third-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10%;
    position: relative;
}

.homepage-investigate_third-content_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepage-investigate_third-content_main-box {
    width: 500px;
    background: #FFFFFF;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.16);
    border-radius: 10px 0px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 100px 115px 105px;
    align-self: flex-start;
    margin-bottom: 92px;
}

.homepage-investigate_third-content_main-box.second {
    padding: 53px 70px 97px;
    align-self: flex-end;
    margin-bottom: 0px;
}

.homepage-investigate_third-content_main-box_header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #36434B;
    margin-bottom: 24px;
}

.homepage-investigate_third-content_main-box_header > span {
    color: #FEA65A;
}

.homepage-investigate_third-content_main-box_title {
    min-width: 278px;
    font-family: Minion Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #999999;
}

.homepage-investigate_third-content_main-first {
    position: absolute;
    top: 17.3%;
    left: 0;
    z-index: -10;
}

.homepage-investigate_third-content_main-second {
    position: absolute;
    right: 0;
    z-index: -10;
    bottom: -5%;
}

.homepage-investigate_third-content_main-flower {
    position: absolute;
    right: 33%;
    left: 33%;
    z-index: -1;
    bottom: -5%;
    max-width: 100%;
}

.homepage-investigate_third-content_main-box.first {
    z-index: -2;
}

.homepage-investigate_third-content_main-first_mobile, .homepage-investigate_third-content_main-second_mobile {
    display: none;
}

@media (max-width: 800px) {
    .homepage-investigate_third-content_main-first, .homepage-investigate_third-content_main-second {
        display: none;
    }

    .homepage-investigate_third-content_main-first_mobile, .homepage-investigate_third-content_main-second_mobile {
        display: block;
    }

    .homepage-investigate_third-content_main-flower {
        width: 400px;
        height: 654px;
        bottom: 0;
        /* left: 75%; */
    }

    .homepage-investigate_third-content_main-box.first {
        z-index: 0;
    }

    .homepage-investigate_third-content_main-first_mobile {
        position: absolute;
        z-index: -10;
        left: 0;
        top: -13%;
    }

    .homepage-investigate_third-content_main-second_mobile {
        position: absolute;
        z-index: -10;
        right: 0;
        bottom: -10%;
    }

    .homepage-investigate_third {
        margin-bottom: 113px;
    }
    
    .homepage-investigate_third-content_main-box {
        align-self: center;
        width: 100%;
        padding: 72px 16px;
        margin-bottom: 63px;
        min-height: 237px;
    }

    .homepage-investigate_third-content_main-box.second {
        align-self: center;
        padding: 53px 16px;
    }

    .homepage-investigate_third-content {
        padding: 0;
    }

    .homepage-investigate_third-content_main-box_header {
        font-size: 20px;
        line-height: 23px;
    }

    .homepage-investigate_third-content_main-box_title {
        font-size: 16px;
        line-height: 19px;
    }
}

@media (max-width: 720px) {
    .homepage-investigate_third-content_main-flower {
        bottom: 0;
        /* left: 70%; */
    }
}

@media (max-width: 850px) {
    .homepage-investigate_third-content_main-flower {
        bottom: 0;
        left: 0;
    }
}
