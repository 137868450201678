.memories-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 12px;
}

.memories-header_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.memories-header_left > span {
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
}

.memories-header_right-icon {
  margin-right: 5px;
}

.form-control {
  width: 130px !important;
}

.memories-header_right-select {
  height: inherit !important;
  background-color: inherit !important;
}

.memories-header_right-select:focus {
  background-color: inherit !important;
}

.memories-header_right-select > div {
  display: flex !important;
  text-align: center !important;
  background: inherit !important;
  padding: 0px 25px 1px 10px !important;
}
