.set-user-connection-modal {
    display: flex;
    border-radius: 10px;
    background-color: #fff;
    max-height: calc(100vh - 65px);
    min-width: 400px;
}

.set-user-connection-modal_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 15px 36px 15px;
    width: 100%;
}

.set-relation-relation-combobox {
    background: #F5F5F5;
    border-radius: 6px;
    width: 80%;
    margin: 0 5px 20px 5px;
    height: 40px;
}

.set-user-connection-modal_content-members {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 19px;
    grid-column-gap: 39px;
    margin-bottom: 37px;
    overflow-y: scroll;
}

.set-user-connection-modal_content-my.disabled {
    display: none;
}

.set-user-connection-modal_content-close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.set-user-connection-modal_content-close > img {
    cursor: pointer;
    transition-duration: 0.3s;
}

.set-user-connection-modal_content-close > img:hover {
    opacity: 0.6;
}

.set-user-connection-modal_content-confirm > button:disabled {
    background: #D8D8D8;
}

.relation-combobox-title {
    margin-bottom: 10px;
}

.set-user-connection-modal_content-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 20px;
}

.set-user-connection-modal_content-header > img {
    margin-right: 15px;
    width: 40px;
    height: 30px;
}

.set-user-connection-modal_content-header > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    color: #36434B;
}

.set-user-connection-modal_content-header > span > span {
    text-transform: capitalize;
}

.set-user-connection-modal_content-subheader {
    margin-bottom: 39px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.set-user-connection-modal_content-subheader > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #9F9F9F;
}

.set-user-connection-modal_content-select {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
}

.set-user-connection-modal_content-my {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
}

.set-user-connection-modal_content-my > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #36434B;
}

.set-user-connection-modal_content-my > span > span:first-child {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FEA65A;
    text-transform: capitalize;
}

.set-user-connection-modal_content-my > span > span:last-child {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #9F9F9F;
}

.set-user-connection-modal_content-select_select {
    border: none;
    outline: none;
    background: #F5F5F5;
    border-radius: 10px;
    width: 224px;
    height: 40px;
    box-sizing: border-box;
    padding-left: 40px;
    margin-bottom: 39px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #36434B;
    background-repeat: no-repeat;
    background-position: 90%;
    appearance: none;
}

.step-2-user-connection-content-wrapper {
  display: flex;
  justify-content: center;
  margin: 70px 0px;
}

.user-connections-grid-item {
  min-height: 270px;
  border: 1px solid lightgray;
  border-radius: 7px;
  text-align: center;
  padding-top: 15px;
  min-width: 240px;
}

.set-relation_display_image_wrapper {
  display: grid;
  min-height: 210px;
}

.set-relation_display_image {
    box-sizing: border-box;
    margin-bottom: 20px;
    width: 190px;
    max-height: 210px;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    padding: 0px;
}

.user-connection-avatar {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.no-gender-avatar {
    width: 68%;
}

.helper-span > span {
    font-family: 'Minion Pro';
    font-size: 18px;
    color: #36434B;
}
.helper-span {
    margin-bottom: 15px;
}

@media (max-width: 750px) {
    .set-user-connection-modal {
        width: 375px;
        margin: 0 auto;
    }

    .set-user-connection-modal_content {
        margin: 20px 16px;
    }

    .set-user-connection-modal_content-members {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 19px;
        grid-column-gap: 9px;
        margin-bottom: 77px;
        margin-bottom: 20px;
    }

    .set-user-connection-modal_content-header {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .set-user-connection-modal_content-header > img {
        margin-bottom: 14px;
        margin-right: 0px;
    }

    .set-user-connection-modal_content-header > span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #36434B;
    }

    .set-user-connection-modal_content-subheader {
        margin-bottom: 20px;
    }

    .set-user-connection-modal_content-subheader > span {
        text-align: center;
    }

    .set-user-connection-modal_content-select {
        margin-bottom: 30px;
    }

    .set-user-connection-modal_content-my {
        margin-bottom: 20px;
    }

    .step-2-user-connection-content-wrapper {
        display: block;
    }

    .set-user-connection-modal {
        min-width: unset;
    }

    .set-user-connection-modal_content-confirm {
        padding-bottom: 20px;
    }
}

.set-user-connection-modal_content-confirm {
    text-align: center;
}