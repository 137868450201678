.image-container {
  background: #fff;
  /* height: 800px; */
  width: 100%;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 8px;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px;
  width: 100%;
}

.cropper-modal_modal,
.grave-picture-gallery-modal {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.cropper-modal_modal > div:last-child > div {
  border-radius: 10px !important;
}

.grave-picture-gallery-modal {
  background-color: rgba(73, 73, 73, 1) !important;
}

.deceased-header_img-loading {
  background-image: url('../../service/Loading_animation.gif');
  background-size: 150px;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.images-list-section {
  padding: 12px;
}

.images-list-container {
  display: flex;
  gap: 8px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.images-list-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.images-list-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.images-list {
  display: flex;
  gap: 12px;
}

.add-media-container {
  display: none;
}

.cover-backgroundPhoto {
  width: auto;
  height: 600px;
  border-radius: 10px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  position: relative;
}

.coverPhoto_infoBox {
  background: #fdfdfd;
  border-radius: 10px;
  width: 501px;
  display: flex;
  position: absolute;
  right: 0;
  top: 24px;
  margin-right: 40px;
}

.coverPhoto_infoBox .left {
  display: flex;
  flex-direction: column;
  padding: 0 3px 0 26px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin: 17px 0 14px 0;
}

.right {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 133.187%;
}

.upload-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.image-text-message {
  color: #404d56;
  text-align: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 133.187%;
}

.upload-image-input {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.upload-image-text {
  color: #fea65a;
  text-align: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 170%;
}

@media screen and (max-width: 750px) {
  .image-container {
    /* height: 775px; */
    border-radius: 0;
  }

  .empty-container {
    height: 500px;
  }
  .images-list-container {
    display: none;
    padding: 0;
    margin: 0;
  }

  .add-media-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 12px 0px;
    gap: 24px;
    flex-wrap: wrap;
  }

  .all-media-btn {
    color: #fea65a;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }

  .cover-backgroundPhoto {
    border-radius: 0;
  }

  .coverPhoto_infoBox {
    position: absolute;
    z-index: 10;
    width: auto;
    top: 17%;
    right: 7px;
    margin: 0;
  }
}
