.heading {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.heading > span {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}

.heading > img {
  cursor: pointer;
  transition-duration: 0.3s;
}

.heading > img:hover {
  opacity: 0.6;
}

.grave-picture-gallery_photos-cropper {
  width: 100% !important;
}

.grave-picture-gallery_photos-cropper > img {
  width: 100% !important;
  /* height: 100vh; */
  opacity: 1 !important;
}

.cropper {
  height: 600px;
  background: #ddd;
}

.cropper-container {
  position: relative;
}

.cropper-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.save {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 13px 32px;
}

.cancel {
  cursor: pointer;
  border: none;
  padding: 12px 30px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.image-loader {
  background: rgba(39, 47, 52, 0.5);
  color: #fff;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
