.video-comment {
  background-color: #ffffff;
}

.video-comment_content-video {
  /* max-width: 100%; */
  width: 100% !important;
  height: 400px !important;
}

.video-comment_content {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: stretch;
  position: relative;
}

.delete-btn {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  position: absolute;
  top: 10px;
  right: 10px;
}

.delete-btn:hover {
  background-color: #cccc;
}

.input-wrapper {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  background: #ffff;
  padding: 14px 0px;
}
.input-wrapper > input {
  background: #ffff;
  width: 100%;
  color: #36434b;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
}

@media (max-width: 750px) {
  .video-comment_content-video {
    width: 100% !important;
    height: 189px !important;
  }

  .video-comment_content {
    width: 100%;
  }
}

.video-container {
  position: relative;
  /* margin-bottom: 24px; */
  /* height: 483px; */
  width: 100%;
}

.video-container > video {
  width: 100%;
  height: 400px;
  cursor: pointer;
  background: #000;
}

.video-container > button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  position: absolute;
  top: 24px;
  right: 24px;
}

.video-container > button:hover {
  background-color: #cccc;
}

.video-container > button > img {
  height: 30px;
  width: 30px;
}

.grave-picture-gallery_photos-photo {
  width: auto;
  /* height: 600px; */
  cursor: pointer;
  position: relative;
  background-color: #000;
}

.gallery_photos-photo {
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
}

.grave-picture-gallery_photos-navigations_dot {
  min-width: 0px !important;
}

.buttons-prev {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 6px 6px 0px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 40%;
  left: 0;
  cursor: pointer;
}

.buttons-next {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px 0px 0px 6px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 40%;
  right: 0;
  cursor: pointer;
}

.show-buttons {
  opacity: 1;
}

.dots-container {
  display: none;
}

.upload-cover-image {
  /* background: #e7e6e9;*/
  background: rgba(39, 47, 52, 0.5);
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 8px;
  width: max-content;
  transition: 0.2s;
}

.upload-cover-image:hover {
  background: rgba(39, 47, 52, 0.7);
}

.camera-title {
  color: #fff;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
}

/* .title-wrapper { */
/* position: absolute;
  bottom: 0; */
/* width: calc(100% - 32px);
  height: 30px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background: #0000004d;
  opacity: 1;
} */

.edit-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  font-family: 'Roboto' Condensed;
  border: none;
  outline: none;
  padding: 16px 0px;
  color: #36434b;
  cursor: text;
}

.edit-button {
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  /* .buttons-prev,
  .buttons-next {
    display: none;
  } */
  .grave-picture-gallery_photos-photo {
    height: 400px;
    border-radius: 0;
  }
  .gallery_photos-photo {
    border-radius: 0;
  }
  .dots-container {
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    overflow-x: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .dots-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .dots-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media screen and (max-width: 480px) {
  .camera-title {
    display: none;
  }

  .upload-cover-image {
    padding: 8px;
    background: #000;
    border-radius: 50%;
    bottom: 25px;
    right: 15px;
  }
}
