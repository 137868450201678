.group-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.switch-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #77848d;
  border-radius: 10px;
  padding: 18px;
}

.active {
  border-color: #fea65a;
}

.warning-message {
  margin-bottom: 12px;
  background-color: #f6dfcb;
  padding: 12px;
  border-radius: 10px;
  color: #c65c00;
}

.action {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #77848d;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.action > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.action > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.action > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.action > label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #404d56;
  cursor: pointer;
}

.remove-message {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #404d56;
  margin-bottom: 24px;
}

.family-message {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}

.count-number-message {
  margin-bottom: 12px;
  color: #36434b;
  font-family: 'Roboto';
}

.subscription-user-count {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 24px;
  line-height: 19px;
  color: #19262d;
}

.valid-subscription-user-count {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 8px;
  color: #404d56;
}

.subscribe-btn {
  width: 100%;
  margin-top: 12px;
}

.subscribe-btn > button {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #404d56;
  border-radius: 10px;
  color: #fbfbfc;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 18px;
  line-height: 170%;
  padding: 12px 0px;
}

.subscribe-btn > button:disabled {
  background: #cbcbcb !important;
  cursor: not-allowed;
}

.total-price-details {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
  align-items: center;
}

.plan-amount-currency {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 32px;
  color: #19262d;
  text-transform: capitalize;
}

.plan-amount-currency-family {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 32px;
  color: #ea9449;
  text-transform: capitalize;
}

.plan-amount-price {
  color: #565560;
  font-family: 'Roboto';
  font-size: 23px;
  font-weight: 400;
}

.total-counting {
  color: rgba(0, 0, 0, 0.4);
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
}

@media screen and (min-width: 720px) {
  .container {
    max-height: 500px;
    overflow-y: auto;
  }

  .container::-webkit-scrollbar {
    display: none;
  }

  .container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
