.event-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.event-wrapper {
  width: 100%;
}

.image-list {
  display: flex;
  gap: 8px;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
  height: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.image-list ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.image-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
