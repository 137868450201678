.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 500px;
  padding: 0 24px;
}

.error {
  font-size: 200px !important;
  color: #ea9449;
}
