.nettgrav-default-selection-buttons {
  border-radius: 8px;
  border: 1px solid #36434B;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  min-width: 90px;
  padding: 0 15px;
  cursor: pointer;
}

.nettgrav-default-selection-buttons:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}

.submit-button {
  color: #36434B;
  text-align: center;
  background-color: #fff;
}

.submit-button:disabled {
	background-color: #8080801c;
	cursor: not-allowed;
}

.cancel-button {
  background: #36434B;
  color: #FFF;
}








/* Margin Rights */

.m-r-0 {
	margin-right: 0px;
}

.m-r-1 {
	margin-right: 1px;
}

.m-r-2 {
	margin-right: 2px;
}

.m-r-3 {
	margin-right: 3px;
}

.m-r-4 {
	margin-right: 4px;
}

.m-r-5 {
	margin-right: 5px;
}

.m-r-6 {
	margin-right: 6px;
}

.m-r-7 {
	margin-right: 7px;
}

.m-r-8 {
	margin-right: 8px;
}

.m-r-9 {
	margin-right: 9px;
}

.m-r-10 {
	margin-right: 10px;
}

.m-r-11 {
	margin-right: 11px;
}

.m-r-12 {
	margin-right: 12px;
}

.m-r-13 {
	margin-right: 13px;
}

.m-r-14 {
	margin-right: 14px;
}

.m-r-15 {
	margin-right: 15px;
}

.m-r-16 {
	margin-right: 16px;
}

.m-r-17 {
	margin-right: 17px;
}

.m-r-18 {
	margin-right: 18px;
}

.m-r-19 {
	margin-right: 19px;
}

.m-r-20 {
	margin-right: 20px;
}

.m-r-21 {
	margin-right: 21px;
}

.m-r-22 {
	margin-right: 22px;
}

.m-r-23 {
	margin-right: 23px;
}

.m-r-24 {
	margin-right: 24px;
}

.m-r-25 {
	margin-right: 25px;
}

.m-r-26 {
	margin-right: 26px;
}

.m-r-27 {
	margin-right: 27px;
}

.m-r-28 {
	margin-right: 28px;
}

.m-r-29 {
	margin-right: 29px;
}

.m-r-30 {
	margin-right: 30px;
}

.m-r-31 {
	margin-right: 31px;
}

.m-r-32 {
	margin-right: 32px;
}

.m-r-33 {
	margin-right: 33px;
}

.m-r-34 {
	margin-right: 34px;
}

.m-r-35 {
	margin-right: 35px;
}

.m-r-36 {
	margin-right: 36px;
}

.m-r-37 {
	margin-right: 37px;
}

.m-r-38 {
	margin-right: 38px;
}

.m-r-39 {
	margin-right: 39px;
}

.m-r-40 {
	margin-right: 40px;
}

.m-r-41 {
	margin-right: 41px;
}

.m-r-42 {
	margin-right: 42px;
}

.m-r-43 {
	margin-right: 43px;
}

.m-r-44 {
	margin-right: 44px;
}

.m-r-45 {
	margin-right: 45px;
}

.m-r-46 {
	margin-right: 46px;
}

.m-r-47 {
	margin-right: 47px;
}

.m-r-48 {
	margin-right: 48px;
}

.m-r-49 {
	margin-right: 49px;
}

.m-r-50 {
	margin-right: 50px;
}

.m-r-51 {
	margin-right: 51px;
}

.m-r-52 {
	margin-right: 52px;
}

.m-r-53 {
	margin-right: 53px;
}

.m-r-54 {
	margin-right: 54px;
}

.m-r-55 {
	margin-right: 55px;
}

.m-r-56 {
	margin-right: 56px;
}

.m-r-57 {
	margin-right: 57px;
}

.m-r-58 {
	margin-right: 58px;
}

.m-r-59 {
	margin-right: 59px;
}

.m-r-60 {
	margin-right: 60px;
}

.m-r-61 {
	margin-right: 61px;
}

.m-r-62 {
	margin-right: 62px;
}

.m-r-63 {
	margin-right: 63px;
}

.m-r-64 {
	margin-right: 64px;
}

.m-r-65 {
	margin-right: 65px;
}

.m-r-66 {
	margin-right: 66px;
}

.m-r-67 {
	margin-right: 67px;
}

.m-r-68 {
	margin-right: 68px;
}

.m-r-69 {
	margin-right: 69px;
}

.m-r-70 {
	margin-right: 70px;
}

.m-r-71 {
	margin-right: 71px;
}

.m-r-72 {
	margin-right: 72px;
}

.m-r-73 {
	margin-right: 73px;
}

.m-r-74 {
	margin-right: 74px;
}

.m-r-75 {
	margin-right: 75px;
}

.m-r-76 {
	margin-right: 76px;
}

.m-r-77 {
	margin-right: 77px;
}

.m-r-78 {
	margin-right: 78px;
}

.m-r-79 {
	margin-right: 79px;
}

.m-r-80 {
	margin-right: 80px;
}

.m-r-81 {
	margin-right: 81px;
}

.m-r-82 {
	margin-right: 82px;
}

.m-r-83 {
	margin-right: 83px;
}

.m-r-84 {
	margin-right: 84px;
}

.m-r-85 {
	margin-right: 85px;
}

.m-r-86 {
	margin-right: 86px;
}

.m-r-87 {
	margin-right: 87px;
}

.m-r-88 {
	margin-right: 88px;
}

.m-r-89 {
	margin-right: 89px;
}

.m-r-90 {
	margin-right: 90px;
}

.m-r-91 {
	margin-right: 91px;
}

.m-r-92 {
	margin-right: 92px;
}

.m-r-93 {
	margin-right: 93px;
}

.m-r-94 {
	margin-right: 94px;
}

.m-r-95 {
	margin-right: 95px;
}

.m-r-96 {
	margin-right: 96px;
}

.m-r-97 {
	margin-right: 97px;
}

.m-r-98 {
	margin-right: 98px;
}

.m-r-99 {
	margin-right: 99px;
}

.m-r-100 {
	margin-right: 100px;
}


/* Margin Left */

.m-l-0 {
	margin-left: 0px;
}

.m-l-1 {
	margin-left: 1px;
}

.m-l-2 {
	margin-left: 2px;
}

.m-l-3 {
	margin-left: 3px;
}

.m-l-4 {
	margin-left: 4px;
}

.m-l-5 {
	margin-left: 5px;
}

.m-l-6 {
	margin-left: 6px;
}

.m-l-7 {
	margin-left: 7px;
}

.m-l-8 {
	margin-left: 8px;
}

.m-l-9 {
	margin-left: 9px;
}

.m-l-10 {
	margin-left: 10px;
}

.m-l-11 {
	margin-left: 11px;
}

.m-l-12 {
	margin-left: 12px;
}

.m-l-13 {
	margin-left: 13px;
}

.m-l-14 {
	margin-left: 14px;
}

.m-l-15 {
	margin-left: 15px;
}

.m-l-16 {
	margin-left: 16px;
}

.m-l-17 {
	margin-left: 17px;
}

.m-l-18 {
	margin-left: 18px;
}

.m-l-19 {
	margin-left: 19px;
}

.m-l-20 {
	margin-left: 20px;
}

.m-l-21 {
	margin-left: 21px;
}

.m-l-22 {
	margin-left: 22px;
}

.m-l-23 {
	margin-left: 23px;
}

.m-l-24 {
	margin-left: 24px;
}

.m-l-25 {
	margin-left: 25px;
}

.m-l-26 {
	margin-left: 26px;
}

.m-l-27 {
	margin-left: 27px;
}

.m-l-28 {
	margin-left: 28px;
}

.m-l-29 {
	margin-left: 29px;
}

.m-l-30 {
	margin-left: 30px;
}

.m-l-31 {
	margin-left: 31px;
}

.m-l-32 {
	margin-left: 32px;
}

.m-l-33 {
	margin-left: 33px;
}

.m-l-34 {
	margin-left: 34px;
}

.m-l-35 {
	margin-left: 35px;
}

.m-l-36 {
	margin-left: 36px;
}

.m-l-37 {
	margin-left: 37px;
}

.m-l-38 {
	margin-left: 38px;
}

.m-l-39 {
	margin-left: 39px;
}

.m-l-40 {
	margin-left: 40px;
}

.m-l-41 {
	margin-left: 41px;
}

.m-l-42 {
	margin-left: 42px;
}

.m-l-43 {
	margin-left: 43px;
}

.m-l-44 {
	margin-left: 44px;
}

.m-l-45 {
	margin-left: 45px;
}

.m-l-46 {
	margin-left: 46px;
}

.m-l-47 {
	margin-left: 47px;
}

.m-l-48 {
	margin-left: 48px;
}

.m-l-49 {
	margin-left: 49px;
}

.m-l-50 {
	margin-left: 50px;
}

.m-l-51 {
	margin-left: 51px;
}

.m-l-52 {
	margin-left: 52px;
}

.m-l-53 {
	margin-left: 53px;
}

.m-l-54 {
	margin-left: 54px;
}

.m-l-55 {
	margin-left: 55px;
}

.m-l-56 {
	margin-left: 56px;
}

.m-l-57 {
	margin-left: 57px;
}

.m-l-58 {
	margin-left: 58px;
}

.m-l-59 {
	margin-left: 59px;
}

.m-l-60 {
	margin-left: 60px;
}

.m-l-61 {
	margin-left: 61px;
}

.m-l-62 {
	margin-left: 62px;
}

.m-l-63 {
	margin-left: 63px;
}

.m-l-64 {
	margin-left: 64px;
}

.m-l-65 {
	margin-left: 65px;
}

.m-l-66 {
	margin-left: 66px;
}

.m-l-67 {
	margin-left: 67px;
}

.m-l-68 {
	margin-left: 68px;
}

.m-l-69 {
	margin-left: 69px;
}

.m-l-70 {
	margin-left: 70px;
}

.m-l-71 {
	margin-left: 71px;
}

.m-l-72 {
	margin-left: 72px;
}

.m-l-73 {
	margin-left: 73px;
}

.m-l-74 {
	margin-left: 74px;
}

.m-l-75 {
	margin-left: 75px;
}

.m-l-76 {
	margin-left: 76px;
}

.m-l-77 {
	margin-left: 77px;
}

.m-l-78 {
	margin-left: 78px;
}

.m-l-79 {
	margin-left: 79px;
}

.m-l-80 {
	margin-left: 80px;
}

.m-l-81 {
	margin-left: 81px;
}

.m-l-82 {
	margin-left: 82px;
}

.m-l-83 {
	margin-left: 83px;
}

.m-l-84 {
	margin-left: 84px;
}

.m-l-85 {
	margin-left: 85px;
}

.m-l-86 {
	margin-left: 86px;
}

.m-l-87 {
	margin-left: 87px;
}

.m-l-88 {
	margin-left: 88px;
}

.m-l-89 {
	margin-left: 89px;
}

.m-l-90 {
	margin-left: 90px;
}

.m-l-91 {
	margin-left: 91px;
}

.m-l-92 {
	margin-left: 92px;
}

.m-l-93 {
	margin-left: 93px;
}

.m-l-94 {
	margin-left: 94px;
}

.m-l-95 {
	margin-left: 95px;
}

.m-l-96 {
	margin-left: 96px;
}

.m-l-97 {
	margin-left: 97px;
}

.m-l-98 {
	margin-left: 98px;
}

.m-l-99 {
	margin-left: 99px;
}

.m-l-100 {
	margin-left: 100px;
}

.m-t-0 {
	margin-top: 0px;
}

.m-t-1 {
	margin-top: 1px;
}

.m-t-2 {
	margin-top: 2px;
}

.m-t-3 {
	margin-top: 3px;
}

.m-t-4 {
	margin-top: 4px;
}

.m-t-5 {
	margin-top: 5px;
}

.m-t-6 {
	margin-top: 6px;
}

.m-t-7 {
	margin-top: 7px;
}

.m-t-8 {
	margin-top: 8px;
}

.m-t-9 {
	margin-top: 9px;
}

.m-t-10 {
	margin-top: 10px;
}

.m-t-11 {
	margin-top: 11px;
}

.m-t-12 {
	margin-top: 12px;
}

.m-t-13 {
	margin-top: 13px;
}

.m-t-14 {
	margin-top: 14px;
}

.m-t-15 {
	margin-top: 15px;
}

.m-t-16 {
	margin-top: 16px;
}

.m-t-17 {
	margin-top: 17px;
}

.m-t-18 {
	margin-top: 18px;
}

.m-t-19 {
	margin-top: 19px;
}

.m-t-20 {
	margin-top: 20px;
}

.m-t-21 {
	margin-top: 21px;
}

.m-t-22 {
	margin-top: 22px;
}

.m-t-23 {
	margin-top: 23px;
}

.m-t-24 {
	margin-top: 24px;
}

.m-t-25 {
	margin-top: 25px;
}

.m-t-26 {
	margin-top: 26px;
}

.m-t-27 {
	margin-top: 27px;
}

.m-t-28 {
	margin-top: 28px;
}

.m-t-29 {
	margin-top: 29px;
}

.m-t-30 {
	margin-top: 30px;
}

.m-t-31 {
	margin-top: 31px;
}

.m-t-32 {
	margin-top: 32px;
}

.m-t-33 {
	margin-top: 33px;
}

.m-t-34 {
	margin-top: 34px;
}

.m-t-35 {
	margin-top: 35px;
}

.m-t-36 {
	margin-top: 36px;
}

.m-t-37 {
	margin-top: 37px;
}

.m-t-38 {
	margin-top: 38px;
}

.m-t-39 {
	margin-top: 39px;
}

.m-t-40 {
	margin-top: 40px;
}

.m-t-41 {
	margin-top: 41px;
}

.m-t-42 {
	margin-top: 42px;
}

.m-t-43 {
	margin-top: 43px;
}

.m-t-44 {
	margin-top: 44px;
}

.m-t-45 {
	margin-top: 45px;
}

.m-t-46 {
	margin-top: 46px;
}

.m-t-47 {
	margin-top: 47px;
}

.m-t-48 {
	margin-top: 48px;
}

.m-t-49 {
	margin-top: 49px;
}

.m-t-50 {
	margin-top: 50px;
}

.m-t-51 {
	margin-top: 51px;
}

.m-t-52 {
	margin-top: 52px;
}

.m-t-53 {
	margin-top: 53px;
}

.m-t-54 {
	margin-top: 54px;
}

.m-t-55 {
	margin-top: 55px;
}

.m-t-56 {
	margin-top: 56px;
}

.m-t-57 {
	margin-top: 57px;
}

.m-t-58 {
	margin-top: 58px;
}

.m-t-59 {
	margin-top: 59px;
}

.m-t-60 {
	margin-top: 60px;
}

.m-t-61 {
	margin-top: 61px;
}

.m-t-62 {
	margin-top: 62px;
}

.m-t-63 {
	margin-top: 63px;
}

.m-t-64 {
	margin-top: 64px;
}

.m-t-65 {
	margin-top: 65px;
}

.m-t-66 {
	margin-top: 66px;
}

.m-t-67 {
	margin-top: 67px;
}

.m-t-68 {
	margin-top: 68px;
}

.m-t-69 {
	margin-top: 69px;
}

.m-t-70 {
	margin-top: 70px;
}

.m-t-71 {
	margin-top: 71px;
}

.m-t-72 {
	margin-top: 72px;
}

.m-t-73 {
	margin-top: 73px;
}

.m-t-74 {
	margin-top: 74px;
}

.m-t-75 {
	margin-top: 75px;
}

.m-t-76 {
	margin-top: 76px;
}

.m-t-77 {
	margin-top: 77px;
}

.m-t-78 {
	margin-top: 78px;
}

.m-t-79 {
	margin-top: 79px;
}

.m-t-80 {
	margin-top: 80px;
}

.m-t-81 {
	margin-top: 81px;
}

.m-t-82 {
	margin-top: 82px;
}

.m-t-83 {
	margin-top: 83px;
}

.m-t-84 {
	margin-top: 84px;
}

.m-t-85 {
	margin-top: 85px;
}

.m-t-86 {
	margin-top: 86px;
}

.m-t-87 {
	margin-top: 87px;
}

.m-t-88 {
	margin-top: 88px;
}

.m-t-89 {
	margin-top: 89px;
}

.m-t-90 {
	margin-top: 90px;
}

.m-t-91 {
	margin-top: 91px;
}

.m-t-92 {
	margin-top: 92px;
}

.m-t-93 {
	margin-top: 93px;
}

.m-t-94 {
	margin-top: 94px;
}

.m-t-95 {
	margin-top: 95px;
}

.m-t-96 {
	margin-top: 96px;
}

.m-t-97 {
	margin-top: 97px;
}

.m-t-98 {
	margin-top: 98px;
}

.m-t-99 {
	margin-top: 99px;
}

.m-t-100 {
	margin-top: 100px;
}


.m-b-0 {
	margin-bottom: 0px;
}

.m-b-1 {
	margin-bottom: 1px;
}

.m-b-2 {
	margin-bottom: 2px;
}

.m-b-3 {
	margin-bottom: 3px;
}

.m-b-4 {
	margin-bottom: 4px;
}

.m-b-5 {
	margin-bottom: 5px;
}

.m-b-6 {
	margin-bottom: 6px;
}

.m-b-7 {
	margin-bottom: 7px;
}

.m-b-8 {
	margin-bottom: 8px;
}

.m-b-9 {
	margin-bottom: 9px;
}

.m-b-10 {
	margin-bottom: 10px;
}

.m-b-11 {
	margin-bottom: 11px;
}

.m-b-12 {
	margin-bottom: 12px;
}

.m-b-13 {
	margin-bottom: 13px;
}

.m-b-14 {
	margin-bottom: 14px;
}

.m-b-15 {
	margin-bottom: 15px;
}

.m-b-16 {
	margin-bottom: 16px;
}

.m-b-17 {
	margin-bottom: 17px;
}

.m-b-18 {
	margin-bottom: 18px;
}

.m-b-19 {
	margin-bottom: 19px;
}

.m-b-20 {
	margin-bottom: 20px;
}

.m-b-21 {
	margin-bottom: 21px;
}

.m-b-22 {
	margin-bottom: 22px;
}

.m-b-23 {
	margin-bottom: 23px;
}

.m-b-24 {
	margin-bottom: 24px;
}

.m-b-25 {
	margin-bottom: 25px;
}

.m-b-26 {
	margin-bottom: 26px;
}

.m-b-27 {
	margin-bottom: 27px;
}

.m-b-28 {
	margin-bottom: 28px;
}

.m-b-29 {
	margin-bottom: 29px;
}

.m-b-30 {
	margin-bottom: 30px;
}

.m-b-31 {
	margin-bottom: 31px;
}

.m-b-32 {
	margin-bottom: 32px;
}

.m-b-33 {
	margin-bottom: 33px;
}

.m-b-34 {
	margin-bottom: 34px;
}

.m-b-35 {
	margin-bottom: 35px;
}

.m-b-36 {
	margin-bottom: 36px;
}

.m-b-37 {
	margin-bottom: 37px;
}

.m-b-38 {
	margin-bottom: 38px;
}

.m-b-39 {
	margin-bottom: 39px;
}

.m-b-40 {
	margin-bottom: 40px;
}

.m-b-41 {
	margin-bottom: 41px;
}

.m-b-42 {
	margin-bottom: 42px;
}

.m-b-43 {
	margin-bottom: 43px;
}

.m-b-44 {
	margin-bottom: 44px;
}

.m-b-45 {
	margin-bottom: 45px;
}

.m-b-46 {
	margin-bottom: 46px;
}

.m-b-47 {
	margin-bottom: 47px;
}

.m-b-48 {
	margin-bottom: 48px;
}

.m-b-49 {
	margin-bottom: 49px;
}

.m-b-50 {
	margin-bottom: 50px;
}

.m-b-51 {
	margin-bottom: 51px;
}

.m-b-52 {
	margin-bottom: 52px;
}

.m-b-53 {
	margin-bottom: 53px;
}

.m-b-54 {
	margin-bottom: 54px;
}

.m-b-55 {
	margin-bottom: 55px;
}

.m-b-56 {
	margin-bottom: 56px;
}

.m-b-57 {
	margin-bottom: 57px;
}

.m-b-58 {
	margin-bottom: 58px;
}

.m-b-59 {
	margin-bottom: 59px;
}

.m-b-60 {
	margin-bottom: 60px;
}

.m-b-61 {
	margin-bottom: 61px;
}

.m-b-62 {
	margin-bottom: 62px;
}

.m-b-63 {
	margin-bottom: 63px;
}

.m-b-64 {
	margin-bottom: 64px;
}

.m-b-65 {
	margin-bottom: 65px;
}

.m-b-66 {
	margin-bottom: 66px;
}

.m-b-67 {
	margin-bottom: 67px;
}

.m-b-68 {
	margin-bottom: 68px;
}

.m-b-69 {
	margin-bottom: 69px;
}

.m-b-70 {
	margin-bottom: 70px;
}

.m-b-71 {
	margin-bottom: 71px;
}

.m-b-72 {
	margin-bottom: 72px;
}

.m-b-73 {
	margin-bottom: 73px;
}

.m-b-74 {
	margin-bottom: 74px;
}

.m-b-75 {
	margin-bottom: 75px;
}

.m-b-76 {
	margin-bottom: 76px;
}

.m-b-77 {
	margin-bottom: 77px;
}

.m-b-78 {
	margin-bottom: 78px;
}

.m-b-79 {
	margin-bottom: 79px;
}

.m-b-80 {
	margin-bottom: 80px;
}

.m-b-81 {
	margin-bottom: 81px;
}

.m-b-82 {
	margin-bottom: 82px;
}

.m-b-83 {
	margin-bottom: 83px;
}

.m-b-84 {
	margin-bottom: 84px;
}

.m-b-85 {
	margin-bottom: 85px;
}

.m-b-86 {
	margin-bottom: 86px;
}

.m-b-87 {
	margin-bottom: 87px;
}

.m-b-88 {
	margin-bottom: 88px;
}

.m-b-89 {
	margin-bottom: 89px;
}

.m-b-90 {
	margin-bottom: 90px;
}

.m-b-91 {
	margin-bottom: 91px;
}

.m-b-92 {
	margin-bottom: 92px;
}

.m-b-93 {
	margin-bottom: 93px;
}

.m-b-94 {
	margin-bottom: 94px;
}

.m-b-95 {
	margin-bottom: 95px;
}

.m-b-96 {
	margin-bottom: 96px;
}

.m-b-97 {
	margin-bottom: 97px;
}

.m-b-98 {
	margin-bottom: 98px;
}

.m-b-99 {
	margin-bottom: 99px;
}

.m-b-100 {
	margin-bottom: 100px;
}





