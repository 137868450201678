.homepage-grave_first {
    width: 100%;
}

.homepage-grave_first-content {
    width: 100%;
}

.homepage-grave_first-content_header {
    width: 100%;
    position: relative;
    z-index: -1;
}

.homepage-grave_first-content_header-main {
    width: 100%;
}

.homepage-grave_first-content_header-line {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -5%;
}

.homepage-grave_first-content_header-text {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #36434B;
    position: absolute;
    max-width: 687px;
    left: 23.2%;
    top: 35.3%;
    right: 23.2%;
}

.homepage-grave_first-content_header-text > span {
    color: #FEA65A;
}

.homepage-grave_first-content_header-mobile {
    display: none;
}

@media (max-width: 800px) {
    .homepage-grave_first-content_header-main, .homepage-grave_first-content_header-line {
        display: none;
    }

    .homepage-grave_first-content_header-mobile {
        display: block;
        width: 100%;
    }

    .homepage-grave_first-content_header-text {
        max-width: 100%;
        font-size: 18px;
        line-height: 22px;
        top: 28%;
        left: 4.2%;
        right: 4.2%;
    }
}