.heading {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
}

.sub-heading {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 16px;
  margin-bottom: 16px;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.list-item {
  margin-bottom: 8px;
  color: #36434b;
  font-family: 'Roboto';
}

.title {
  font-weight: 500;
}
