.modal-heading {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 25px;
  font-weight: 500;
  padding: 52px 106px 0px 106px;
}

.details {
  color: #404d56;
  padding: 12px 66px 32px 66px;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.process-modal {
  display: flex;
  align-items: center;
  gap: 4px;
}

.process-modal > span {
  color: #404d56;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  padding: 20px;
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
}
