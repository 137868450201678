.homepage-investigate_second {
    width: 100%;
    margin-bottom: 217px;
}

.homepage-investigate_second-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.homepage-investigate_second-content_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 74px;
}

.homepage-investigate_second-content_header-text {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
    color: #36434B;
    margin-bottom: 24px;
}

.homepage-investigate_second-content_header-subtext {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #36434B;
    margin-bottom: 8px;
}

.homepage-investigate_second-content_header-title {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #9F9F9F;
    width: 592px;
}

.homepage-investigate_second-content_header-text > span, .homepage-investigate_second-content_header-subtext > span {
    color: #FEA65A;
}

.homepage-investigate_second-content_main {
    display: flex;
    width: 82%;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    justify-content: space-between;
}

.homepage-investigate_second-content_main-block {
    display: flex;
    width: 250px;
    align-items: center;
    flex-direction: column;
}

.homepage-investigate_second-content_main-block_image {
    min-width: 84px;
    min-height: 84px;
    background-color: rgba(245, 245, 245, 0.6);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
}

.homepage-investigate_second-content_main-block_header {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-bottom: 19px;
}

.homepage-investigate_second-content_main-block_subheader {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #9F9F9F;
}

@media (max-width: 800px) {
    .homepage-investigate_second-content_header-text {
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 26px;
    }

    .homepage-investigate_second-content_header-subtext {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .homepage-investigate_second-content_header-title {
        width: 90%;
    }

    .homepage-investigate_second-content_header {
        margin-bottom: 56px;
    }

    .homepage-investigate_second-content_main {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .homepage-investigate_second-content_main-block {
        margin-bottom: 50px;
        width: 90%;
    }

    .homepage-investigate_second-content_main-block:last-child {
        margin-bottom: 0px;
    }

    .homepage-investigate_second {
        margin-bottom: 140px;
    }
}