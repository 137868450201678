.search-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 80%;
  margin: 0px auto;
  margin-bottom: 12px;
}

.search-filters .autocomplete-input {
  background: #fff !important;
}

.form-margin {
  margin-right: 32px;
}

.form-control {
  width: 244px !important;
  height: 40px !important;
}

.input-lable {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #999999 !important;
  margin-bottom: 10px;
}

.search-filters_select {
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  background: #f5f5f5 !important;
  /* text-transform: capitalize !important; */
}

.search-filters_select-input {
  margin: 0px !important;
}

.search-filters_select-input > div > input {
  padding-left: 12px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #36434b !important;
  height: 100% !important;
}

.MuiFilledInput-root {
  border-radius: 10px !important;
  background: #f5f5f5 !important;
}

.MuiList-padding {
  padding: 0 !important;
}

.MuiFilledInput-underline:after,
.MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.MuiSelect-icon {
  color: #36434b !important;
  cursor: pointer;
}

.search-filters_select-item {
  text-transform: capitalize !important;
}

@media (max-width: 750px) {
  .search-filters {
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    gap: 8px;
  }

  .form-margin,
  .form-manege {
    margin-right: 0;
    margin-top: 16px;
    width: 100%;
  }

  .form-control {
    width: 100% !important;
  }
}
