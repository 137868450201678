.header {
  margin-bottom: 60px;
  width: max-content;
}

.header-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
