.homepage-scientists_fourth-content_group {
    margin-left: 5.5%;
    z-index: -1;
}

.homepage-scientists_fourth {
    width: 100%;
}

.homepage-scientists_fourth-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.homepage-scientists_fourth-content_main {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.homepage-scientists_fourth-content_empty {
    min-width: 49px;
}

.homepage-scientists_fourth-content_main-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #36434B;
    width: 80%;
}

.homepage-scientists_fourth-content_main-boxes {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    right: 0;
    position: absolute;
    top: -25px;
    z-index: -1;
}

.homepage-scientists_fourth-content_main-big-box, .homepage-scientists_fourth-content_main-small-box {
    background: #FEA65A;
    opacity: 0.34;
    border-radius: 20px;
    min-width: 158px;
    min-height: 158px;
}

.homepage-scientists_fourth-content_main-big-box {
    margin-right: 45px;
}

.homepage-scientists_fourth-content_main-small-box {
    border-radius: 10px;
    min-width: 70px;
    min-height: 70px;
    margin-top: -45px;
}

@media (max-width: 800px) {
    .homepage-scientists_fourth-content {
        flex-direction: column-reverse;
    }

    .homepage-scientists_fourth-content_main-big-box {
        background: #FEA65A;
        opacity: 0.34;
        border-radius: 8px;
        min-width: 78.62px;
        min-height: 78.62px;
        margin-right: 16px;
    }

    .homepage-scientists_fourth-content_main-small-box {
        background: #FEA65A;
        opacity: 0.34;
        border-radius: 8px;
        min-width: 34.83px;
        min-height: 34.83px;
        margin-top: -25px;
    }

    .homepage-scientists_fourth-content_group {
        margin-left: 0px;
        margin-top: 40px;
        max-width: 540px;
        width: 91.5%;
    }

    .homepage-scientists_fourth-content_main-text {
        width: 100%;
    }

    .homepage-scientists_fourth-content_main {
        box-sizing: border-box;
        padding: 0px 16px;
    }

    .homepage-scientists_fourth {
        margin-bottom: 67px;
    }
}