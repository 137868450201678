.event-container {
  display: flex;
  gap: 24px;
  margin-bottom: 14px;
}

.event-form-section {
  position: relative;
}

.event-container-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.input-element {
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex: 1;
}

.input-element-list {
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 14px;
}

.title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
}

.inputs {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 14px 28px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
  border: 1px solid transparent;
}

.inputs:focus {
  border: 1px solid;
}

.event-content-textarea {
  width: 100%;
  margin-bottom: 14px;
}

.input-element > textarea {
  border: 1px solid transparent;
  outline: none;
  resize: none;
  width: 100%;
  height: 124px;
  box-sizing: border-box;
  padding: 14px 28px;
  border-radius: 8px;
  background: #f2f5f8;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #777;
}

.input-element > textarea:focus {
  border: 1px solid;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #fea65a;
  background: #fff;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.selector {
  width: 100%;
  background: #f2f5f8;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  padding: 14px 28px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}

.selector:focus {
  border: 1px solid;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(39, 47, 52, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
}

.loader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.loader > span {
  color: #fea65a;
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 500;
  text-transform: capitalize;
}

@media screen and (max-width: 800px) {
  .event-container,
  .event-container-list {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }

  .event-container {
    margin-bottom: 14px;
  }

  .input-element-list {
    width: 100%;
  }

  .input-element {
    width: 100%;
  }
  .grave-btn-container {
    display: inline;
  }

  .grave-information_content-verify {
    flex-direction: column;
  }
}
