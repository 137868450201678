.message {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3
}

.image {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  height: 24px;
  width: 24px;
}

/* .red-background {
  background: #e85e6c;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
}
.red-background > img {
  height: 80px;
} */

.oops {
  color: #e85e6c;
  font-family: 'Roboto';
  font-size: 26px;
  font-weight: 500;
  line-height: 1.3;
  padding: 24px;
}

.message-wrapper {
  padding: 0 24px 24px;
}
