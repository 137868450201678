.container-wrapper {
  padding: 24px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 12px;
}

.about-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.about-item-header > span {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
}

.edit-container {
  background: #f2f5f8;
  padding: 16px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: 16px;
}

.edit {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
}

.about-item-content_row-container {
  background: #fff;
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.action-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
}

.action-btn:hover {
  background-color: #f2f5f8;
}

.action-btn img {
  width: 16px;
}

.action-btn > button {
  border: none;
  background: none;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.about-item-content_row {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 0px;
}

.icon {
  background: #e4f3fd;
  padding: 8px;
}

.about-item-value-container {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.about-item-key {
  color: #737a7f;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
}

.about-item-value {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
}
.info {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  padding: 24px 24px 36px 24px;
  margin-bottom: 12px;
}

.empty-grave-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(300px - 24px);
}

.empty-message {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.add-btn {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
}

.set-relation-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  cursor: pointer;
  border-radius: 10px;
  background: #404d56;
  box-shadow: 0px 1px 6px 0px rgba(254, 166, 90, 0.3);
  border: none;
  padding: 18px 50px;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.edit-btn {
  margin-top: 12px;
}

@media screen and (min-width: 1020px) {
  .container-wrapper {
    margin-bottom: 0;
  }

  .about-item-header {
    margin-bottom: 24px;
  }

  .about-item-content_row {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0;
  }

  .icon {
    padding: 14px;
  }

  .edit-container {
    background-color: #fff;
    cursor: pointer;
    margin-top: 0;
    padding: 0;
  }

  .info {
    margin-bottom: 0;
  }
}
