.card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 12px;
}

.payment-method-change {
  margin-top: 24px;
  color: rgba(0, 0, 0, 0.4);
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.payment-change {
  color: #ea9449;
  cursor: pointer;
}

.plan-details {
  box-shadow: 1px 3px 20px rgba(0, 0, 0, 0.1);
  padding: 32px;
  border-radius: 10px;
  border-top: 2px solid #19262d;
  border-bottom: 2px solid #19262d;
  position: relative;
}

.plan-details.current {
  border-top: 2px solid #fea65a;
  border-bottom: 2px solid #fea65a;
}

.loading-container {
  height: 400px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.product-name {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #19262d;
}

.product_description {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #404d56;
  margin-bottom: 24px;
}

.plan-amount {
  margin-bottom: 24px;
}

.plan-amount-currency {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 32px;
  color: #19262d;
  text-transform: capitalize;
}

.plan-amount-currency-family {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 32px;
  color: #ea9449;
  text-transform: capitalize;
}

.plan-amount-price {
  color: #565560;
  font-family: 'Roboto';
  font-size: 23px;
  font-weight: 400;
}

.discount-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-count {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #404d56;
}

.family-message {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}

.count-number-message {
  margin-bottom: 12px;
  color: #36434b;
  font-family: 'Roboto';
}

.total-price-details {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.total-counting {
  color: rgba(0, 0, 0, 0.4);
  font-family: 'Roboto';
  font-size: 32px;
  font-weight: 500;
}

.plan-subscribe-btn {
  margin-bottom: 24px;
}

.plan-subscribe-btn > button {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #404d56;
  border-radius: 10px;
  color: #fbfbfc;
  font-family: 'Roboto';
  transition: ease 0.3s;
  font-weight: 600;
  font-size: 18px;
  line-height: 170%;
  padding: 12px 0px;
}

.plan-subscribe-btn > button:hover {
  background: #344048;
}

.plan-subscribe-btn > button:disabled {
  background: #cbcbcb !important;
  cursor: not-allowed;
}

.plan-subscribe-btn > button:disabled:hover {
  background: #b5b5b5 !important;
}

.user-count-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}

.features-list {
  list-style: none;
  padding: 0;
}

.feature-list-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.feature-list-item > span {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
  color: #565560;
}

.plan-features > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #2b2a30;
}

@media screen and (min-width: 1120px) {
  .card {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

@media screen and (min-width: 550px) {
  .card {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

@media screen and (max-width: 520px) {
  .loading-container {
    width: auto;
  }
}
