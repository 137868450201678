.contact {
  background-color: #fff;
}

.header {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  margin-bottom: 32px;
}
