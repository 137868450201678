.plans-section {
  padding: 0 24px;
}

.subscription-plans_card-content_header {
  width: 100%;
  margin-bottom: 12px;
  padding-top: 40px;
}

.subscription-plans_card-content_header > span {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #19262d;
}

.subscription-plans_card-content_header-desc {
  padding-bottom: 30px;
}

.subscription-plans_card-content_header-desc > span {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #404d56;
}

@media (min-width: 630px) {
  /* .plans-section {
		padding: 16px;
	} */

  .subscription-plans_card-content_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
  }

  .subscription-plans_card-content_header-desc {
    text-align: center;
    padding-bottom: 30px;
  }

  .subscription-plans_card-content_header > span {
    font-size: 48px;
    line-height: 57px;
  }
}
