.administrator-page {
  border-radius: 10px;
  background: #fff;
  padding: 36px;
  margin-bottom: 24px;
  min-height: 500px;
}

.action-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
}

.heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.heading {
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 25px;
  font-weight: 500;
}

.action-btn:hover {
  background-color: #f2f5f8;
}

.action-btn img {
  width: 16px;
}

.action-btn > button {
  border: none;
  background: none;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.sub-heading {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
}

.admin-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}
