.cropper {
  width: 100% !important;
  overflow: hidden;
  margin-bottom: 24px;
}

.cropper > img {
  width: 100% !important;
  opacity: 1 !important;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.send-btn {
  background: #58e962;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.send-it {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  color: #404d56;
}

.icon {
  color: #fff;
  font-size: 22px;
}

.btn {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 12px;
}
