.question-section {
  width: 339px;
  height: 352px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background: #FFFFFF;
  border: 3px solid #F5F5F5;
  text-align: start;
  position: relative;
  border-radius: 10px;
}

.question-section-title {
  margin-top: 44px;
  margin-bottom: 53px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #36434B;
  width: 275px;
}

.question-section-content {
  width: 275px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 131.69%;
  color: #9F9F9F;
  position: absolute;
  top: 116px;
}

@media screen and (max-width: 750px){
  .question-section {
    width: 100%;
    height: auto;
    min-height: 140px;
    max-height: 243px;
    border: none;
    border-top: 2px solid #F5F5F5;
    padding: 0 16px 0;
  }

  .question-section-title {
    margin-top: 38px;
    margin-bottom: 25px;
    width: 100%;
  }

  .question-section-content {
    position: static;
    width: 100%;
    margin-bottom: 30px;
  }
}