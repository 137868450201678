.company-name-container {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 14px;
}

.input-element {
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex: 1;
}

.title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
}

.inputs {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 21px 28px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: 1px solid transparent;
  outline: none;
}

.inputs:focus {
  border: 1px solid;
}

.selector {
  width: 100%;
  background: #f2f5f8;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  padding: 18px 28px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}

.selector:focus {
  border: 1px solid;
}

.workspace-content-textarea {
  width: 100%;
  margin-bottom: 14px;
}

.input-element > textarea {
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  height: 124px;
  box-sizing: border-box;
  padding: 14px 28px;
  border-radius: 8px;
  background: #f2f5f8;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  color: #777;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
}

.grave-actions-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex: 1;
}

.grave-information-edit-delete-container {
  display: flex;
  gap: 24px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.action-btn > button {
  border: none;
  background: none;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #fea65a;
  background: #fff;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.admin-access {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 21px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-access > span {
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
}

.admin-access > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #fea65a;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.relation-message {
  margin-top: 24px;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  color: #777;
}

.admin-access > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.admin-access > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.admin-access > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.relation-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-info-container {
  display: flex;
  gap: 12px;
  align-items: center;
  flex: 1;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  cursor: pointer;
}

.user-relation {
  color: #8d9093;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 1.2;
}

.warning-message {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 1.3;
}

.user-role {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 1.3;
}
.user-relation {
  color: #8d9093;
  font-family: 'Roboto';
  font-size: 14px;
}

.user-action {
  display: flex;
  flex-direction: column;
  background: #fff;
  background: #fff;
  cursor: pointer;
}

.more-icon-container {
  cursor: pointer;
}

.more-icon {
  padding: 8px;
}

.choose-relation {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 12px;
}

@media screen and (max-width: 800px) {
  .company-name-container {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
  .input-element {
    width: 100%;
  }
  .grave-btn-container {
    display: inline;
  }

  .grave-information_content-verify {
    flex-direction: column;
  }
}
