.user-profile-display-image-container {
  width: 150px;
  height: 140px;
  border: 5px solid #ffffff;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #dfdfdf;
  position: relative;
  border-radius: 18px;
  cursor: pointer;
}

.user-gender-avatar {
  height: 100%;
  width: 100%;
  border-radius: 18px;
  object-fit: cover;
}

.settings-page_header_cropper-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 42px;
}

.cancel-btn {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  padding: 17px 52px;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.save-btn {
  cursor: pointer;
  border-radius: 8px;
  background: #404d56;
  padding: 17px 52px;
  color: #fff;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  border: none;
  text-transform: uppercase;
}

.user-profile {
  width: 147px;
  height: 137px;
  border-radius: 17px;
}

.user-profile-loading {
  width: 50%;
  margin: 40px auto;
  display: flex;
  height: 50%;
}

.no-gender {
  width: 70%;
  height: 90%;
  margin: 10px auto;
  display: flex;
}

.member-photo_selector {
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: -12px;
  right: -12px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.member-photo_selector > img {
  height: 16px;
  width: 16px;
}
