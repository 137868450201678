.benefits {
  width: 100%;
}

.additional-benefits-heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 31.25px;
  line-height: 37px;
  color: #36434B;
  margin-bottom: 48px;
}

.additional-benefits {
  display: grid;
  grid-template-columns: 1fr;
}

.additional-benefits-details {
  display: flex;
  flex-direction: column;
}

.additional-benefits-icon {
  background-color: #36434B;
  width: max-content;
  border-radius: 50%;
  padding: 8px;
  margin-bottom: 24px;
}


.additional-benefits-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #36434B;
  margin-bottom: 24px;
}

.additional-benefits-desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #777777;
  margin-bottom: 24px;
}

@media (min-width : 630px) {

  .benefits {
    width: 80%;
    margin: 24px auto;
  }

  .additional-benefits-heading {
    font-size: 39.06px;
    line-height: 46px;
    text-align: center;
  }

  .additional-benefits {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}

@media (min-width : 1020px) {

  .benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .additional-benefits-heading {
    font-size: 39.06px;
    line-height: 46px;
  }

  .additional-benefits {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
}