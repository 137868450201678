.connect-grave-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  row-gap: 24px;
  column-gap: 24px;
  margin-top: 12px;
  margin-bottom: 32px;
}

.connected-grave {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  cursor: pointer;
}

.grave-image {
  border-radius: 2px 2px 0px 0px;
  height: 145px;
  width: 145px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 8px;
}

.grave-name {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.age-years {
  color: #9f9f9f;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
}

.relation {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
}

.empty-grave-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(300px - 24px);
}

.empty-message {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.add-btn {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
}
