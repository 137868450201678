.images-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.grave-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
  border-radius: 10px;
}

.privacy-list {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.image-loader {
  height: 250px;
  background: rgba(39, 47, 52, 0.5);
  color: #fff;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.image-loader-single {
  height: 400px;
  background: rgba(39, 47, 52, 0.5);
  color: #fff;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.grave-image-single {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  border-radius: 10px;
}

.image-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  margin: 8px 12px;
}

.image-actions > button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

.image-actions > button:hover {
  background-color: #cccc;
}

.cropper-modal_content-inputs-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.description-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
}

.address-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.description-wrapper {
  display: flex;
  padding: 14px 27px;
  border-radius: 8px;
  background: #f2f5f8;
  gap: 8px;
  /* flex-wrap: wrap; */
}

.label {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.edit-virtue-modal_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-input {
  outline: none;
  background: #f2f5f8;
  border: none;
  color: #777;
  font-family: 'Roboto';
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.address-input {
  outline: none;
  border: none;
  padding: 16px 27px;
  border-radius: 8px;
  background: #f2f5f8;
  color: #777;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.set-cover-image {
  display: flex;
  align-items: center;
  /* padding: 15px 13px; */
  flex-direction: row;
  margin-bottom: 12px;
}

.set-cover-image > input[type='checkbox'] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #77848d;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.set-cover-image > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.set-cover-image > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.set-cover-image > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.set-cover-image > label {
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 19px;
  color: #36434b;
  margin-left: 20px;
}

@media screen and (max-width: 520px) {
  .description-wrapper {
    flex-wrap: wrap;
  }
}
