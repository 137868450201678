.image {
  border-radius: 10px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
  border: 1px solid lightgray;
}

.selected-image {
  opacity: 0.5 !important;
  border-radius: 10px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
  border: 1px solid lightgray;
}
