.homepage-grave_fourth, .homepage-grave_fourth-content {
    width: 100%;
}

.homepage-grave_fourth-content_photo {
    max-width: 1280px;
    width: 100%;
}

.homepage-grave_fourth-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage-grave_fourth-content_text {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    color: #F5F5F5;
    position: absolute;
    margin-bottom: 40px;
}

.homepage-grave_fourth-content_photo-mobile {
    display: none;
}

@media (max-width: 800px) {
    .homepage-grave_fourth-content_text {
        margin-bottom: 0px;
        font-size: 32px;
        line-height: 38px;
    }
}

@media (max-width: 550px) {
    .homepage-grave_fourth-content_photo-mobile {
        display: block;
        width: 100%;
    }

    .homepage-grave_fourth-content_photo {
        display: none;
    }
}