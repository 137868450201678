.member-background-image-container {
  border: 3px solid #f5f5f5;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 395px;
}

.member-header-content {
  grid-column-start: 2;
  grid-row-start: 11;
}

.default-user-avatar {
  display: flex;
  margin: 10px auto;
}

.member-header-display-image-container {
  width: 200px;
  height: 236px;
  border: 5px solid #ffffff;
  box-sizing: border-box;
  background: #fff;
  bottom: 41%;
  align-items: center;
  justify-content: center;
  grid-column-start: 2;
  grid-row-start: 11;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  position: absolute;
  bottom: -20%;
  left: 5%;
}

.user-gender-avatar-header {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.user-profile-loading {
  width: 70%;
  margin: 45px auto;
  display: flex;
  height: 60%;
}

.no-gender {
  width: 70%;
  height: 90%;
  margin: 10px auto;
  display: flex;
}

/* .user-coverphoto-loading {
  margin: 50px auto;
  display: flex;
  height: 50%;
} */

.member-header-info {
  /* grid-row-start: 17;
  grid-column-start: 6;
  grid-row-end: 21;
  grid-column-end: 21; */
  /* display: flex; */
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.user-information {
  /* float: left;
  display: grid;
  margin-left: 10px; */
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.user-name {
  font-family: Minion Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 28px;
  color: #36434b;
  margin-bottom: 6px;
  hyphens: auto;
  -webkit-hyphens: auto; /* For Safari */
  -moz-hyphens: auto; /* For Firefox */
  -ms-hyphens: auto; /* For Internet Explorer */
  word-wrap: break-word;
}

.user-avatar {
  width: stretch;
  height: stretch;
  height: -moz-available;
  width: -moz-available;
}

.user-desc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 18px;
  color: #36434b;
  margin-bottom: 6px;
}

.user-date-of-birth {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 18px;
  color: #777777;
  margin-bottom: 6px;
}

.member-photo-selector {
  grid-column-start: 5;
  grid-row-start: 19;
  width: 55px;
  height: 55px;
  background: #fff;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: -25px;
  right: -25px;
}

.member-cover-photo-selector {
  height: 55px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.make-connection_button {
  grid-row-start: 18;
  grid-column-start: 18;
  width: 125px;
  height: 46px;
  background-color: #fea65a;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto';
  cursor: pointer;
}

.edit-coverphoto_button {
  grid-row-start: 18;
  grid-column-start: 18;
  width: 150px;
  height: 45px;
  background-color: #fea65a;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  font-family: 'Roboto';
  cursor: pointer;
}

.user-confirmation-span {
  grid-row-start: 18;
  grid-column-start: 17;
  width: 188px;
  height: 20px;
  padding: 10px;
  background-color: #219653;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

/* .member-background-image {
  width: inherit;
  height: 395px;
} */

.upload-coverphoto-icon {
  margin-right: 11px;
}

.delete-coverphoto-icon {
  margin-right: 8px;
  margin-left: -2px;
}

.user-connection-dialog {
  max-width: 900px !important;
}

.cropper-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.image-loader {
  background: rgba(39, 47, 52, 0.5);
  color: #fff;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.actions-buttons-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.member-header-info_container {
  margin-left: 260px;
}

@media (max-width: 700px) {
  .member-background-image-container {
    /* grid-column: 1/21;
    grid-row-start: 1;
    grid-row-end: 9; */
    /* background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
  }

  .member-header_outer_container {
    background-color: #ffffff;
    /* padding: 20px 200px; */
    /* display: grid;
    grid-template-columns: repeat(20, 5%);
    grid-template-rows: repeat(20, 25px); */
    margin: auto;
    max-width: 1040px;
  }

  .member-header-info_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 46px;
    margin-left: 0px;
  }

  .member-header-info {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .member-header-display-image-container {
    width: 185px;
    height: 200px;
    border: 5px solid #ffffff;
    box-sizing: border-box;
    background: #fff;
    position: absolute;
    bottom: -10%;
    left: 50%;
    /* grid-row-start: 6;
    margin-left: 457%; */
    transform: translate(-50%);
  }

  .member-photo-selector {
    grid-column-start: 14;
    grid-row-start: 12;
  }

  .user-information {
    text-align: center;
    align-items: center;
    /* margin-left: 8px; */
  }

  .user-name {
    font-size: 30px;
  }

  .make-connection_button {
    grid-row-start: 19;
    grid-column-start: 8;
  }

  .user-confirmation-span {
    grid-row-start: 19;
    grid-column-start: 5;
    margin-left: 10px;
  }

  .edit-coverphoto_button {
    grid-row-start: 19;
    grid-column-start: 7;
  }
}
