.tabs-container {
  display: flex;
  gap: 26px;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.btn {
  padding: 12px;
  /* width: 20px; */
  transition: ease 0.5;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.active-btn {
  padding: 12px;
  /* width: 20px; */
  transition: ease 0.5;
  cursor: pointer;
  border-bottom: 1px solid #fea65a;
}
