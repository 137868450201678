.question-text-area-wrapper {
  display: flex;
  justify-content: center;
  margin: 10px 0px 0px;
  float: left;
  width: 88%;
  margin-left: 15px;
}

.comment-row {
  display: flex;
  padding-bottom: 10px;
}

.question-text-area_disabled {
  width: 100%;
  min-height: 18px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #36434B;
  padding: 15px;
  outline: none;
  resize: none;
  overflow: auto;
  /* height: unset; */
  border: 2px solid #e5e5e5;
  border-radius: 7px;
  margin-bottom: 10px;
  background: #F5F5F5;
}

.question-text-area_enabled{
  width: 100%;
  min-height: 18px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #36434B;
  padding: 15px;
  outline: none;
  resize: none;
  overflow: auto;
  border: 2px solid #e5e5e5;
  border-radius: 7px;
  margin-bottom: 10px;
  background: #FFF;
}

.comment-row-three-dots {
  transform: rotate(90deg);
    cursor: pointer;
    width: 20px;
    margin-left: 16px;
    align-self: flex-start;
    margin-top: 25px;
}