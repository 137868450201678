.billing-details-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
  width: 100%;
}

.billing-details-container > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  color: #36434b;
}

.auth-welcome_input {
  width: 100%;
}

.countries-arrow_popover {
  margin-left: 4px;
}

.create-membership_first-input_block-inputs {
  display: flex;
  border-radius: 8px;
  padding: 21px 27px;
  background: #f2f5f8;
  border: 1px solid transparent;
}

.error {
  border: 1px solid #d00202;
}

.error-message {
  margin-top: 8px;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 20.56px;
  color: #d00202;
}

.create-membership_first-input_block-inputs input {
  box-sizing: border-box;
  border: none;
  width: 100%;
  outline: none;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  background: #f2f5f8;
  margin-left: 8px;
}

.create-membership_first-input_block-inputs_button {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  color: #777;
}

.waiting-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
}

.vipps-logo {
  background-image: url(../../../../img/VippsLogo.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40px;
  width: 100px;
}

.waiting-message {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #36434b;
}

.conform-message {
  color: #36434b;
  text-align: center;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.btn {
  border-radius: 8px;
  background: #404d56;
  border: none;
  color: #fff;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  padding: 16px 24px;
  text-transform: uppercase;
  cursor: pointer;
}

.btn:disabled {
  background-color: #cbcbcb;
  cursor: not-allowed;
  color: #36434b;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.loading > img {
  height: 100px;
  width: 100px;
}
.desktop {
  display: flex;
}

.mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }
}

.payment-page {
  background: #f2f5f8;
  width: 100%;
}

.payment-page-heading {
  padding: 24px;
}

.payment-page-heading > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #19262d;
}

.payment-details,
.selected-plan-details {
  background-color: #fff;
  box-shadow: 1px 3px 20px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
}

.no-payment-message {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 153.2%;
  color: #404d56;
}

.payment-details .card-information {
  margin-bottom: 40px;
}

@media (min-width: 630px) {
  .payment-details,
  .selected-plan-details {
    box-shadow: none;
    border-radius: 10px;
  }

  .payment-page-container {
    width: 80%;
    margin: 0px auto;
    padding-bottom: 8px;
  }

  .payment-page-heading {
    text-align: center;
  }

  .payment-page-heading > span {
    font-size: 31.25px;
    line-height: 37px;
  }
}

@media (min-width: 1200px) {
  .payment-page-container {
    display: flex;
    gap: 24px;
    width: 70%;
  }

  .selected-plan {
    width: 350px;
    flex-shrink: 0;
    overflow: hidden;
  }

  .payment-container {
    flex: 1;
    min-width: 0;
  }

  .selected-plan-details {
    border-width: 2px 0px;
    border-style: solid;
    border-color: #19262d;
    border-radius: 10px;
  }
}
