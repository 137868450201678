.permission-container > button {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 170%;
  background-color: transparent;
  border: 1px solid #404d56;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  transition: all ease 0.5s;
}

.permission-container > button:hover {
  background-color: #404d56;
  color: #fff;
}
