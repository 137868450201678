.add-by-link_header-subtitle.video {
    margin-top: 0px;
}

.video-modal_mobile {
    background-color: #fff;
    border-radius: 10px;
    height: auto;
}

.video-mobile_modal-content_video, .video-mobile_modal-content_headline, .video-mobile_modal-content_description {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.video-mobile_modal-content_video > span, .video-mobile_modal-content_headline > span, .video-mobile_modal-content_description > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 8px;
}

.video-mobile_modal-content_headline > textarea {
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 12px 10px 18px 5px;
    background: #F5F5F5;
    border-radius: 6px;
    resize: none;
    height: 70px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 130.7%;
    color: #36434B;
}

.video-mobile_modal-content_description > textarea {
    box-sizing: border-box;
    border: none;
    outline: none;
    resize: none;
    padding: 12px 5px 12px 15px;
    background: #F5F5F5;
    border-radius: 6px;
    height: 104px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 130.7%;
    color: #36434B;
}