.questions-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 6px solid #FFFFFF;
}

.questions-section-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #36434B;
  margin-top: 49px;
  margin-bottom: 41px;
}

.questions-section-title_button {
  margin-right: 14px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161);
  display: flex;
  align-items: center;
  justify-content: center;
}

.questions-section-questions {
  display: flex;
  max-width: 1050px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 750px){
  .questions-section  {
    border-top: none
  }

  .questions-section-title {
    margin: 30px 0;
  }
}