/* .subscription-chosen-cards {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 24px;
  grid-gap: 12px;
  margin-bottom: 24px;
  padding: 24px;
} */

.additional-benefit {
  padding: 24px;
}

.compare-plans {
  background: #f1f7fd;
}

.faq {
  padding: 24px;
}

.faq-heading {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #19262d;
  margin-bottom: 44px;
  margin-top: 44px;
}

@media (min-width: 992px) {
  /* .subscription-chosen-cards {
    margin: 24px 40px;
  } */

  .faq {
    margin: 16px auto;
    width: 900px;
  }
}

@media (min-width: 767px) {
  /* .subscription-chosen-cards {
    grid-template-columns: repeat(2, 1fr);
    margin: 24px auto;
  } */

  .faq-heading {
    font-size: 39.06px;
    line-height: 46px;
    text-align: center;
  }
}

@media (min-width: 1020px) {
  .subscription-chosen-cards {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    margin: 24px auto;
    width: 1000px;
  }
}
