.grave {
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 16px;
  text-align: center;
  color: #36434b;
  background: #fff;
  box-shadow: 1px 4px 10px 3px #0000000d;
  padding: 20px 16px;
}

.grave-images_circle {
  border-radius: 50%;
  background: #36434b;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  cursor: pointer;
  z-index: 10;
}

.grave-images_circle > img {
  width: 24px;
  height: 24px;
}

.grave-date {
  margin-top: 25px;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  max-width: 192px;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.grave-location {
  color: #999999;
  margin-bottom: 12px;
}

.grave-date.second {
  margin-top: 0px;
  text-transform: capitalize;
}

.grave-id {
  font-size: 14px;
  line-height: 16px;
  margin-top: 20px;
  color: #999999;
}

.plot_number {
  font-size: 14px;
  line-height: 16px;
  min-height: 16px;
  color: #999999;
  margin-bottom: 12px;
}

.grave-button {
  border: 1px solid #36434b;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: 'Roboto';
  font-size: 16px;
  color: #36434b;
  padding: 12px 42px;
  background: #36434b;
  cursor: pointer;
  transition: all 0.3s ease;
}

.grave-button:hover {
  background-color: #d8d8d8;
}

.grave-data_date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.grave-avatar_listing {
  border: 3px solid #fea65a;
  box-sizing: border-box;
  border-radius: 70px 70px 0px 0px;
  background-color: #ffffff;
  margin-bottom: 20px;
  width: 122px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 20px;
}

.grave-data_date > span {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #999999;
}

.grave-data_date > span:first-child {
  margin-right: 25px;
}

.grave-data_date > span:last-child > img {
  background-image: url('../../../img/Christ.svg');
  background-repeat: no-repeat;
  background-position: 48% 80%;
}

.grave-link-deceased {
  text-decoration: none;
}

.mouse-over-popover_physical-header {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #36434b;
}

.mouse-over-popover_physical-main {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #36434b;
  display: block;
  margin-bottom: 7px;
}

.mouse-over-popover_physical-main > span {
  font-family: 'Roboto Medium';
  color: #fea65a;
}

.grave_avatar {
  width: 100%;
  height: 100%;
  border-radius: 70px 70px 0px 0px;
}

.grave-name {
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  color: #36434b;
  font-weight: 500;
  line-height: 29.3px;
  margin-bottom: 24px;
}

@media (max-width: 750px) {
  .grave-date {
    margin-top: 25px;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
    max-width: 292px;
  }

  .grave-images > img:first-child {
    margin-top: 10px !important;
    margin-left: 7px !important;
  }

  .grave-avatar_listing {
    width: 115px !important;
  }
}
