.container {
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 3px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 30px;
}

.container:hover {
  transform: translateZ(-30px);
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;
}

.heading {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #36434b;
}

.more-icon-container {
  cursor: pointer;
}

.more-icon {
  padding: 8px;
}

.stope-button {
  padding: 18px;
  color: #36434b;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  background: #fff;
  cursor: pointer;
}

.account_info {
  display: flex;
  align-items: center;
  gap: 40px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.card-methods {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

@media screen and (max-width: 765px) {
  .account_info {
    gap: 10px;
    flex-wrap: wrap;
  }
  .card-methods {
    flex-wrap: wrap;
    gap: 8px;
  }
}
