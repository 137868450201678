.homepage-scientists_second {
    width: 100%;
    margin-top: -14px;
}

.homepage-scientists_second-content_background {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage-scientists_second-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.homepage-scientists_second-content_text {
    width: 100%;
    box-sizing: border-box;
    padding: 115px 14.6% 0px 10.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.homepage-scientists_second-content_text-first {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #36434B;
    width: 533px;
}

.homepage-scientists_second-content_text-second {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 40px;
    line-height: 48px;
    color: #FEA65A;
}

.homepage-scientists_second-content_image {
    width: 78%;
    margin-bottom: 130px;
    align-self: center;
    margin-top: -40px;
}

@media (max-width: 900px) {
    .homepage-scientists_second {
        margin-top: 0px;
        background: linear-gradient(180.04deg, #F5F5F5 -0.09%, rgba(245, 245, 245, 0) 99.99%);
    }

    .homepage-scientists_second-content {
        display: flex;
        flex-direction: column-reverse;
    }

    .homepage-scientists_second-content_background {
        display: none;
    }

    .homepage-scientists_second-content_image {
        margin-top: 0px;
        width: 100%;
        margin-bottom: 40px;
    }

    .homepage-scientists_second-content_text {
        padding: 0px 16px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 83px;
    }

    .homepage-scientists_second-content_text-first {
        margin-bottom: 40px;
        width: 100%;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
    }
}