.upgrade-plan-prompt {
  width: 500px;
  height: 241px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upgrade-plan-prompt_cancel {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 13px;
}

.upgrade-plan-prompt_body_subheading {
  font-family: 'Roboto';
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #19262d;
}

.upgrade-plan-prompt_body_wrapper {
  display: block;
  max-width: 80%;
}
.upgrade-plan-prompt_body_content {
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 12px;
  color: #404d56;
}

.upgrade-plan-prompt_body_content > span > a {
  color: #3ca0ff;
  text-decoration: none;
}

@media (max-width: 800px) {
  .upgrade-plan-prompt {
    width: 94%;
  }

  .upgrade-plan-prompt_body_subheading {
    font-size: 18px;
  }
}
