.menu-item {
  padding: 32px 14px;
  background: #e4f3fd;
  cursor: pointer;
}

.menu-item:first-of-type {
  border-radius: 10px 10px 0px 0px;
}

.menu-item:last-of-type {
  border-radius: 0px 0px 10px 10px;
}

.menu-item-active {
  padding: 32px 14px;
  background: #36434b;
  cursor: pointer;
}

.menu-item-active:first-of-type {
  border-radius: 10px 10px 0px 0px;
}

.menu-item-active:last-of-type {
  border-radius: 0px 0px 10px 10px;
}

.item {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 500;
  line-height: 18px;
}

.active-item {
  color: #fff;
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 500;
  line-height: 18px;
}
