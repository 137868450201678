.learn-more_second {
    width: 100%;
    margin-bottom: 108px;
}

.learn-more_second-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.learn-more_second-content_header {
    max-width: 312px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.02em;
}

.learn-more_second-content_header-main {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #36434B;
}

.learn-more_second-content_header-main > span, .learn-more_second-content_main-text_header-title > span {
    color: #FEA65A;
}

.learn-more_second-content_main {
    box-sizing: border-box;
    width: 100%;
    padding: 0px 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
}

.learn-more_second-content_main-text {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
}

.learn-more_second-content_main-text_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding-left: 12px;
}

.learn-more_second-content_main-text_header-title {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-left: 19px;
    color: #36434B;
    max-width: 289px;
}

.learn-more_second-content_main-text_title {
    box-sizing: border-box;
    max-width: 403px;
    min-height: 180px;
    padding: 34px 16px 8px 12px; 
    background-image: url('../../../../img/BoxBorderLearnMore.svg'); 
    background-repeat: round;
    letter-spacing: 0.02rem;
    background-size: cover;
}

.learn-more_second-content_main-text_title > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #36434B;
}

.learn-more_second-content_main-empty {
    min-width: 152px;
    display: flex;
    justify-content: center;
}

.learn-more_second-content_main-image {
    width: 100%;
    max-width: 777px;
    display: flex;
    justify-content: center;
}

.learn-more_second-content_main-image  > video {
    width: 100%;
}

.learn-more_second-content_main-text_header-number {
    display: none;
}

@media (max-width: 950px) {
    .learn-more_second-content_main-text_title {
        max-width: 350px;
        border-right: 2px solid rgba(254, 166, 90, 0.5);
        border-radius: 4px;
    }
}

@media (max-width: 900px) {
    .learn-more_second-content_main {
        flex-direction: column;
        padding: 0;
    }

    .learn-more_second-content_main-empty {
        display: none;
    }

    .learn-more_second-content_main-image {
        max-width: none;
    }

    .learn-more_second-content_main-text_title {
        background-image: none;
        border: none;
        min-height: 0px;
        max-width: 100%;
        width: 100%;
    }

    .learn-more_second-content_main-text {
        min-width: 100%;
        box-sizing: border-box;
        padding: 0px 16px;
        margin-bottom: 30px;
    }

    .learn-more_second-content_main-text_title, .learn-more_second-content_main-text_header {
        padding: 0px;
    }

    .learn-more_second-content_main-text_header-title {
        max-width: 100%;
    }

    .learn-more_second-content_main-text_header {
        margin-bottom: 34px;
        position: relative;
        box-sizing: border-box;
        padding-right: 20px;
    }

    .learn-more_second-content_main-text_header-number {
        position: absolute;
        right: 0;
        z-index: -1;
        display: block;
    }
}