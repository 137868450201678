.payment-methods {
  margin-bottom: 24px;
}

.payment-methods-heading {
  margin-bottom: 12px;
}

.payment-methods-heading > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #404d56;
  margin-top: 32px;
}

.payment-methods-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.payment-method {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 1px 3px 20px rgba(0, 0, 0, 0.1);
  padding: 18px;
}

.select-method {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-method > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #77848d;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.select-method > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.select-method > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.select-method > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.select-method > label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #404d56;
}

.options {
  display: flex;
  align-items: center;
  gap: 10px;
}

.options > img {
  height: 25px;
}

.vipps {
  background-image: url(../../../../img/VippsLogo.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 24px;
  width: 45px;
}

@media (min-width: 1200px) {
  .payment-methods-container {
    flex-direction: row;
  }

  .payment-method {
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .select-method label {
    display: none;
  }
}
