.music {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.remove {
  padding: 4px;
  background: #fff;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  border: 1px solid gray;
  transition: ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.remove:hover {
  border: 1px solid transparent;
  background-color: #ccc;
}

.remove > img {
  height: 12px;
  width: 12px;
}
