.homepage-findgrave_first {
  width: 100%;
  margin-bottom: 100px;
}

.homepage-findgrave_first-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.homepage-findgrave_first-content_header {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 70px;
  position: absolute;
  left: 0;
}

.homepage-findgrave_first-content_header-main {
  font-family: Minion Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 37px;
  color: #36434b;
  margin-bottom: 32px;
}

.homepage-findgrave_first-content_header-main > span {
  color: #fea65a;
}

.homepage-findgrave_first-content_header-submain {
  font-family: Minion Pro;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #36434b;
  opacity: 0.8;
  margin-bottom: 40px;
  letter-spacing: 0.02em;
}

.added_here {
  margin-top: 16px;
  color: #999999;
}

.added_here a {
  text-decoration: none;
  color: #fea65a;
}

.homepage-findgrave_first-content_header-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.homepage-findgrave_first-content_header-input > input {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  background-image: url('../../../../img/SearchInput.svg');
  background-repeat: no-repeat;
  background-position: 2%;
  width: 100%;
  padding: 18px 45px 18px 38px;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.homepage-findgrave_first-content_header-input_button {
  min-width: 152px !important;
  min-height: 56.67px !important;
  background: #36434b !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161) !important;
  border-radius: 10px !important;
  font-family: 'Roboto Medium' !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #fff !important;
  text-transform: none !important;
  margin-left: -37px !important;
}

.homepage-findgrave_first-content_bg {
  width: 72%;
}

@media (max-width: 1200px) {
  .homepage-findgrave_first-content_bg {
    width: 67%;
  }
}

@media (max-width: 1130px) {
  .homepage-findgrave_first-content_bg {
    width: 62%;
  }

  .homepage-findgrave_first-content_header {
    margin-left: 40px;
  }
}

@media (max-width: 1030px) {
  .homepage-findgrave_first-content_bg {
    width: 62%;
  }

  .homepage-findgrave_first-content_header {
    margin-left: 30px;
    width: 450px;
  }
}

@media (max-width: 950px) {
  .homepage-findgrave_first-content_bg {
    width: 62%;
  }

  .homepage-findgrave_first-content_header {
    margin-left: 20px;
    width: 400px;
  }
}

@media (max-width: 890px) {
  .homepage-findgrave_first-content_bg {
    width: 62%;
  }

  .homepage-findgrave_first-content_header {
    margin-left: 20px;
    width: 350px;
  }
}

@media (max-width: 800px) {
  .homepage-findgrave_first-content_header {
    position: relative;
    margin-left: 0px;
    width: 91.5%;
  }

  .homepage-findgrave_first {
    width: 100%;
    margin-bottom: 50px;
  }

  .homepage-findgrave_first-content {
    flex-direction: column-reverse;
  }

  .homepage-findgrave_first-content_bg {
    width: 100%;
    margin-bottom: 50px;
  }

  .homepage-findgrave_first-content_header-main {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  .homepage-findgrave_first-content_header-submain {
    margin-bottom: 30px;
  }

  .homepage-findgrave_first-content_header-input {
    flex-direction: column;
    align-items: center;
  }

  .homepage-findgrave_first-content_header-input_button {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }
}
