.payment-page {
  background: #f2f5f8;
  width: 100%;
}

.payment-page-heading {
  padding: 24px;
}

.payment-page-heading > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #19262d;
}

.payment-details,
.selected-plan-details {
  background-color: #fff;
  box-shadow: 1px 3px 20px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
}

.no-payment-message {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 153.2%;
  color: #404d56;
}

.payment-details .card-information {
  margin-bottom: 40px;
}

@media (min-width: 630px) {
  .payment-details,
  .selected-plan-details {
    box-shadow: none;
    border-radius: 10px;
  }

  .payment-page-container {
    width: 80%;
    margin: 24px auto;
  }

  .payment-page-heading {
    text-align: center;
  }

  .payment-page-heading > span {
    font-size: 31.25px;
    line-height: 37px;
  }
}

@media (min-width: 1200px) {
  .payment-page-container {
    display: flex;
    gap: 24px;
    width: 70%;
  }

  .selected-plan {
    width: 350px;
    flex-shrink: 0;
    overflow: hidden;
  }

  .payment-container {
    flex: 1;
    min-width: 0;
  }

  .selected-plan-details {
    border-width: 2px 0px;
    border-style: solid;
    border-color: #19262d;
    border-radius: 10px;
  }
}
