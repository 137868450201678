.grave-avatar-container {
  position: relative;
  cursor: pointer;
  align-self: flex-start;
}

.camera-icon {
  cursor: pointer;
  background-color: #fff;
  padding: 8px;
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: -12px;
  right: -14px;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera-icon:hover {
  background-color: #f2f5f8;
}
