.container {
  width: 100%;
}

.create-membership {
  width: 80%;
  margin: 50px auto;
}

.header-container {
  margin-bottom: 42px;
}

.welcome {
  font-family: 'Roboto' Condensed;
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 12px;
  color: #36434b;
}

.create-account {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #777777;
}

.input-container {
  margin-bottom: 24px;
}

.label {
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
}

.auth-button {
  width: 100%;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  background: #f5f5f5;
}

.error {
  border: 1px solid #36434b !important;
}

.auth-button > div {
  border-radius: 4px !important;
}

.auth-button:hover {
  border-color: #36434b !important;
}

.auth-button > div > input,
.auth-button > input {
  padding: 16px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.gender-container {
  display: flex;
  align-items: center;
  gap: 18px;
}

.checkbox {
  color: #c4c4c4 !important;
}

.check-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 42px;
}

.check-container > label {
  display: inline !important;
  margin: 0px !important;
}

.check-container > label > div {
  display: none;
}

.check-container > label > span {
  padding: 0px !important;
}

.terms_conditions {
  color: #777777;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.link {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  transition: ease 0.3s;
}

.link:hover {
  color: #c65c00;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.login-container {
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
}

.login-here {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #fea65a;
  cursor: pointer;
}

.warning-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
}

.warning {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 20.56px;
  color: #c65c00;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 12px;
}

.change-input {
  font-family: 'Roboto' Condensed;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #777777;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  padding-bottom: 2px;
  transition: ease 0.3s;
}

.change-input:hover {
  font-weight: 500;
  border-color: #777777;
}

.message-container {
  padding: 12px;
}

.message {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
  margin-bottom: 16px;
}

.message-header {
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
  margin-bottom: 12px;
}

.message-bottom {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #777777;
  margin-bottom: 4px;
}

.message-team {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #777777;
  margin-bottom: 24px;
}

.message-subheading {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
  margin-bottom: 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 inset !important;
  box-shadow: 0 0 0px 1000px #f5f5f5 inset !important;
  -webkit-text-fill-color: #000 !important;
  transition: background-color 5000s ease-in-out 0s;
}
.message-btn {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

@media screen and (max-width: 550px) {
  .gender-container {
    flex-direction: column;
    align-items: normal;
  }
}

@media screen and (min-width: 820px) {
  .message-container {
    box-shadow: 0px 2px 16px 5px #0000000d;
    border-radius: 10px;
    width: 700px;
    padding: 24px;
  }
}
