.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bird-why {
  display: none;
}

.deceased-modal {
  border: none;
  outline: none;
  position: relative;
  margin: auto;
  max-width: 910px;
  margin-top: 5%;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 35px;
  padding-top: 17px;
}

.deceased-modal_image-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deceased-modal_sub {
  position: absolute;
}

.deceased-modal_text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #36434B;
  margin-top: 53px;
  margin-bottom: 61px;
}

.deceased-modal_text > span:nth-child(1) {
  color: #3ecf61;
}

.deceased-modal_text > span:nth-child(2) {
  color: #FEA65A;
  text-transform: uppercase;
}

.deceased-modal_btn-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.deceased-modal_cancel, .deceased-modal_confirmation {
  width: 299px;
  height: 51px;
  background: #D8D8D8 !important;
  border-radius: 10px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #FFFFFF !important;
  text-transform: inherit !important;
}

.deceased-modal_confirmation {
  background:#FEA65A !important;
  margin-left: 58px;
}

.deceased-modal_confir-link {
  text-decoration: none !important;
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 20px;
  position: relative;
  border: 7px;
  margin: 5px 0px;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 750px) {
  .deceased-modal {
    height: 100%;
    margin-top: 0px;
    border-radius: 0px;
    justify-content: flex-start;
  }

  .bird-why {
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;
    margin-bottom: 25px;
  }

  .bird-why_bird {
    margin-right: 20px;
  }

  .deceased-modal_btn-group {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .deceased-modal_confirmation {
    margin-left: 0px;
  }

  .deceased-modal_image-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .deceased-modal_image {
    width: 375px;
  }

  .bird-why_why {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FEA65A;
  }

  .deceased-modal_confir-link {
    margin-bottom: 16px;
  }
}