.claim-message-container {
  background: #ffefc7;
  margin-bottom: 24px;
  border: 1px solid #ffd262;
  border-radius: 6px;
}

.claim-message {
  padding: 10px 24px;
}

.message {
  color: #985000;
  text-align: center;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.claim-link {
  color: #985000;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
  transition: all ease 0.3s;
  border-bottom: 1px solid transparent;
}

.claim-link:hover {
  border-color: #985000;
}
