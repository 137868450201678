.search-input {
  display: flex;
  margin-bottom: 24px;
  background: #f2f5f8;
  border-radius: 8px;
}

.search-input > img {
  margin-left: 12px;
}

.inputs {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 17px 13px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
  flex: 1;
}
