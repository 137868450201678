.connected-graves-route {
  /* max-width: 1280px; */
  /* margin: 0 auto; */
  width: 100%;
  background: #f2f5f8;
}

/* @media (max-width: 1140px) {
    .connected-graves-route {
        max-width: 800px;
        margin: 0 auto;
    }
}

@media (max-width: 800px) {
    .connected-graves-route {
        max-width: 375px;
        margin: 0 auto;
    }
} */

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
}
