.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.list {
  border-radius: 40px;
  background: #f2f5f8;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 2px 12px;
  cursor: pointer;
}

.item {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #19262d;
  text-transform: capitalize;
}

.icon {
  color: #19262d !important;
}

.privacy-question-box {
  min-width: 90px !important;
  padding: 11px !important;
  background: #f5f5f5;
  border-radius: 10px;
}

.privacy-select > div {
  display: flex !important;
  text-align: center !important;
  background: inherit !important;
  padding: 0px !important;
}
