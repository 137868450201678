.input-element {
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
}

.title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
}

.title > span {
  color: rgba(54, 67, 75, 0.6);
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}

.inputs {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 14px 28px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
  border: 1px solid transparent;
}

.inputs:focus {
  border: 1px solid;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 17px 58px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 17px 58px;
}

@media screen and (max-width: 720px) {
  .grave-btn-container {
    display: inline;
  }
  .grave-information_content-verify {
    flex-direction: column;
  }
}
