.image-wrapper {
  position: relative;
  height: 400px;
  width: 100%;
  margin-bottom: 44px;
}

.image-wrapper > span {
  display: inline !important;
}
.image-wrapper > button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  position: absolute;
  top: 20px;
  right: 20px;
}

.image-wrapper > button:hover {
  background-color: #cccc;
}

/* .image-wrapper > button > img {
  height: 20px;
  width: 20px;
} */

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.input-wrapper {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  background: #f2f5f8;
  border-radius: 8px;
  padding: 14px 28px;
  border: 1px solid transparent;
}
.input-wrapper > input {
  background: #f2f5f8;
  width: 100%;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
}

.input-wrapper > input:focus ~ .input-wrapper {
  border: 1px solid #404d56;
}

.action-button {
  display: flex;
  gap: 12px;
  align-items: center;
}

.action-button > button {
  border: none;
  background-color: transparent;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  transition: ease 0.2s;
}

.action-button > button:hover {
  opacity: 0.5;
}
.input-element {
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
}

.title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
}

.title > span {
  color: rgba(54, 67, 75, 0.6);
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}
