.administrators-content_input {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 17px;
}
.administrators-content_input_autocomplete {
  width: 100% !important;
  background: #f2f5f8 !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  padding: 6px 20px !important;
}
.administrators-content_input
  .administrators-content_input_autocomplete
  .MuiInput-root {
  top: -3px;
}

.administrators-content_select {
  width: 100%;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding-left: 28px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #f2f5f8;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  color: #777;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
}

.label {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 12px 24px;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 12px 24px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid #fea65a;
  background: #fff;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.admin-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.container {
  padding: 12px;
  transition: ease 0.3s;
  background-color: transparent;
  position: relative;
}

.container:hover {
  background-color: #f2f5f8;
}

.remove-btn {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  position: absolute;
  top: 12px;
  right: 12px;
}

.remove-btn:hover {
  background-color: #cccc;
}
