.terms_conditions {
    color: #36434B;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 104px
}

.header_terms_condiyions{
    margin: 56px auto 0;
    font-family: Minion Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.02em;
}

.subheader_terms_condiyions {
    color: grey;
    margin: 10px auto 0;
    font-size: 24px;
    opacity: 0.8;
}

span.t {

}

.listHeader_terms_condiyions{
    margin: 26px 0 6px;
    display: block
}

.nested_terms_condiyions {
    margin: 6px 0;
    display: block;
}

.button_terms_condiyions {
    background: #FEA65A;
    box-shadow: 0px 1px 6px rgba(254, 166, 90, 0.302);
    border-radius: 10px;
    width: 180px;
    height: 55px;
    color: white;
    border: none;
    cursor: pointer;
    margin: 40px auto 52px;
    display: block;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
}

.button_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 650px){
    .header_terms_condiyions {
        margin-top: 40px
    }
    .terms_conditions {
        margin: 0 32px;
    }
    .button_terms_condiyions {
        margin: 24px auto 64px;
    }
}
