.searchbar-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 1px 4px 10px 3px #0000000d;
}

.searchbar-input > input {
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  background-image: url('../../../../../img/SearchInput.svg');
  background-repeat: no-repeat;
  background-position: 2.5%;
  width: 100%;
  padding: 18px 45px 18px 38px;
  outline: none;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 19px;
}

@media (max-width: 800px) {
  .searchbar-input {
    margin-top: 20px;
  }
}
