.video {
  border-radius: 2px;
  width: 228px;
  height: 138px;
  cursor: pointer;
  border: 1px solid gray;
  background: #000;
}

.selected-video {
  opacity: 0.5 !important;
  border-radius: 2px;
  width: 228px;
  height: 138px;
  cursor: pointer;
  border: 1px solid gray;
  background: #000;
}

@media screen and (max-width: 750px) {
  .video,
  .selected-video {
    width: 100px !important;
    height: 100px !important;
  }
}
