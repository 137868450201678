.media-container {
  border-radius: 10px;
  background: #fff;
  padding: 36px;
  position: relative;
  margin-bottom: 24px;
  min-height: 500px;
}

.media-tabs-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
  flex-wrap: wrap;
}

.images-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
}

.add-images-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
}

.upload-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.image-text-message {
  color: #404d56;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 133.187%;
  text-transform: uppercase;
}

.upload-image-input {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.upload-image-text {
  color: #55636b;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.empty-images-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 18px;
  font-weight: 400;
  line-height: 127.688%;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.loading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 500;
  line-height: 127.688%;
}

.scroll-top {
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  background-color: #404d56;
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.upload-cover-image {
  cursor: pointer;
  background: #e5e7e9;
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: 0.2s;
}

.upload-cover-image:hover {
  background: #b5b6b7;
}

.upload-cover-image > span {
  color: #19262d;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 500;
  text-transform: capitalize;
}

.heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
}

.heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 500;
  line-height: 127.688%;
}

.images-btn {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.btn {
  border: none;
  background: #fff;
  outline: none;
  padding: 8px;
  color: #19262d;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 400;
  cursor: pointer;
}

.btn-focus {
  color: #007af2;
  border: none;
  border-bottom: 2px solid #007af2;
  background: #fff;
  outline: none;
  padding: 8px;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 400;
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .images-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
