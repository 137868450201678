.interests-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 24px;
  column-gap: 24px;
  margin-top: 12px;
}

.interest {
  border-radius: 5px;
  background: #f6f6f6;
  padding: 24px 32px;
  height: max-content;
}

.interest-title {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
}

.interest > p {
  color: #6b7378;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.empty-grave-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(300px - 24px);
}

.empty-message {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.add-btn {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
}

@media screen and (min-width: 520px) {
  .interests-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
    column-gap: 24px;
    margin-top: 12px;
  }
}
@media screen and (min-width: 1020px) {
  .interests-container {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 0;
  }
}
