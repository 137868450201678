.edit-photo-menu {
    margin-left: auto;
}

.edit-photo-menu_family {
    width: 123px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit-photo-menu_family > span {
    margin-left: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #9F9F9F;
}

.edit-photo-button {
    width: 130px !important;
    padding: 3px 5px !important;
    height: 30px !important;
    text-transform: none !important;
}

.edit-photo-button_family {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.edit-photo-button_family > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #9F9F9F;
}