.content_header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
}

.content_header > span {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}

.content_header > img {
  cursor: pointer;
  transition-duration: 0.3s;
}

.content_header > img:hover {
  opacity: 0.6;
}

.message-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.message {
  color: #36434b;
  text-align: center;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.grave-information_content-verify {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.link {
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .grave-btn-container {
    display: inline;
  }

  .grave-information_content-verify {
    flex-direction: column;
  }
}
