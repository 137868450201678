.video-post_content-title {
  align-self: center;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-post_content-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #36434b;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.video-post_content-video {
  width: 100%;
}

.photo-post_content-photo_photo {
  width: 100%;
  height: 500px;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
}

.music-post_content-photo {
  position: relative;
}

.video-player {
  width: 100% !important;
  height: 500px !important;
  background-color: #f2f5f8;
}

.photo-post-content_loading {
  background-image: url('../../../../service/Loading_animation.gif');
  background-size: 150px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
}

.buttons-prev {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 6px 6px 0px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 40%;
  left: 0;
  cursor: pointer;
}

.buttons-next {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px 0px 0px 6px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 40%;
  right: 0;
  cursor: pointer;
}

.music-post_content-title.video-post,
.music-post_content-category.video-post {
  cursor: pointer;
}
