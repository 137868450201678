.subscription-alert {
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
  background-color: #ddffde;
}

.container {
  margin-top: 4px;
}

.subscription-alert .alert-title {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}

.subscription-alert .alert-message {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
}

.alert-cta > button {
  color: #985000;
}
