.music-post_content-category_tab {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.music-post_content-category_tab > .MuiTab-wrapper > img {
  margin: 0px !important;
}

.music-post {
  background: #ffffff;
  box-shadow: 1px 3px 20px 0px #0000001a;
  border-radius: 10px;
  width: 100%;
}

.music-post_content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.memories-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.music-post_content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 20px 24px;
}

.music-post_content-header_name {
  display: flex;
  flex-direction: column;
}

.music-post_content-header_name-title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 700;
  line-height: 18px;
}

.music-post_content-header_name-text {
  color: #777;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

.music-post_content-title {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 22px;
}

.music-post_content-commentary {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
}

.music-post_content-commentary > span {
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 24px;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 22px;
}

.music-post_content-title_textarea {
  color: #777;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  background: white;
  overflow-y: scroll;
  max-height: 170px;
  overflow-wrap: break-word;
  margin-bottom: 20px;
  word-wrap: anywhere;
}

.music-post_content-title_textarea_editing {
  /* width: 100%; */
  resize: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid #9aa8b2;
  background: #fff;
  color: #777;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  background: white;
  overflow-y: scroll;
  max-height: 170px;
  cursor: text;
  overflow-y: scroll;
  word-wrap: anywhere;
  background: #ffff;
  overflow-wrap: break-word;
  padding: 10px;
  margin-bottom: 12px;
  word-wrap: anywhere;
}

.music-post_content-title_form {
  display: flex;
  flex-direction: column;
}

.music-post_content-title_button {
  align-self: flex-end !important;
  text-transform: none !important;
  border-radius: 10px !important;
  background-color: #fea65a !important;
  height: 30px !important;
  color: white !important;
}

.memories_comments-area_comments {
  padding: 0 10px;
}

.music-post_content-header_name > a {
  text-decoration: none;
}

.dots-button {
  border: none;
  background-color: #fff;
  height: 25px;
  width: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  transition: ease 0.3s;
  border-radius: 50%;
}

.dots-button:hover {
  background-color: #cccc;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 12px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 9px 14px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}
