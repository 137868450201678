.year-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 50px;
  height: max-content;
  width: 100px;
  padding: 10px;
  background-color: #ccc;
}

.year-btn > button {
  color: #9aa8b2;
  font-family: 'Roboto' Condensed;
  font-size: 24px;
  font-weight: 400;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
