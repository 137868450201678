.company-name-container {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 14px;
}

.company-name-container-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.select-roles-filter_form_control {
  height: 30px !important;
}

.select-roles-filter_form_control > div {
  display: flex !important;
  text-align: center !important;
  background: #fff !important;
  padding: 0px 25px 0px 10px !important;
}

.input-element {
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex: 1;
}

.input-element-list {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 30%;
}

.title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
}

.inputs {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 14px 28px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
  border: 1px solid transparent;
}

.inputs:focus {
  border: 1px solid;
}

.workspace-content-textarea {
  width: 100%;
  margin-bottom: 14px;
}

.input-element > textarea {
  border: 1px solid transparent;
  outline: none;
  resize: none;
  width: 100%;
  height: 124px;
  box-sizing: border-box;
  padding: 14px 28px;
  border-radius: 8px;
  background: #f2f5f8;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  color: #777;
}

.input-element > textarea:focus {
  border: 1px solid;
}

.grave-actions-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 24px;
}

.grave-information-edit-delete-container {
  display: flex;
}

.action-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
}

.action-btn:hover {
  background-color: #f2f5f8;
}

.action-btn img {
  width: 16px;
}

.action-btn > button {
  border: none;
  background: none;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.disabledButton {
  cursor: not-allowed;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #fea65a;
  background: #fff;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

@media screen and (max-width: 800px) {
  .company-name-container,
  .company-name-container-list {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }

  .input-element-list {
    width: 100%;
  }

  .input-element {
    width: 100%;
  }
  .grave-btn-container {
    display: inline;
  }

  .grave-information_content-verify {
    flex-direction: column;
  }
}
