.empty-event-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
}

.empty-event-container > span {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
}
