.landing-footer {
  display: flex;
  padding: 30px 70px;
  justify-content: center;
  border-top: 1px solid rgba(154, 168, 178, 0.15);
  background: #f2f5f8;
  gap: 32px;
}

.footer-logo-title-wrapper {
  display: flex;
  align-items: center;
  flex: 0.5;
}

.landing-footer_chapter.last > div {
  display: flex;
  flex-direction: column;
}

.landing-footer_logo-subheader {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff !important;
  opacity: 0.7;
  margin-bottom: 3px;
}

.landing-footer_logo-header {
  color: #9aa8b2;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 118.1%; /* 16.534px */
  letter-spacing: -0.24;
  margin-left: 10px;
}

.landing-footer_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: #2a343a;
  border-radius: 10px;
}

.footer-container {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
}

.item-container {
  min-width: 200px;
}

.title {
  color: rgba(64, 77, 86, 0.5);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 16px;
}

.link-container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: flex-start;
}

.link {
  color: rgba(64, 77, 86, 0.5) !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  transition-duration: 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.landing-footer_chapter-mobile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.landing-footer_chapter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.landing-footer_chapter-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 16px;
}

.landing-footer_chapter-link {
  color: rgba(64, 77, 86, 0.5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  transition-duration: 0.3s;
  margin: 0 10px 0 5px;
  cursor: pointer;
  text-decoration: none;
  padding-left: 10px;
}

.landing-footer-link-border {
  border-left: 1px solid rgba(64, 77, 86, 0.5);
}

.first-link {
  padding-left: 0;
  margin-left: 0;
}

.landing-footer_logo-image-mobile {
  display: none;
}

.landing-footer_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 60%;
}

.landing-footer_content.mobile {
  display: none;
}

.landing-footer_chapter-link:hover {
  opacity: 0.5;
}

.active-link {
  color: #2a343a !important;
  font-size: 14px;
  background-color: unset;
  text-decoration: none;
}
.landing-footer_links {
  color: rgba(64, 77, 86, 0.5);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 900px) {
  .landing-footer_logo-image-mobile {
    display: block;
    margin-bottom: 5px;
  }

  .item-container {
    min-width: 150px;
  }

  .landing-footer_chapter-link.last {
    margin-right: 45px;
  }

  .landing-footer_content {
    display: none;
  }

  .footer-container {
    width: 100%;
  }

  .landing-footer_content.mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .landing-footer_content-first {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 46px;
  }

  .landing-footer_logo-image {
    display: none;
  }

  .landing-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding: 35px 15px;
    /* margin-top: 50px; */
    height: unset;
  }

  .landing-footer_chapter.last {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .landing-footer_chapter.last > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .landing-footer_links {
    margin-top: 20px;
    padding: 0 10px;
    line-height: 2;
  }
}
