.container {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  padding: 6px;
  border-radius: 25px;
  border: 1px solid #eee;
}

.inspire,
.reflect,
.enlighten {
  cursor: pointer;
  height: 18px !important;
  padding: 6px;
  width: 18px !important;
  border-radius: 50%;
}

.inspire {
  background: #ffd580;
}

.enlighten {
  background: #add8e6;
}

.reflect {
  background: #98fb98;
}
