.plan-members-heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  word-wrap: anywhere;
  max-width: 100%;
}

.sub-heading {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 21px;
}

.empty-gift-memberships {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 47.5px;
  margin-bottom: 42px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-bottom: 42px;
}
