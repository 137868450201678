.municipality-search-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 42px;
}

.municipality-not-found {
  margin: 35px 0px;
  display: grid;
  justify-content: center;
}

.municipality-not-found > span {
  margin: 20px 0px;
}

.municipality-search-content > a {
  text-decoration: none !important;
  width: 100%;
}

.no-grave-found-link {
  background-color: #fea65a;
  padding: 13px 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  transition-duration: 0.4s;
  width: 150px;
  margin: auto;
}

@media (max-width: 750px) {
  .municipality-search-content {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
