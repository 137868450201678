.container {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 24px;
}

.sub-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.calender {
  background: #e4f3fd;
  padding: 12px 18px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.title {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #737a7f;
}

.date {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #404d56;
}

.timer-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.timer-btn-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.edit-btn-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.remaining-timer {
  font-family: 'Roboto' Condensed;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.44px;
  color: #985000;
}

@media screen and (min-width: 1020px) and (max-width: 1100px) {
  .container {
    align-items: flex-start;
  }
  .sub-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .title-container,
  .timer-btn-container {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1500px) {
  .sub-container {
    flex-direction: column;
    align-items: baseline;
  }
  .title-container,
  .timer-btn-container {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 560px) {
  .container {
    align-items: flex-start;
  }
  .sub-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .title-container,
  .timer-btn-container {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 870px) {
  .sub-container {
    flex-direction: column;
    align-items: baseline;
  }
  .title-container,
  .timer-btn-container {
    width: 100%;
    justify-content: space-between;
  }
}
