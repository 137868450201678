.details-container {
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.date-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.date {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  border-right: 2px solid #9aa8b2;
  padding-right: 8px;
  margin-right: 8px;
}

.location {
  color: #9aa8b2;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
}

.title {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: normal;
  text-align: center;
  text-transform: lowercase;
}
.title::first-letter {
  text-transform: uppercase;
}
.description {
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  hyphens: auto;
  -webkit-hyphens: auto; /* For Safari */
  -moz-hyphens: auto; /* For Firefox */
  -ms-hyphens: auto; /* For Internet Explorer */
  word-break: break-word;
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.action-btn > button {
  background: transparent;
  border: none;
  padding: 8px;
  transition: ease 0.3s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.action-btn > button:hover {
  background-color: #ccc;
}
