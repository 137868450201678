
.user-info-container {
  display: flex;
}

.user-info_name {
  margin-left: 15px;
  align-self: center;
}

.user-info_name > span {
  font-family: 'Minion Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 18px;
  color: #36434B;
}

.user-info_avatar-wrapper {
  cursor: pointer;
}

.question-privacy {
  margin-left: auto;
  height: 40px;
  width: 130px;
  align-self: center;
}

.privacy-select > div {
  display: flex !important;
  text-align: center !important;
  background: inherit !important;
  padding: 0px !important;
}

.privacy-question-box {
  min-width: 90px !important;
  padding: 11px !important;
  background: #f5f5f5;
  border-radius: 7px;
  height: 18px;
}

@media (max-width: 750px) {

  .user-info-container {
    padding: 10px;
  }

  .user-info_name > span {
    font-size: 20px;
  }
}