.about-box-container {
  flex: 1;
  margin-bottom: 12px;
}

.grave-notes {
  margin-bottom: 24px;
}
.about-container {
  display: flex;
  gap: 42px;
  min-height: 500px;
}

.cropper-modal_modal,
.set-relations_modal,
.grave-picture-gallery-modal {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.cropper-modal_modal > div:last-child > div {
  border-radius: 10px !important;
}

@media (max-width: 1020px) {
  .about-box-container {
    width: 100%;
  }
}
