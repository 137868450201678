#id223223 > div > span {
  background-color: #fea65a !important;
}
.menu-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  color: #36434b;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.menu-section a {
  margin-right: 50px;
}

.menu-section a:first-child {
  margin-left: 50px;
}

.menu-section button {
  /* margin-right: 25px; */
  padding: 12px !important;
}
.menu-section button:last-child {
  margin-right: 0px;
}

.menu-section_item {
  cursor: pointer;
  /* width: 97px;
  height: 54px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #999999;
}

/* .menu-section_item:last-child {
  margin-left: 100px;
} */

.MuiTab-wrapper {
  flex-direction: row !important;
  justify-content: center;
}

/* .MuiTab-wrapper > img {
  margin-right: 7px;
} */

.PrivateTabIndicator-colorPrimary-10 {
  background-color: #fea65a !important;
}

.PrivateTabIndicator-colorPrimary-7 {
  background-color: #fea65a !important;
}

.unauthorized-mobile {
  display: none;
}

.unauthorized-full {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 1220px) {
  .menu-section a {
    margin-right: 0;
  }

  .menu-section a:first-child {
    margin-left: 0;
  }

  .menu-section button {
    margin-right: 0;
  }
}

@media screen and (max-width: 1000px) {
  .menu-section_name {
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #36434b;
    margin-top: 7px;
  }

  .menu-section a {
    margin-right: 0;
  }

  .menu-section_item {
    flex-direction: column;
  }

  .menu-section_item {
    margin-top: 20px;
  }

  .unauthorized-full {
    display: none;
  }

  .unauthorized-mobile {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .unauthorized-mobile .menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .unauthorized-mobile-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .unauth-mobile {
    width: 93%;
    margin-right: 0;
    margin: 10px 0;
  }

  .unauthorized-mobile .menu > img {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  .authorized-header-full {
    display: none;
  }
}
