.terms {
  padding: 16px;
  border: 2px dashed #404d56;
  border-radius: 8px;
  margin-bottom: 16px;
}

.name {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #404d56;
  margin-bottom: 16px;
}

.text {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #404d56;
  margin-bottom: 16px;
}

.accept-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 36px;
}

.label {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #404d56;
}

.accept-container > input[type='checkbox'] {
  background-color: #fff;
  margin: 0;
  color: #fea65a;
  width: 20px;
  height: 20px;
  border: 1px solid #404d5666;
  cursor: pointer;
}
