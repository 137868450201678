.grave-connections-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grave-avatar_listing {
  border: 3px solid #fea65a;
  box-sizing: border-box;
  border-radius: 70px 70px 0 0;
  background-color: #fff;
  margin-bottom: 20px;
  width: 122px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.grave_avatar {
  width: 100%;
  height: 100%;
  border-radius: 70px 70px 0 0;
}

.grave-connections-grid-item {
  min-height: 390px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #36434b;
}

.grave-content-name_span {
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #36434b;
  margin-bottom: 12px;
  word-break: break-word;
}

.grave-info-relation {
  margin: 15px 0px;
}

.grave-info-relation > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #fea65a;
}

.grave-info-dates {
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
}

.grave-info-dates > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.choose-grave > button {
  border: 1px solid #36434b;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff;
  width: 200px;
  height: 50px;
  margin-bottom: 15px;
  font-family: 'Minion Pro';
  font-weight: 500;
  font-size: 17px;
  color: #36434b;
}

.choose-grave > button:hover {
  background: #36434b;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

.no-graves {
  margin: 160px auto;
  text-align: center;
}

.no-graves > h1 {
  color: #fea65a;
  font-family: 'Minion Pro';
}

@media (max-width: 500px) {
  /* .grave-connections-grid {
    grid-template-columns: auto;
  } */

  .grave-connections-grid-item {
    margin: 20px 0px;
  }

  .no-graves > h1 {
    color: #fea65a;
    font-size: 23px;
  }
}

@media (min-width: 690px) and (max-width: 1020px) {
  /* .grave-connections-grid {
    grid-template-columns: auto auto;
  } */

  .grave-connections-grid-item {
    margin: 20px 0px;
  }

  .no-graves > h1 {
    color: #fea65a;
    font-size: 23px;
  }
}

@media (min-width: 1020px) and (max-width: 1150px) {
  /* .grave-connections-grid {
    grid-template-columns: auto auto auto;
  } */

  .grave-connections-grid-item {
    margin: 20px 0px;
  }
}

@media (min-width: 1150px) and (max-width: 100%) {
  /* .grave-connections-grid {
    grid-template-columns: auto auto auto auto;
  } */

  .grave-connections-grid-item {
    margin: 20px 0px;
  }
}
