.order-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.list-button {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  background: transparent;
  color: #404d56;
}

.discounted-amount {
  cursor: pointer;
  background: #e4f3fd;
  padding: 6px;
  color: #985000;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}

.discount-month {
  color: #404d56 !important;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}

.order-details {
  padding-top: 16px;
  margin-bottom: 32px;
}

.selected-plan-price,
.selected-plan-taxes,
.total-price,
.discount-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}

.order-heading {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #404d56;
}

.tax-heading,
.selected-price,
.selected-plan-name,
.total-price-heading,
.total-price-monthly {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #404d56;
}

.selected-plan-add-more {
  display: flex;
  gap: 12px;
  align-items: center;
}

.add-more-member {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 4px;
  transition: all ease 0.3s;
}

.add-more-member:hover {
  background: #f2f5f8;
}

.tax-price {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  color: #404d56;
}

.discount-price {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-align: right;
  color: #19262d;
}

.plans-list > button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
  transition: all ease 0.5s;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #77848d;
  padding: 0;
}
