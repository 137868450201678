.memories_comments-area {
  background: #fff;
  margin-bottom: 10px;
  /* width: 100%; */
}

.memories_comments-area_content {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  box-sizing: border-box;
  padding: 18px;
  background: #f1f1f1;
}

.memories_comments-area_content-field {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 28px;
}

.memories_comments-area_content-input {
  /* width: 100%; */
  /* border-radius: 6px;
  background: #ececec; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 2px 8px; */
  gap: 8px;
}

.memories_comments-area_content-input > textarea {
  width: 100%;
  border: none;
  outline: none;
  background: #fff;
  color: #838383;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 14px; /* 77.778% */
  resize: none;
  padding-top: 18px;
  border-radius: 25px;
  padding-left: 18px;
}

.memories_comments-area_content-input_button {
  border: none;
  background: #fea65a;
  border-radius: 50%;
  cursor: pointer;
  padding: 8px;
  display: flex;
}
