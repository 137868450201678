.homepage-investigate_fourth {
    width: 100%;
    margin-bottom: 219px;
    background: #36434B;
}

.homepage-investigate_fourth-content {
    width: 100%;
    box-sizing: border-box;
    padding: 64px 0px 90px;
    position: relative;
}

.homepage-investigate_fourth-content_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepage-investigate_fourth-content_header-main {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.homepage-investigate_fourth-content_header-main > span {
    color: #FEA65A;
}

.homepage-investigate_fourth-content_header-submain {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.025em;
    color: #FFFFFF;
    opacity: 0.8;
    width: 690px;
    margin-bottom: 38px;
}

.homepage-investigate_fourth-content_header-contact {
    width: 180px;
    height: 55px;
    background: #FEA65A;
    box-shadow: 0px 1px 6px rgba(254, 166, 90, 0.302);
    border-radius: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    border: none;
}

.homepage-investigate_fourth-content_header-contact:hover {
    box-shadow: 0px 6px 12px rgba(254, 166, 90, 0.302);
}

.homepage-investigate_fourth-content_first {
    position: absolute;
    left: 0;
    top: -2%;
}

.homepage-investigate_fourth-content_second {
    transform: scale(-1, 1);
    width: 73px;
    height: 291px;
    position: absolute;
    right: 0;
}

@media (max-width: 800px) {
    .homepage-investigate_fourth {
        margin-bottom: 0px;
    }

    .homepage-investigate_fourth-content_header-main {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .homepage-investigate_fourth-content_header-submain {
        width: 100%;
        font-family: 'Roboto';
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 70px;
    }

    .homepage-investigate_fourth-content {
        padding: 70px 16px 39px;
    }

    .homepage-investigate_fourth-content_first {
        width: 85px;
        height: 319px;
        bottom: 0;
        top: auto;
        left: -2%;
    }

    .homepage-investigate_fourth-content_second {
        width: 83px;
        height: 319px;
        top: -2%;
        right: -4%;
    }
}

@media (max-width: 600px) {
    .homepage-investigate_fourth-content_first {
        width: 85px;
        height: 319px;
        bottom: 0;
        top: auto;
        left: -4%;
    }

    .homepage-investigate_fourth-content_second {
        width: 83px;
        height: 319px;
        top: -2%;
        right: -5%;
    }
}

@media (max-width: 500px) {
    .homepage-investigate_fourth-content_first {
        width: 85px;
        height: 319px;
        bottom: 0;
        top: auto;
        left: -5%;
    }

    .homepage-investigate_fourth-content_second {
        width: 83px;
        height: 319px;
        top: -2%;
        right: 0;
    }
}