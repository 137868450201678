.container {
  margin-top: 4px;
}

.alert-title {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}

.alert-message,
.sub-heading {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
}

.message-alert {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
}

.alert-cta > button {
  color: #985000;
}

.message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
