.grave-picture-gallery_photos-photo {
  width: 100%;
  height: 600px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
}

.video-player {
  width: 100% !important;
  height: 600px !important;
  background-color: #000;
}

.gallery_photos-photo {
  height: inherit;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  background: #000;
}

.grave-picture-gallery_photos-navigations_dot {
  min-width: 0px !important;
}

.buttons-prev {
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 6px 6px 0px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 40%;
  left: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.buttons-next {
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px 0px 0px 6px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 40%;
  right: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.show-buttons {
  opacity: 1;
}

.upload-cover-image {
  /* background: #e7e6e9;*/
  background: rgba(39, 47, 52, 0.5);
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 8px;
  width: max-content;
  transition: 0.2s;
}

.upload-cover-image:hover {
  background: rgba(39, 47, 52, 0.7);
}

.camera-title {
  color: #fff;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
}

.image-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  top: 10px;
  position: absolute;
  right: 10px;
}

.image-actions > button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

.image-actions > button:hover {
  background-color: #cccc;
}

.dots-container {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dots-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dots-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.image-input {
  display: none;
}

.set-cover-image {
  display: flex;
  align-items: center;
  padding: 12px;
  flex-direction: row;
  position: absolute;
  bottom: 12px;
  right: 12px;
  background: rgba(39, 47, 52, 0.5);
  border-radius: 10px;
  cursor: pointer;
}

.set-cover-image:hover {
  background: rgba(39, 47, 52, 0.7);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  margin-bottom: 42px;
}

.set-cover-image > input[type='checkbox'] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #fff;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.set-cover-image > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.set-cover-image > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.set-cover-image > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.set-cover-image > label {
  color: #fff;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
}

.action-container {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.more-icon {
  color: #fea65a;
  font-size: 32px !important;
}

.popover-container {
  display: flex;
  gap: 12px;
  padding: 12px;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media screen and (max-width: 750px) {
  .grave-picture-gallery_photos-photo {
    width: 100%;
    height: 300px;
  }

  .video-player {
    width: 100% !important;
    height: 300px !important;
    background-color: #000;
  }
}

@media screen and (max-width: 480px) {
  .camera-title {
    display: none;
  }

  .upload-cover-image {
    padding: 8px;
    background: #000;
    border-radius: 50%;
    bottom: 25px;
    right: 15px;
  }
}
