.edit-virtue {
  background-color: #fff;
  border: none;
  outline: none;
  border-radius: 10px;
}

.edit-virtue_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-roles-filter_form_control {
  height: 30px !important;
}

.select-roles-filter_form_control > div {
  display: flex !important;
  text-align: center !important;
  background: #fff !important;
  padding: 0px 30px 0px 10px !important;
}

.edit-virtue_content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.edit-virtue_content-header > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #36434b;
}

.edit-virtue_content-header > img {
  transition-duration: 0.3s;
  cursor: pointer;
}

.edit-virtue_content-header > img:hover {
  opacity: 0.6;
}

.edit-virtue_content-suggestions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
  flex-wrap: nowrap;
}

.edit-virtue_content-subheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 19px;
}

.edit-virtue_content-subheader > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #36434b;
}

.edit-virtue_content-subheader > span > span {
  color: #fea65a;
}

.edit-virtue_content-textarea {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
}

.edit-virtue_content-textarea > textarea {
  border: none;
  outline: none;
  resize: none;
  background: #f5f5f5;
  border-radius: 10px;
  width: 100% !important;
  padding: 16px 11px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #36434b;
}

@media (max-width: 750px) {
  .edit-virtue_content-header {
    margin-bottom: 28px;
  }

  .edit-virtue_content-suggestions {
    margin-bottom: 38px;
  }

  .edit-virtue_content-subheader {
    margin-bottom: 10px;
  }
}
