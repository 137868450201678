.membership-page {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.membership-page_content {
  display: flex;
  flex-direction: column;
}

.membership-page_content-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.membership-page_content-header_header {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}

.membership-page_orange-line {
  width: 100px;
  border: 1px solid #fea65a;
  margin-bottom: 20px;
}

.membership-page_content-header_subheader {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 167.19%;
  color: #999999;
}

.agreement {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 167.19%;
  color: #999999;
}

.agreement > a {
  font-family: 'Roboto';
  font-weight: 500;
  color: #fea65a;
  text-decoration: none;
  text-transform: capitalize;
}

.membership-page_content-header-no-result {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 167.19%;
}

.membership-page-details_border {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 1px 4px 10px 3px rgba(0, 0, 0, 0.05);
}

.membership-page-details-yearly {
  width: 360px;
  height: 181px;
  background: linear-gradient(180deg, #2997ff 0%, #2997ff 100%);
  border-radius: 10px 10px 0px 10px;
  position: relative;
  margin-bottom: 30px;
}

.membership-page-details_center_image {
  position: absolute;
  left: 18%;
}

.membership-page-details-yearly_status {
  padding: 20px;
}

.membership-page-details-Conscious {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.membership-page-details-Conscious-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.membership-page-details-Conscious-details_heading {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
}

.membership-page-details-cost_label {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 2;
  margin-top: 25px;
}

.membership-page-details-cost_label > span {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.membership-page-details-cost_value {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 2;
  margin-top: 10px;
  margin-bottom: 5px;
}

.membership-page-details-cost_value > span {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
}

.membership-page-details_bottom_heading {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.membership-page-submenu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 30px;
  margin-bottom: 30px;
}

.membership-page-submenu_item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.membership-page-submenu_item > span {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #9f9f9f;
}

.membership-page-details_update_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
}

.membership-page-details_update_button > button {
  border: none;
  background: #fea65a;
  border-radius: 10px;
  padding: 16px 24px;
  font-family: 'Minion Pro';
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
}

.membership-page-billing_method {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.membership-page-billing_method span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #36434b;
}

.membership-page-billing-method-next_billing {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #999999;
  margin-bottom: 30px;
}

.membership-page-billing-method_account_info {
  display: flex;
  align-items: center;
  gap: 40px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.membership-page-billing-method_account_info_visa {
  margin-right: 20px;
}

.card-methods {
  display: flex;
  align-items: center;
}

/* .membership-page-billing_method_button:hover {
  background-color: #fea65a;
} */

.membership-page_content-main {
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  position: relative;
}

.membership-page_content-main_blue-square {
  width: 265px;
  height: 213px;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #fea65a 0%, #fea65a 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 0px 0px 8px;
}

.membership-page_content-main_blue-square_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 3;
  width: inherit;
  height: inherit;
  padding-bottom: 15px;
}

.membership-page_content-main_blue-square_line {
  position: absolute;
  z-index: 1;
  width: inherit;
  height: inherit;
  background-image: url('../../../img/Lines.svg');
  background-repeat: no-repeat;
  background-position: 50%;
}

.membership-page_content-main_blue-square_dots {
  position: absolute;
  z-index: 2;
  width: inherit;
  height: inherit;
  background-image: url('../../../img/Dots223.svg');
  background-repeat: no-repeat;
  background-position: 50%;
}

.membership-page_content-main_blue-square_content-header {
  width: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 26px;
}
.membership-page_content-main_blue-square_content-header > img {
  padding-left: 16px;
}

.membership-page_content-main_blue-square_content-header > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  padding-right: 23px;
}

.membership-page_content-main_blue-square_content-price {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.membership-page_content-main_blue-square_content-price_column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.membership-page_content-main_blue-square_content-price_column-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.membership-page_content-main_blue-square_content-price_column-value {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 10px;
}

.membership-page_content-main_blue-square_content-footer {
  width: 233px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 90%;
  margin-top: 8px;
}

.membership-page_content-main_blue-square_content-footer_text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.membership-page_content-main_white-square {
  width: 438px;
  padding: 32px 15px 15px 0;
  /* height: 181px; */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}

.membership-page_content-main_white-square_content {
  display: flex;
  flex-direction: row;
  margin-left: 24px;
}

.membership-page_content-main_white-square_title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #9f9f9f;
  margin-left: 24px;
}

.membership-page_content-main_white-square_content-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 40px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #9f9f9f;
}

.membership-page_content-main_white-square_content-column > span {
  width: 100%;
  padding-top: 24px;
}

.membership-page_content-main_white-square_content-column img {
  padding-right: 9px;
}

.membership-page_content-change {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.membership-page_content-billing {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}

.membership-page_content-billing_text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #36434b;
  margin-bottom: 6px;
}

.membership-page_content-billing_subtext {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  margin-bottom: 30px;
}

.membership-page_content-billing_card {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.membership-page_content-billing_card > img {
  margin-right: 30px;
}

.membership-page_content-billing_card-ending,
.membership-page_content-billing_card-country {
  margin-right: 60px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.membership-page_content-billing_card-country {
  margin-right: 40px;
}

.membership-detail-wrapper {
  margin: 10px 10px 10px 0px;
}

.membership-detail_key {
  font-size: 18px;
  color: #999999;
}

.membership-detail_value {
  margin: 0px 10px;
  font-size: 19px;
  font-weight: 500;
  color: #fea65a;
}

.redirect-to-plans-button {
  background-color: #fea65a !important;
  color: #ffffff !important;
  border-radius: 6px !important;
}
@media (max-width: 1100px) {
  .membership-page {
    width: auto;
    max-width: 898px;
  }

  .membership-page_content-main_white-square_content-column {
    margin-right: 30px;
  }

  .membership-page_content-main_blue-square {
    width: 300px;
  }
}

@media (max-width: 930px) {
  .membership-page {
    width: 100%;
  }

  .membership-page-submenu {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
  }

  .membership-page_content-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .membership-page_content-main_blue-square {
    width: 340px;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .membership-page_content-main_white-square {
    width: 340px;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 0px;
  }

  .membership-page_content-main_white-square_content {
    margin-bottom: 26px;
  }

  .membership-page_content-main_white-square_content-column {
    margin-right: 20px;
  }

  .membership-page_content-main_white-square_content-column:last-child {
    margin-right: 0px;
  }
}

.more-icon-container {
  cursor: pointer;
  border-radius: 6px;
  background: transparent;
  transition: ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-icon-container:hover {
  background: #dfdfdf;
}

.more-icon {
  padding: 8px;
}

.remove-payment-method {
  padding: 8px 12px;
  color: #36434b;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  background: #fff;
  cursor: pointer;
  transition: ease 0.3s;
}

.remove-payment-method:hover {
  background: #dfdfdf;
}

@media (max-width: 765px) {
  .membership-page_border {
    margin: 40px 10px;
    border: 1px solid #dfdfdf;
  }

  .membership-page_content-header_header {
    font-size: 20px;
    line-height: 23px;
  }

  .membership-page_content-header_subheader {
    font-size: 14px;
    line-height: 24px;
  }

  .membership-page-submenu {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }

  .membership-page-details-yearly {
    width: auto;
  }

  .membership-page-details_update_button > button {
    font-size: 16px;
    line-height: 15px;
  }

  .membership-page_content-main {
    display: flex;
    flex-direction: column;
  }

  .membership-page-submenu_item > span {
    font-size: 16px;
    line-height: 19px;
  }

  .membership-page-billing_method {
    flex-wrap: wrap;
  }

  .membership-page-billing_method span {
    font-size: 16px;
    line-height: 19px;
    word-wrap: anywhere;
  }

  .membership-page-billing-method_account_info_visa {
    margin-right: 10px;
  }

  .membership-page-billing-method_account_info {
    gap: 10px;
    flex-wrap: wrap;
  }

  .membership-page-billing-method-next_billing {
    font-size: 14px;
    line-height: 20px;
  }
  .card-methods {
    flex-wrap: wrap;
    gap: 8px;
  }

  .membership-page_content-main_blue-square {
    width: 340px;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .membership-page_content-main_white-square {
    width: 340px;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .membership-page_content-main_white-square_content {
    margin: 40px 16px;
  }

  /* .membership-page_content-main_white-square_content-column {
        margin-right: 50px;
    } */

  /* .membership-page_content-main_white-square_content-column:last-child {
    margin-right: 0px;
  }

  .membership-page_content-change {
    margin-top: -50px;
  } */

  .membership-page_content-billing_card-ending {
    margin-right: 35px;
  }
}
