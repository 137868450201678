.container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 24px 0;
  max-width: max-content;
}

.title {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
}
