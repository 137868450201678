.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 2px;
}

.image {
  height: 100px;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
  border: 1px solid lightgray;
}

.selected-image {
  opacity: 0.5 !important;
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  border: 1px solid lightgray;
}

.container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin: 24px 0;
  max-width: max-content;
}

.title {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
}
