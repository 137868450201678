.member-card {
  padding: 24px 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 4px 10px 3px rgba(0, 0, 0, 0.05);
  /* max-width: max-content; */
}

.current-user {
  color: #999;
  font-family: 'Roboto';
  font-size: 16px;
}

.member-container {
  display: flex;
  gap: 16px;
}

.user-container {
  display: flex;
  flex-direction: column;
}

.user-name {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 15px;
}

.connect-btn {
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #19262d;
  background: #fff;
  padding: 12px;
  color: #19262d;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
}

.user-confirmation {
  padding: 10px;
  background-color: #219653;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  max-width: max-content;
}

.date-of-birth,
.date-of-death {
  margin-left: 8px;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.relation-container {
  padding: 12px;
  background: #f1f9ff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.relation {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #b3661b;
  padding-right: 4px;
  margin-right: 4px;
  border-right: 1px solid #0000001a;
}

.current-user-relation {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  color: #999999;
}

.date-of-birth-container {
  margin-bottom: 14px;
}
