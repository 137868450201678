.video-post_content-title {
  align-self: center;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-post_content-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #36434b;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.video-post_content-video {
  width: 100%;
}

.photo-post_content-photo_photo {
  width: 100%;
  height: 500px;
  cursor: pointer;
  object-fit: contain;
  object-position: center;
}

.music-post_content-photo {
  position: relative;
}

.photo-title {
  position: absolute;
  opacity: 1;
  bottom: 0px;
  left: 0px;
  background: #0000004d;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: left;
  padding: 20px 24px;
  color: #e4f3fd;
  width: calc(100% - 48px);
  transition: all 0.3s;
}

.video-player {
  width: 100% !important;
  height: 400px !important;
  background-color: #f2f5f8;
}

.photo-post-content_loading {
  background-image: url('../../../../service/Loading_animation.gif');
  background-size: 150px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
}

.buttons-prev {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 6px 6px 0px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 40%;
  left: 0;
  cursor: pointer;
}

.buttons-next {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px 0px 0px 6px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 40%;
  right: 0;
  cursor: pointer;
}

.music-post_content-title.video-post,
.music-post_content-category.video-post {
  cursor: pointer;
}

.react-player-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fea65a;
  height: 60px !important;
  width: 60px !important;
  transition: all 0.4s;
  cursor: pointer;
}

.loading-image {
  position: absolute;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.3);
}

.loading-image > span {
  color: #fea65a;
  font-size: 18px;
  font-style: Roboto;
  font-weight: 400;
}
@media screen and (min-width: 750px) {
  .photo-title {
    opacity: 0;
  }

  .photo-post_content-photo_photo {
    height: auto;
  }
  .music-post_content-photo:hover .photo-title {
    opacity: 1;
  }
}

@media screen and (max-width: 750px) {
  .photo-post_content-photo_photo {
    height: auto;
  }
  .video-player {
    height: auto !important;
  }
}
