.detail {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.title {
  color: #9aa8b2;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  flex: 1;
}

.value {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  flex: 1;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 32px;
}

.cancel {
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  padding: 15px 50px;
  text-transform: uppercase;
}
.conform {
  border-radius: 8px;
  background: #404d56;
  border: none;
  color: #fff;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  padding: 15px 50px;
  text-transform: uppercase;
}
