.user-payment-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 26px;
  cursor: pointer;
  padding: 24px 16px;
}
.payment-wrapper {
  display: flex;
  align-self: center;
  gap: 24px;
}

.select {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #77848d;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.select > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.select > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.select > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.select > label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #404d56;
  cursor: pointer;
}

.images {
  display: flex;
  align-items: center;
  gap: 10px;
}

.images > img {
  height: 25px;
}
