.container {
  margin-bottom: 24px;
}

.document-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
}

.heading {
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
  margin-bottom: 4px;
}

.sub-heading {
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
  color: #9aa8b2;
}

.select-option {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #1a1a1a1a;
  padding: 14px;
  border-radius: 4px;
  flex: 1;
  cursor: pointer;
  transition: ease 0.3s;
}

.select-option:hover {
  border-color: #36434b;
}

.select-option-selected {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #fea65a;
  padding: 14px;
  border-radius: 4px;
  flex: 1;
  cursor: pointer;
}

.select-option-selected > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #404d5666;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.select-option-selected > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.select-option-selected > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.select-option-selected > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.select-option-selected > label {
  font-family: 'Noto' 'Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #1a1a1a66;
  cursor: pointer;
}

.select-option > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #404d5666;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.select-option > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.select-option > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.select-option > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.select-option > label {
  font-family: 'Noto' 'Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #1a1a1a66;
  cursor: pointer;
}
