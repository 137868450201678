/* Warning mesage react-toastify */
.user-notification-toast {
  display: flex;
  align-items: center !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
}

.user-notification-toast {
  background: rgb(235, 236, 237) !important;
}

.user-notification-toast.success {
  background: rgb(233, 244, 238) !important;
}

.user-notification-toast.default {
  background: #ffffff !important;
}

.user-notification-toast .Toastify__close-button {
  align-self: center !important;
  opacity: 1 !important;
}

.user-notification-toast .Toastify__close-button > svg {
  height: 16px !important;
  width: 16px !important;
}

.user-notification-toast.warning .Toastify__close-button > svg {
  fill: #36434b !important;
}

.user-notification-toast.success .Toastify__close-button > svg {
  fill: #219653 !important;
}

.user-notification-toast .user-notification-toast_message {
  display: flex;
  align-items: center;
  color: #36434b !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
}

.user-notification-toast .user-notification-toast_message > img {
  margin-right: 16px;
}
