.video-container {
  position: relative;
  margin-bottom: 24px;
  height: 483px;
  width: 100%;
}

.video-container > video {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: #000;
}

.video-container > button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  position: absolute;
  top: 20px;
  right: 20px;
}

.video-container > button:hover {
  background-color: #cccc;
}

/* .video-container > button > img {
  height: 30px;
  width: 30px;
} */

.input-element {
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
}

.title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
}

.title > span {
  color: rgba(54, 67, 75, 0.6);
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
}

.inputs {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 14px 28px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
  border: 1px solid transparent;
}

.inputs:focus {
  border: 1px solid;
}

.link-error {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
}

.image-wrapper {
  position: relative;
  height: 483px;
  width: 100%;
  margin-bottom: 44px;
}

.image-wrapper > span {
  display: inline !important;
}

.image-wrapper > button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  position: absolute;
  top: 24px;
  right: 24px;
}

.image-wrapper > button:hover {
  background-color: #cccc;
}

.image-wrapper > button > img {
  height: 30px;
  width: 30px;
}

.image {
  height: 483px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.input-wrapper {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  background: #f2f5f8;
  border-radius: 8px;
  padding: 14px 28px;
  border: 1px solid transparent;
}
.input-wrapper > input {
  background: #f2f5f8;
  width: 100%;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
}

.input-wrapper > input:focus ~ .input-wrapper {
  border: 1px solid #404d56;
}

.action-button {
  display: flex;
  gap: 12px;
  align-items: center;
}

.action-button > button {
  border: none;
  background-color: transparent;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  transition: ease 0.2s;
}

.action-button > button:hover {
  opacity: 0.5;
}

.video-player {
  position: relative;
  margin-bottom: 44px;
}

.video-player > button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  position: absolute;
  top: 20px;
  right: 20px;
}

.video-player > button:hover {
  background-color: #cccc;
}
