.gender-container {
  display: flex;
  align-items: center;
  gap: 18px;
}

.gender-btn {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #f5f5f5;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #777777;
  padding: 19px 40px;
  transition: ease 0.3s !important;
  cursor: pointer;
  text-transform: capitalize;
}

.gender-btn:not(.error):hover {
  border-color: #36434b;
}

.error {
  border-color: #c65c00;
}

.active {
  background-color: #36434b;
  color: #fff;
}

@media screen and (max-width: 550px) {
  .gender-container {
    flex-direction: column;
    align-items: normal;
  }
}
