.music-post {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 62%;
}

.music-post_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.music-post_content-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 17px 18px;
}

.music-post_content-header_name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 7px;
}

.music-post_content-header_name-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #FEA65A;
}

.music-post_content-header_name-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #777777;
}

.music-post_content-title {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 22px;
}

.music-post_content-title > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #36434B;
}

.add-by-link_header-music_image {
    margin-right: 10px;
}

.music-post_content-songs {
    display: flex;
    flex-direction: column;
    width: calc(100% - 18px);
    box-sizing: border-box;
    padding-left: 18px;
}

.music-post_content-player {
    width: 100%;
    background: #F5F5F5;
    border-radius: 6px;
    height: 54px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 43px 0px 24px;
}

.music-post_content-player_play {
    width: 30px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FEA65A;
    margin-right: 11px;
}

.music-post_content-player_play > img {
    margin-left: 3px;
}

.music-post_content-player_author {
    width: 100%;
}

.music-post_content-player_author > div {
    height: 4px;
    background: rgba(54, 67, 75, 0.1);
    border-radius: 4px;
    width: 90%;
    margin-top: 8px;
}

.music-post_content-player_duration {
    margin-left: auto;
}

.music-post_content-player_duration > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #9F9F9F;
}

.music-post_content-category {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 50px 100px 50px 111px;
}

.music-post_content-category > div {
    width: 100%;
}

.music-post_content-commentary {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 20px;
}

.music-post_content-commentary > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #9F9F9F;
    margin: 10px 0;
}

.music-post_content-commentary > div:last-child {
    width: 100%;
    height: 1px;
    background-color: #C9CACB;
}

.music-post_content-photo {
    position: relative;
    padding: 10px;
}

.music-post_content-title.photo-post, .music-post_content-category.photo-post, .music-post_content-commentary.photo-post {
    cursor: pointer;
} 

@media (max-width: 750px) {
    .music-post {
        background: #FFFFFF;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
        border-radius: 10px;
        width: 97%;
        margin: auto;
    }

    .music-post_content-category {
        padding: 30px 50px 27px 55px;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    .music-post {
        width: 65%;
        margin: auto;
    }
}