.hug-modal-body {
    width: 100%;
    max-width: 423px;
}

.hug-modal-body_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 42px 20px 56px;
}

.hug-modal-body_content-image {
    position: relative;
    margin-bottom: 36px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hug-modal-body_content-image_main {
    max-width: 288px;
    max-height: 288px;
    height: 100%;
    width: 100%;
}

.hug-modal-body_content-image_close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;
    transition: 0.3s;
}

.hug-modal-body_content-image_close:hover {
    opacity: 0.5;
}

.hug-modal-body_content-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hug-modal-body_content-text_title {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FEA65A;
    margin-bottom: 9px;
}

.hug-modal-body_content-text_subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #36434B;
}