.plan-details-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.plan-details-header_product_name {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #19262d;
}

.plan-details-header_product_desc {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #404d56;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
}

.modal-header > img {
  height: 20px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.modal-header > img:hover {
  opacity: 0.6;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

.btn-container > button {
  cursor: pointer;
  border: none;
  background: #404d56;
  border-radius: 10px;
  color: #fbfbfc;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  padding: 16px 58px;
}

.empty-message {
  padding: 12px;
  background: #ddffde;
  border-radius: 10px;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 24px;
  line-height: 19px;
  color: #404d56;
}
