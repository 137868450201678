.header-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.add-button {
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  border-radius: 5px;
  border: 1px solid #404d56;
  background: #404d56;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  text-transform: capitalize;
}
