.header-section {
  height: 90px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 160;
  background-color: #fff;
  width: 100%;
}

.log-in {
  background: #fafafa;
  border-radius: 40px;
  color: #fea65a;
  width: 53px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.log-in:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}

.header-section_first-row_mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
}

.sign-in-options {
  border-radius: 10px;
  border: 1px solid #36434b;
  height: 40px;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  min-width: 90px;
  padding: 0 15px;
  cursor: pointer;
}

.sign-in-options:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}

.login_btn {
  color: #36434b;
  text-align: center;
  background-color: #fff;
  /* margin-right: 20px; */
}

.register_btn {
  background: #36434b;
  color: #fff;
}

.login-btn-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 1220px) {
  .header-section_first-row_mobile {
    padding: 10px;
    width: calc(100% - 32px);
  }
}
@media screen and (max-width: 532px) {
  .header-section {
    height: 65px;
  }

  .sign-in-options {
    padding: 0 10px;
  }

  .login_btn {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1000px) {
  .login-btn-container {
    display: none;
  }
}
