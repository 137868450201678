.container {
  width: 70%;
  margin: 0px auto;
}

.heading-container {
  padding: 40px 0px;
}

.agreement-header {
  color: #19262d;
  font-family: Roboto;
  font-size: 31.25px;
  font-weight: 500;
  margin-bottom: 12px;
}

.sub-header {
  color: #19262d;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

.empty-agreement {
  color: #fea65a;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-agreements {
  cursor: pointer;
  color: #fea65a;
  margin-bottom: 12px;
}

@media screen and (max-width: 765px) {
  .container {
    padding: 24px;
    width: auto;
    margin: 0;
  }
}
