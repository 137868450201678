.heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 32px;
  font-weight: 500;
  word-wrap: anywhere;
  max-width: 100%;
  margin-bottom: 32px;
}

.title {
  font-family: 'Roboto';
  font-size: 22px;
  line-height: 21px;
  color: #36434b;
  margin-bottom: 16px;
  text-transform: capitalize;
}

.details {
  padding: 24px;
  box-shadow: 1px 4px 10px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.message {
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 21px;
  color: #36434b;
  margin-bottom: 24px;
}

.confirm-message {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  text-align: center;
  color: #36434b;
  margin-bottom: 32px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

.confirm-btn {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}
