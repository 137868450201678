.music-player_player {
    box-shadow: none !important;
    margin-bottom: 10px;
}

.music-player {
    flex-direction: row;
    align-items: center;
}

.music-player_player .rhap_controls-section, .music-player_player .rhap_time.rhap_current-time, .music-player_player .rhap_progress-indicator {
    display: none !important;
}

.music-player_player .rhap_progress-filled {
    background-color: #fea65a !important;
}

.music-player_button-image {
    width: 9px;
    height: 12px;
    margin-right: -2px;
}

.music-player_button {
    min-width: 0px !important;
    width: 38px !important;
    height: 35px !important;
    background-color: #fea65a !important;
}

.music-player_button-secs-prev {
    min-width: 0px !important;
    min-height: 0 !important;
    width: 50px;
    height: 28px;
    align-items: normal !important;
    justify-content: center !important;
    padding: 0px !important;
    margin: 10px 0px !important;
}

.music-player_button-secs-next {
    min-width: 0px !important;
    min-height: 0 !important;
    width: 50px;
    height: 28px;
    align-items: normal !important;
    justify-content: center !important;
    padding: 0px !important;
    margin: 10px 0px 0px 10px !important;
}

.music-player-buttons {
  display: flex;
  justify-content: center;
}