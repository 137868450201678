.save-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-container > button {
  border: none;
  background: #404d56;
  border-radius: 10px;
  color: #fff;
  padding: 12px;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}

.date-container,
.time-container,
.title-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.label {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 400;
}

.checkbox {
  color: #c4c4c4 !important;
}

.check-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 42px;
}

.check-container > label {
  display: inline !important;
  margin: 0px !important;
}

.check-container > label > span {
  padding: 0px !important;
}

.recurring {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
