.search-graves {
  display: flex;
  flex-direction: column;
}

.search-graves_none {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.search-graves_title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
}

.search-graves_results {
  margin-left: 3px;
  color: black;
}

.search-graves_grave {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.MuiBox-root-10 {
  padding: 0 !important;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  color: #fea65a;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

.search-graves_none-image {
  margin-bottom: 37px;
}

.search-graves_none-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #36434b;
  margin-bottom: 15px;
  text-align: center;
}

.search-graves_none-text > span {
  color: #fea65a;
}

.search-graves_none-subtext {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #c9cacb;
  margin-bottom: 16px;
}

.search-graves_none-button {
  background-color: #fea65a;
  padding: 13px 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  transition-duration: 0.4s;
}

.search-graves_none-button > img {
  margin-right: 8px;
}

.search-graves_none-button:hover {
  opacity: 0.8;
}

@media (max-width: 750px) {
  .search-graves {
    width: 100%;
  }
  .search-graves_grave {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .search-graves_none {
    margin-top: 30px;
    width: 335px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }

  /* .search-graves_grave {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

  .search-graves_none-image {
    width: 281px;
  }
}
