.video-post {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 0px 50px 15px;
}

.video-post_header {
    display: flex;
    flex-direction: column;
}

.video-post_header > span:first-child {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 8px;
}

.video-post_header > input {
    border: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 15px;
    height: 42px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #36434B;
    background: #F5F5F5;
    border-radius: 6px;
}

.video-post_header-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
}

.video-post_header-image > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 17px;
    color: #FEA65A;
    margin-bottom: 10px;
    align-self: center;
}

.video-post_header-image > input {
    border: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #36434B;
    background: #F5F5F5;
    border-radius: 6px;
    margin-bottom: 20px;
}

.video-post_header-image > textarea {
    border: none;
    outline: none;
    width: 100%;
    height: 96px;
    resize: none;
    background: #F5F5F5;
    border-radius: 6px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #36434B;
    box-sizing: border-box;
    padding: 12px 7px 12px 15px;
    margin-bottom: 25px;
}

.video-post_header-image_buttons {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px
}

.video-post_header-subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #C9CACB;
    margin-top: 20px;
    margin-bottom: 30px;
    cursor: default;
}

.video-post_header-subtitle > span {
    color: #FEA65A;
    text-decoration: underline;
}

.video-post_header-subtitle.disabled {
    display: none;
}

.video-post_header-image_middle {
    margin-top: 18px;
}
