.all-replies {
  font-size: 14px;
  font-family: 'Roboto';
  color: #777777;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  transition: all ease 0.3s;
  cursor: pointer;
  width: max-content;
  margin-left: 12px;
  margin-bottom: 12px;
}

.all-replies:hover {
  border-color: #777777;
}
