.second-modal {
  /* border-radius: 10px; */
  outline: none;
  border: none;
  background-color: #fff;
  /* width: 750px; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.second-modal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.second-modal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.second-modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 28px 16px;
}

.second-modal_content-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.second-modal_content-header > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}

.second-modal_content-header > img {
  cursor: pointer;
  transition-duration: 0.4s;
}

.second-modal_content-header > img:hover {
  opacity: 0.6;
}

.second-modal_content-go-back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition-duration: 0.4s;
  margin-bottom: 13px;
}

.second-modal_content-go-back:hover {
  opacity: 0.6;
}

.second-modal_content-go-back > span {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  margin-left: 11px;
}

.second-modal_content-cropped-image {
  width: 100%;
  margin-bottom: 52px;
  position: relative;
}

.second-modal_content-cropped-image > img {
  border-radius: 10px;
  width: 100%;
}

.second-modal_content-subheader {
  margin-bottom: 76px;
}

.second-modal_content-subheader > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #999999;
}

.second-modal_content-cropped-image_form-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-modal_content-cropped-image_form-form > input {
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  background-color: #36434b;
  height: 20px;
}

/* .second-modal_content-cropped-image_form-form > button {
    display: none;
} */

.second-modal_content-cropped-image_form-form_button-submit {
  margin: 0px !important;
  margin-top: 5px !important;
  padding: 0px !important;
  min-width: 0px !important;
  width: 100% !important;
  min-height: 0px !important;
  height: 20px !important;
  text-transform: none !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
  background-color: #fea65a !important;
  color: #fff !important;
}

@media (max-width: 800px) {
  .second-modal {
    border-radius: 0px;
    outline: none;
    border: none;
    background-color: #fff;
    width: 100%;
  }
}
