.claim-page {
  background-color: #f2f5f8;
  padding: 32px;
  position: relative;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(39, 47, 52, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
}

.container {
  background-color: #f2f5f8;
  width: 60%;
  margin: 0 auto;
}

.wrapper {
  background-color: #fff;
  padding: 42px;
  border-radius: 10px;
}

.heading {
  font-family: 'Roboto' Condensed;
  font-size: 31px;
  font-weight: 500;
  line-height: 37px;
  color: #19262d;
  margin-bottom: 14px;
}

.sub-heading {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #404d56;
  margin-bottom: 32px;
}

.send-btn {
  border: none;
  background: #404d56;
  border-radius: 10px;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  padding: 16px 18px;
  cursor: pointer;
}

.send-btn:disabled {
  cursor: not-allowed;
  background: #fff;
  color: #404d56;
  border: 1px solid #404d56;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1000px) {
  .container {
    width: 100%;
  }
}
