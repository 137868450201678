.reactions {
  position: absolute;
  top: -40px;
  left: 0px;
  display: none;
}

.reaction,
.inspired-reaction,
.enlightened-reaction,
.reflected-reaction {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  padding: 6px;
  background: transparent;
  transition: 0.3s ease;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  justify-content: center;
  text-transform: capitalize;
}

.reaction {
  color: hsl(0deg 0% 50%);
}

.inspired-reaction {
  color: hsl(27.8deg 98.8% 50.45%);
}

.enlightened-reaction {
  color: hsl(194.74deg 60% 50%);
}

.reflected-reaction {
  color: hsl(120deg 60% 50%);
}

.reaction:hover .reactions {
  display: block;
}

.inspired-reaction:hover .reactions,
.enlightened-reaction:hover .reactions,
.reflected-reaction:hover .reactions {
  display: block;
}

.reaction {
  &:hover {
    color: #36434b;
    background: #eee;

    .inspired,
    .reflected,
    .enlightened {
      background-color: #36434b;
    }
  }
}

.inspired-reaction:hover,
.enlightened-reaction:hover,
.reflected-reaction:hover {
  background: #eee;
}

.inspired,
.reflected,
.enlightened,
.inspired-active,
.reflected-active,
.enlightened-active {
  cursor: pointer;
  height: 10px;
  padding: 6px;
  width: 10px;
  border-radius: 50%;
  transition: 0.3s ease;
}

.inspired,
.reflected,
.enlightened {
  background-color: #9f9f9f;
}

.inspired-active {
  background: hsl(27.8deg 98.8% 50.45%);
}

.enlightened-active {
  background: hsl(194.74deg 60% 50%);
}

.reflected-active {
  background: hsl(120deg 60% 50%);
}

@media screen and (min-width: 821px) {
  .reaction,
  .inspired-reaction,
  .enlightened-reaction,
  .reflected-reaction {
    padding: 8px;
  }
}
