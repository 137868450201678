.homepage-findgrave_fifth {
    width: 100%;
    margin-bottom: 110px;
}

.homepage-findgrave_fifth-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 0px 70px;
}

.homepage-findgrave_fifth-content_empty {
    min-width: 163px;
    min-height: 126px;
}

.homepage-findgrave_fifth-content_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 7%;
}

.homepage-findgrave_fifth-content_header-main {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #36434B;
    margin-bottom: 24px;
}

.homepage-findgrave_fifth-content_header-main > span {
    color: #FEA65A;
}

.homepage-findgrave_fifth-content_header-submain {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 40px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #36434B;
    opacity: 0.8;
}

.homepage-findgrave_fifth-content_header-button {
    width: 219px !important;
    height: 55px !important;
    background: #36434B !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161) !important;
    border-radius: 10px !important;
    align-self: center !important;
    text-transform: none !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #FFFFFF !important;
}

.homepage-findgrave_fifth-content_header-images {
    position: relative;
    width: 125%;
}

.homepage-findgrave_fifth-content_header-images_image {
    position: absolute;
    left: -111px;
    top: -45px;
    width: 100%;
}

.homepage-findgrave_fifth-content_header-images_blur {
    width: 100%;
}

.homepage-findgrave_fifth-content_header-images_grey {
    min-width: 166px;
    min-height: 166px;
    background: #F5F5F5;
    position: absolute;
    right: 21px;
    top: -128px;
    z-index: 1;
}

.homepage-findgrave_fifth-content_header-images_orange {
    min-width: 166px;
    min-height: 166px;
    background: #FEA65A;
    position: absolute;
    left: -194px;
    bottom: 5%;
    z-index: 1;
}


@media (max-width: 930px) {
    .homepage-findgrave_fifth {
        margin-bottom: 70px;
    }

    .homepage-findgrave_fifth-content {
        flex-direction: column-reverse;
        padding: 0;
    }

    .homepage-findgrave_fifth-content_header {
        box-sizing: border-box;
        padding: 0px 16px;
    }

    .homepage-findgrave_fifth-content_header-images_blur, .homepage-findgrave_fifth-content_header-images_image {
        max-width: 450px;
    }

    .homepage-findgrave_fifth-content_header-images_blur {
        position: absolute;
        bottom: -54px;
        right: -80px;
    }

    .homepage-findgrave_fifth-content_header-images_image {
        left: auto;
        position: relative;
        top: auto;
        bottom: auto;
    }

    .homepage-findgrave_fifth-content_header-images {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    .homepage-findgrave_fifth-content_header-images_grey {
        min-width: 0;
        min-height: 0;
        width: 84px;
        height: 84px;
        top: -43px;
        right: -43px;
    }

    .homepage-findgrave_fifth-content_header-images_orange {
        min-width: 0;
        min-height: 0;
        width: 84px;
        height: 84px;
        bottom: -43px;
        left: -43px;
    }
}

@media (max-width: 930px) {
    .homepage-findgrave_fifth-content_header-images_blur {
        right: 0;
    }

    .homepage-findgrave_fifth-content_header-images_grey {
        right: 0;
    }
}