.menu-item-block {
    width: 100%;
    box-sizing: border-box;
    border-bottom: 0.5px solid #C9CACB;
    max-height: 77px;
}

.menu-item-block.last {
    border-bottom: none;
}

.menu-item-block_content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 19px 20px 19px;
}

.menu-item-block_content > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #36434B !important;
    opacity: 0.5;
    margin-left: 31px;
}

.menu-item-block_content-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 9px;
}

.menu-item-block_content-row.last {
    margin-bottom: 0px;
}

.menu-item-block_content-row > img {
    margin-right: 15px;
}

.menu-item-block_content-row > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #36434B;
}

.menu-item-block:hover span  {
    color: #fea65a;
}