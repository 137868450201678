.memories-section {
  /* background: #f5f5f5; */
  /* padding-bottom: 77px; */
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}

.family-image {
  background-image: url(../../../../img/Family.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 500px;
}

.memories-section > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #36434b;
  margin-bottom: 32px;
  /* margin-top: 84px; */
}

.memories-section > img {
  max-width: 100vw;
}

.memories-section_text-area {
  width: 100%;
  min-height: 100px;
  color: #9aa8b2;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  padding: 15px;
  outline: none;
  resize: none;
  overflow-y: auto;
  height: unset;
  border-radius: 6px;
  border: 1px solid #9aa8b2;
  background: #fff;
}

.memories-section_comments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.memories-section_comments > div {
  margin-bottom: 72px;
}

.memory-text-area-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.new-memory-section {
  background: #ffffff;
  border-radius: 9px;
  padding: 26px 24px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
  margin: 32px 0px;
  position: relative;
}

.upgrade-memory_plan-prompt_body_wrapper {
  display: block;
}

.upgrade-memory_plan-prompt_body_subheading {
  font-family: Roboto Condensed;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #19262d;
  margin-bottom: 40px;
  text-align: center;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(39, 47, 52, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
}

.loader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.loader > span {
  color: #fea65a;
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 500;
  text-transform: capitalize;
}

@media screen and (max-width: 800px) {
  .memories-section {
    padding-top: 20px;
  }
}

@media screen and (max-width: 750px) {
  .memories-section_text-area {
    width: 90% !important;
  }

  .family-image {
    height: 350px;
  }

  .memories-section_comments > div:first-child {
    margin-top: 50px;
  }

  .memories-section_comments > div {
    margin-bottom: 20px;
  }

  .new-memory-section {
    /* margin: auto; */
    /* width: 85%; */
    background: #fff;
    border-radius: 9px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
  }
}

@media (min-width: 750px) and (max-width: 850px) {
  .memories-section_text-area {
    margin: auto;
    width: 89% !important;
  }

  .family-image {
    height: 350px;
  }

  .new-memory-section {
    /* margin: 40px auto;
    width: 60%; */
    background: #fff;
    border-radius: 9px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
  }
}
