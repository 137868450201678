.loading {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
  height: max-content;
  overflow-y: auto;
}

.empty-comments {
  color: #080809;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Roboto';
  text-align: center;
  height: 100px;
}
