.auth-modal {
  width: 500px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative; /* Add position relative to .auth-modal */
}

.auth-modal_cancel {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 13px;
}

.auth-modal_body_subheading {
  font-family: 'Roboto Condensed';
  font-size: 23px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #19262d;
  margin-bottom: 40px;
}

.auth-modal_body_wrapper {
  max-width: 80%;
  margin: auto auto auto 8px;
  text-align: left;
}

.auth-modal_body_content {
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #404d56;
  text-align: left;
}

.auth-modal_title {
  display: flex;
  position: absolute;
  top: 6%;
  left: 5%;
}

.auth-modal_title > span {
  font-family: 'Roboto Condensed';
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  color: #19262d;
}

.auth-modal_body_content > span > a {
  color: #3ca0ff;
  text-decoration: none;
}

.auth-modal_footer {
  display: flex;
  margin-left: auto;
  margin-right: 15px;
}

.auth-modal_footer button {
  margin-left: 10px; /* Add spacing between buttons */
}

@media (max-width: 800px) {
  .auth-modal {
    width: 94%;
  }

  .auth-modal_body_subheading {
    font-size: 21px;
  }
}

.downgrade-plan-prompt_cancel {
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 20px;
}

.downgrade-plan-prompt_body_subheading {
  font-family: 'Roboto' Condensed;
  font-size: 23px;
  font-weight: 500;
  color: #19262d;
  margin-bottom: 32px;
}

.downgrade-plan-prompt_body_content {
  margin-bottom: 32px;
}

.downgrade-plan-prompt_body_content > span {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #404d56;
}

.downgrade-plan-prompt_body_content > ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.downgrade-plan-prompt_body_content > ul > li {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #404d56;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 9px 24px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 8px 24px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

@media (max-width: 800px) {
  .downgrade-plan-prompt_body_subheading {
    font-size: 21px;
  }
}
