.homepage-findgrave_second {
    width: 100%;
    margin-bottom: 80px;
}

.homepage-findgrave_second-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 9%;
}

.homepage-findgrave_second-content_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 56px;
}

.homepage-findgrave_second-content_header-main {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #36434B;
    margin-bottom: 24px;
}

.homepage-findgrave_second-content_header-main > span {
    color: #FEA65A;
}

.homepage-findgrave_second-content_header-submain {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    max-width: 800px;
    letter-spacing: 0.02em;
    color: #36434B;
    opacity: 0.8;
}

.homepage-findgrave_second-content_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.homepage-findgrave_second-content_main-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.homepage-findgrave_second-content_main-row_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30%;
}

.homepage-findgrave_second-content_main-row_block-header_orange {
    width: 40px;
    height: 40px;
    background: #FEA65A;
    opacity: 0.34;
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    left: 0;
}

.homepage-findgrave_second-content_main-row_block-header_grey {
    width: 40px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 5px; 
    position: absolute;
    z-index: -1;  
    left: 0;
}

.homepage-findgrave_second-content_main-row_block-header {
    position: relative;
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
}

.homepage-findgrave_second-content_main-row_block-header > span {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    color: #36434B;
}

.homepage-findgrave_second-content_main-row_block > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #36434B;
    opacity: 0.8;
}

@media (max-width: 800px) {
    .homepage-findgrave_second {
        margin-bottom: 90px;
    }

    .homepage-findgrave_second-content_header-main {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .homepage-findgrave_second-content_header-submain {
        max-width: 91.5%;
        font-size: 14px;
        line-height: 18px;
    }

    .homepage-findgrave_second-content {
        padding: 0;
    }

    .homepage-findgrave_second-content_header {
        margin-bottom: 74px;
    }

    .homepage-findgrave_second-content_main-row {
        flex-direction: column;
        align-items: center;
    }

    .homepage-findgrave_second-content_main-row_block {
        width: 91.5%!important;
        max-width: none!important;
        margin-bottom: 40px;
    }

    .homepage-findgrave_second-content_main-row:first-child {
        margin-bottom: 0px;
    }

    .homepage-findgrave_second-content_main-row.last > div:last-child {
        margin-bottom: 0px;
    }
}