.grave-passion {
  margin-top: 12px;
}

.passion {
  border-radius: 5px;
  background: #f6f6f6;
  padding: 24px;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
}

.empty-grave-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(300px - 24px);
}

.empty-message {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.add-btn {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
}

@media screen and (min-width: 1020px) {
  .grave-passion {
    margin-top: 0;
  }
}
