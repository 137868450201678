.grave-connections-header {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
  align-items: baseline;
}

.grave-connections-header > img {
  cursor: pointer;
}

.grave-connections-header > span {
  font-family: 'Minion Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #36434b;
  word-break: break-word;
}
