.automatic-renew {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.automatic-renew-heading {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #2b2a30;
  margin-bottom: 16px;
}

.automatic-renew-desc {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 153.2%;
  color: #404d56;
}

.terms-condition {
  margin-bottom: 16px;
}

.terms-condition > span {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 153.19%;
  color: #404d56;
}

.terms-condition-link {
  font-weight: 500;
  color: #404d56;
  text-decoration: none;
  margin-left: 2px;
}
