.close-btn {
  position: absolute;
  top: 32px;
  right: 32px;
  background: none;
  border: none;
  cursor: pointer;
}

.heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 8px;
}

.discard-message {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 70px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.side-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.discard-btn {
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  padding: 16px 40px;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  cursor: pointer;
}

.no-btn {
  border-radius: 8px;
  background: #404d56;
  padding: 16px 60px;
  color: #fff;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  cursor: pointer;
}
