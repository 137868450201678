.municipality-header {
  display: flex;
  min-height: 150px;
}

.municipality-header_left {
  float: left;
  width: 60%;
  align-self: center;
}

.municipality-header_title > span {
  font-family: 'Minion Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #36434b;
  letter-spacing: 2px;
}

.municipality-header_title {
  margin-bottom: 15px;
}

.municipality-header_info {
  margin: 10px 0px;
}

.municipality-header_info > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #9f9f9f;
}

.header-submain {
  font-family: Minion Pro;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #36434b;
  opacity: 0.8;
  margin-bottom: 40px;
  letter-spacing: 0.02em;
}

.header-submain > a {
  text-decoration: none;
  color: #fea65a;
}

.header-submain_wrapper {
  padding: 35px 50px;
}

.municipality-header_right {
  float: right;
  width: 35%;
  align-self: center;
  margin: auto;
}

@media (max-width: 800px) {
  .municipality-header {
    display: block;
    min-height: 150px;
  }

  .municipality-header_left {
    float: none;
    width: auto;
  }

  .municipality-header_right {
    float: none;
    width: auto;
  }
}
