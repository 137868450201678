.card-payment {
  margin-bottom: 24px;
}

.card-input-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
  width: 100%;
}

.card-input-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  color: #36434b;
}

.card-inputs {
  border: none;
  outline: none;
  background: #f2f5f8;
  border-radius: 8px;
  padding: 21px 27px;
}

.card-inputs::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #777777;
}

.card-details-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-input-cvc-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
  width: 100%;
  margin-left: 12px;
}

.payment-security {
  display: flex;
  align-items: center;
  gap: 12px;
}

.payment-security > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #404d56;
}

.billing-info-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #404d56;
  margin-bottom: 24px;
  margin-top: 8px;
}

.billing-details,
.billing-details-container,
.billing-details-container-email,
.billing-details-container-phoneNumber {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
  width: 100%;
}

.billing-details > span,
.billing-details-container > span,
.email-heading,
.phoneNumber-heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  color: #36434b;
}

.billing-details > input,
.billing-details-container > input,
.custom-phone-input {
  outline: none;
  border: none;
  background: #f2f5f8;
  border-radius: 8px;
  font-size: 18px;
  padding: 21px 27px;
  color: #777777;
}

.custom-phone-input > input,
.custom-phone-input-error > input {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #777777;
  border: none;
  background: none;
  outline: none;
}

.email {
  outline: none;
  border: none;
  background: #f2f5f8;
  border-radius: 8px;
  font-size: 18px;
  padding: 21px 27px;
  color: #777777;
}

.email::placeholder,
.error-email::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #777777;
}

.error-email,
.custom-phone-input-error {
  outline: none;
  border: 1px solid red;
  background: #f2f5f8;
  border-radius: 8px;
  font-size: 18px;
  padding: 21px 27px;
  color: #777777;
}

.billing-details > input::placeholder,
.billing-details-container > input::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #777777;
}

.country-list {
  outline: none;
  border: none;
  background: #f2f5f8;
  border-radius: 8px;
  padding: 21px 27px;
  font-size: 18px;
  width: 100%;
  color: #777777;
  max-height: 270px;
}

.user-message > span {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #404d56;
}

@media (min-width: 1200px) {
  .billing-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .billing-details-container,
  .billing-details-container-email,
  .billing-details-container-phoneNumber {
    width: 49%;
  }
}
