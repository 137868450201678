.homepage-investigate_fifth {
    width: 100%;
    margin-bottom: 168px;
}

.homepage-investigate_fifth-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.homepage-investigate_fifth-content_block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
}

.homepage-investigate_fifth-content_block-empty {
    min-width: 63px;
}

.homepage-investigate_fifth-content_block-image {
    width: 100%;
}

.homepage-investigate_fifth-content_block-image > img:first-child {
    width: 100%;
}

.homepage-investigate_fifth-content_block-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.homepage-investigate_fifth-content_block-text_header {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #36434B;
    margin-bottom: 32px;
}

.homepage-investigate_fifth-content_block-text_header > span {
    color: #FEA65A;
}

.homepage-investigate_fifth-content_block-text_subheader {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #4F4F4F;
    margin-bottom: 39px;
    width: 350px;
}

.homepage-investigate_fifth-content_block-text_grey {
    min-width: 149px;
    min-height: 149px;
    background: #F5F5F5;
    opacity: 0.6;
    border-radius: 10px;
    align-self: flex-start;
    margin-left: 76px;
}

.homepage-investigate_fifth-content_block-grey {
    position: absolute;
    width: 371px;
    height: 408px;
    background: #F5F5F5;
    opacity: 0.6;
    border-radius: 20px 0px 0px 20px;
    top: -35%;
    right: 0;
    z-index: -1;
}

.homepage-investigate_fifth-content_block.first {
    margin-bottom: 243px;
}

.homepage-investigate_fifth-content_block.second {
    flex-direction: row-reverse;
}

.homepage-investigate_fifth-content_block-orange {
    position: absolute;
    width: 383px;
    height: 407px;
    background: #FFE4CE;
    border-radius: 0px 20px 20px 0px;
    top: -47%;
    left: 0;
    z-index: -1;
}

.homepage-investigate_fifth-content_block-text_orange {
    min-width: 149px;
    min-height: 149px;
    background: #FFE4CE;
    border-radius: 10px;
    align-self: flex-end;
    margin-right: 76px;
}

.homepage-investigate_fifth-content_block-text_container {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
}

.homepage-investigate_fifth-content_block-image_mobile {
    display: none;
}

@media (max-width: 800px) {
    .homepage-investigate_fifth-content_block-image_mobile {
        display: block;
        width: 100%;
    }

    .homepage-investigate_fifth {
        margin-bottom: 0px;
        margin-top: -10px;
    }

    .homepage-investigate_fifth-content_block.first {
        flex-direction: column;
    }

    .homepage-investigate_fifth-content_block-image > img:first-child, .homepage-investigate_fifth-content_block-empty {
        display: none;
    }

    .homepage-investigate_fifth-content_block-image {
        margin-bottom: 66px;
    }

    .homepage-investigate_fifth-content_block-text_header {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 42px;
    }

    .homepage-investigate_fifth-content_block-text {
        box-sizing: border-box;
        padding: 0px 16px;
    }

    .homepage-investigate_fifth-content_block-text_subheader {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 46px;
    }

    .homepage-investigate_fifth-content_block-text_grey {
        align-self: center;
        min-height: 50px;
        border-radius: 10px 10px 0px 0px;
        margin-left: 0px;
    }

    .homepage-investigate_fifth-content_block-grey  {
        width: 143px;
        top: auto;
        bottom: 4%;
        right: 0;
    }

    .homepage-investigate_fifth-content_block.second {
        display: flex;
        flex-direction: column;
    }

    .homepage-investigate_fifth-content_block-orange {
        width: 143px;
        top: auto;
        bottom: 14%;
        left: 0;
    }

    .homepage-investigate_fifth-content_block-image.second {
        display: block !important;
    }

    .homepage-investigate_fifth-content_block.first {
        margin-bottom: -5px;
    }

    .homepage-investigate_fifth-content_block-text_orange {
        align-self: center;
        margin-right: 0px;
    }
}