.new-grave-heading {
  font-family: 'Roboto' Condensed;
  font-size: 31px;
  font-weight: 500;
  line-height: 37px;
  text-align: center;
  color: #19262d;
  margin-bottom: 32px;
  width: 100%;
}

.add-stepper {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .new-grave-heading {
    text-align: start;
    margin-left: 24px;
  }
}
