.event-content_header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.event-content_header > span {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}

.event-content_header > img {
  cursor: pointer;
  transition-duration: 0.3s;
}

.event-content_header > img:hover {
  opacity: 0.6;
}

.event-content_subheader {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}

.event-content_subheader > span {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.upload-container {
  position: relative;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(39, 47, 52, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
}

.loader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.loader > span {
  color: #fea65a;
  font-size: 20px;
  font-family: 'Roboto';
  font-weight: 500;
  text-transform: capitalize;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #fea65a;
  background: #fff;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}
