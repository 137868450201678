.reset-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 820px) {
  .reset-page {
    min-height: 750px;
  }
}
