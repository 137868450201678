.message {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
}

.deceased-content_suggestions-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
}

.deceased-content_suggestions-text_block {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.deceased-content_suggestions-text_block-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 20px;
}

.deceased-content_suggestions-text_block-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 30px;
  margin-bottom: 30px;
}

.deceased-content_suggestions-text_block-text > div {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #36434b;
  text-align: center;
  max-width: 321px;
  word-wrap: break-word;
  padding: 10px 0;
}

.deceased-content_suggestions-text_block-text > img:first-child {
  align-self: flex-start;
}

.deceased-content_suggestions-text_block-text > img:last-child {
  align-self: flex-end;
}

.deceased-content_suggestions-text_block-author {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

.deceased-content_suggestions-text_block-author > a {
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fea65a;
}

.deceased-content_suggestions-text_block-author-family {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.deceased-content_suggestions-text_block-author-family > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #777777;
}

@media screen and (max-width: 520px) {
  .deceased-content_suggestions-text_block {
    width: 150px;
  }
}
