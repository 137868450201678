.settings-personal-information-edit_content-headers {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-inputs-title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
}

.btn-container {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.settings-personal-information-edit_content-headers_header {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  margin-bottom: 20px;
}

.settings-personal-information-edit_content-headers_subheader,
.settings-personal-information-edit_content-headers_inputs-column_label {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  color: #999999;
}

.settings-personal-information-edit_content-headers_inputs {
  margin-bottom: 38px;
}

.settings-personal-information-edit_content-headers_inputs-column {
  display: flex;
  flex-direction: column;
}

.settings-personal-information-edit_content-headers_inputs-column_label {
  margin-bottom: 10px;
}

.settings-input-text {
  margin-bottom: 20px;
  border-radius: 8px;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
  background-color: #f5f5f5;
  outline: none;
  border: none;
  padding: 20px;
}

.settings-input-text-email {
  border-radius: 8px;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
  background-color: #f5f5f5;
  outline: none;
  border: none;
  padding: 20px;
}

.settings-input-text input {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
  background-color: #f5f5f5;
  outline: none;
  border: none;
}

.settings-inputs-gender {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.settings-inputs-gender-male,
.settings-inputs-gender-female,
.settings-inputs-gender-others {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #777777;
  padding: 20px 40px;
  border: none;
  background: #f5f5f5;
  border-radius: 8px;
  cursor: pointer;
}

.settings-inputs-gender-male_active,
.settings-inputs-gender-female_active,
.settings-inputs-gender-others_active {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  padding: 20px 40px;
  border: none;
  background: #fea65a;
  border-radius: 8px;
  cursor: pointer;
}

.settings_buttons-cancel {
  font-family: 'Minion Pro';
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 40px;
  width: 150px;
  color: #fea65a;
  border: 1px solid #fea65a;
  border-radius: 10px;
  transition-duration: 0.4s;
  margin-right: 30px;
  background: none;
  cursor: pointer;
}

.settings_buttons-save {
  font-family: 'Minion Pro';
  font-size: 20px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #fea65a;
  border-radius: 10px;
  height: 40px;
  width: 150px;
  transition-duration: 0.4s;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.settings_buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.settings_buttons button {
  cursor: pointer;
}

.error-message {
  margin: 0;
  margin-top: 0px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "'Roboto'", 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: #f44336;
  margin-bottom: 20px;
}

@media (max-width: 750px) {
  .settings-personal-information-edit_content-headers_inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .settings-personal-information-edit_content-headers_header {
    font-size: 20px;
    line-height: 23px;
  }

  .settings-input-text {
    font-size: 12px;
    line-height: 14px;
  }

  .settings_buttons-save,
  .settings_buttons-cancel {
    font-size: 16px;
    line-height: 15px;
  }

  .settings-inputs-gender {
    width: 60%;
    gap: 10px;
  }

  .settings-inputs-gender-male,
  .settings-inputs-gender-female,
  .settings-inputs-gender-others {
    font-size: 12px;
    line-height: 14px;
    padding: 15px 30px;
  }
  .settings-inputs-gender-male_active,
  .settings-inputs-gender-female_active,
  .settings-inputs-gender-others_active {
    font-size: 12px;
    line-height: 14px;
    padding: 15px 30px;
  }
}

/* Material UI */

.MuiInput-underline::before,
.MuiInput-underline::after {
  border-bottom: none !important;
}

.MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiInput-input:focus {
  background: #f5f5f5;
}

/* Material UI */
