.vipps-agreement-page {
  background: #f2f5f8;
  height: 100vh;
}

.vippa-page-wrapper {
  width: 60%;
  margin: 0 auto;
  background: #fff;
  padding: 32px;
}

.vipps-heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 42px;
}

.vipps-heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-bottom: 42px;
}

.message {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
}

@media screen and (max-width: 750px) {
  .vippa-page-wrapper {
    width: auto;
    margin: 0;
  }
}
