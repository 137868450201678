.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 6px;
  margin-bottom: 4px;
}

.footer-container {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 4px;
  margin-bottom: 12px;
}

.group-button {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
}

.comment {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  padding: 6px;
  color: hsl(0deg 0% 50%);
  background: transparent;
  transition: 0.3s ease;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  justify-content: center;
  text-transform: capitalize;
}

.comment:hover .reactions {
  display: block;
}

.comment:hover {
  color: #36434b;
  background: #eee;
}

@media screen and (min-width: 821px) {
  .comment {
    padding: 8px;
  }
}
