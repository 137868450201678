.grave-wall {
  flex: 1;
}

.grave-wall-box-container {
  margin-bottom: 12px;
  background: #fff;
  border-radius: 10px;
  padding: 24px;
}

.grave-notes {
  margin-bottom: 24px;
}

.grave-wall-container-mobile {
  background: #fff;
  padding: 24px;
  margin-bottom: 12px;
}

.grave-wall-heading {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
}

.grave-wall-heading-mobile {
  color: #985000;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
}

.grave-wall-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.edit-virtue-modal_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grave-wall-anniversary {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 750px) {
  .grave-wall-box-container {
    width: 100%;
    border-radius: none;
  }
}
