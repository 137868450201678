.first-step {
  width: 622px;
  /* height: 401px; */
  padding: 50px;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
}

.first-step-button {
  border: none;
  background-color: #404d56;
  padding: 16px 42px;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  cursor: pointer;
  margin-top: 32px;
  border-radius: 10px;
}

.first-step-name > label > span,
.first-step-form-control > label > span,
.first-step-file-input > span > span {
  color: #fea65a;
}

.first-step-file {
  display: none;
}

.first-step-name {
  margin-bottom: 20px !important;
  width: 100%;
}

.first-step-name > div {
  height: 40px;
}

.first-step-name > label,
.first-step-form-control > label {
  font-size: 14px;
  line-height: 16px;
  color: #9f9f9f;
  font-family: Roboto;
}

.first-step-name > div > input,
.first-step-form-control > div > div {
  padding-top: 20px;
  font-size: 14px;
  line-height: 16px;
  font-family: Roboto;
}

.first-step-name > div > input {
  padding: 18.5px 12px 18.5px;
}

.first-step-name > label,
.first-step-form-control > label {
  transform: translate(12px, 12px) scale(1);
}

.first-step-name > .MuiInputLabel-filled.MuiInputLabel-shrink,
.first-step-form-control > .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 0px) scale(0.75) !important;
}

.first-step-form-control {
  width: 100%;
  height: 40px;
  margin-bottom: 20px !important;
  text-align: center !important;
}

.first-step-file-lable {
  width: 100%;
}

.first-step-file-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background: #f5f5f5;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #9f9f9f;
}

.first-step-form-control_select-gender {
  height: 40px !important;
  border-radius: 10px !important;
}

.first-step-form-control_select-gender > div {
  height: inherit !important;
  background-color: inherit !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: center !important;
  padding-left: 10px !important;
}

.first-step-form-control_select-gender > div:focus {
  background-color: inherit !important;
  height: inherit !important;
  border-radius: 10px !important;
}

.add-grave_autocomplete {
  width: 100% !important;
  height: 40px !important;
  background: #f5f5f5 !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  /* margin-bottom: 20px !important; */
}

.add-grave_autocomplete-input {
  width: 100% !important;
  height: 100% !important;
}

.add-grave_autocomplete-input > div > input {
  width: 100% !important;
  height: 100% !important;
  padding-left: 10px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #36434b !important;
}

.add-grave_autocomplete-input > div > input::placeholder {
  color: black !important;
}

.gender-container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  margin-bottom: 38px;
}

.gender-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.select-gender {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  background: #f5f5f5;
  padding: 14px 24px;
  border-radius: 8px;
  cursor: pointer;
}

.select-gender-active {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  background: #e4f3fd;
  padding: 14px 24px;
  border-radius: 8px;
  cursor: pointer;
}
.select-gender-active > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #404d56;
  width: 16px;
  height: 16px;
  border: 0.15em solid #404d56;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.select-gender-active > input[type='checkbox']:checked {
  border: 0.15em solid #404d56;
}

.select-gender-active > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #404d56;
}

.select-gender-active > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.select-gender-active > label {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
  cursor: pointer;
}

.select-gender > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #404d56;
  width: 16px;
  height: 16px;
  border: 0.15em solid #404d56;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.select-gender > input[type='checkbox']:checked {
  border: 0.15em solid #404d56;
}

.select-gender > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #404d56;
}

.select-gender > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.select-gender > label {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
  cursor: pointer;
}
/* jjjjjjjj */
.select-file-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 12px;
}

.select-file {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  background: #fff;
  border: 1px solid #1a1a1a1a;
  padding: 14px 24px;
  border-radius: 8px;
  cursor: pointer;
}

.select-file-active {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  border: 1px solid #fea65a;
  background: #fff;
  padding: 14px 24px;
  border-radius: 8px;
  cursor: pointer;
}
.select-file-active > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #fea65a;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.select-file-active > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.select-file-active > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.select-file-active > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.select-file-active > label {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #fea65a;
  cursor: pointer;
}

.select-file > input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  color: #9aa8b2;
  width: 16px;
  height: 16px;
  border: 0.15em solid #9aa8b2;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.select-file > input[type='checkbox']:checked {
  border: 0.15em solid #9aa8b2;
}

.select-file > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #9aa8b2;
}

.select-file > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.select-file > label {
  font-family: 'Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #9aa8b2;
  cursor: pointer;
}

.input-element-list {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  margin-bottom: 38px;
}

.title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
}

.inputs {
  border-radius: 8px;
  background: #f5f5f5;
  padding: 14px 28px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  outline: none;
  border: 1px solid transparent;
}

.inputs:focus {
  border: 1px solid;
}
.choose-relation-selection {
  border-radius: 8px;
  background: #f5f5f5;
  padding: 12px;
}

.next-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sub-heading {
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #9aa8b2;
}

@media screen and (max-width: 750px) {
  /* .select-file-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  } */
  .first-step {
    width: 100vw;
    padding: 32px 24px;
  }

  /* .first-step-button {
    width: 166px;
    height: 50px;
  } */
}
