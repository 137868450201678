.container {
  width: 100%;
  background-color: #19262d;
}

.side-container {
  width: 80%;
  margin: 100px auto;
  background-color: #19262d;
}

.heading {
  font-family: 'Roboto' Condensed;
  font-size: 44px;
  font-weight: 700;
  line-height: 51.56px;
  color: #f5f5f5;
  margin-bottom: 70px;
  text-align: center;
}

.description {
  font-family: 'Roboto';
  font-size: 27px;
  font-weight: 400;
  line-height: 37.72px;
  text-align: center;
  color: #f5f5f5;
  margin-bottom: 42px;
}

@media screen and (max-width: 1000px) {
  .container {
    display: none;
  }
}
