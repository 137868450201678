.container {
  height: 200px;
  width: 100%;
  position: relative;
}

.main-container {
  position: relative;
}

.sub-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
}

.color-picker {
  width: auto !important;
  padding: 10px !important;
  margin-bottom: 24px;
}

.set-color {
  position: absolute;
  right: 16px;
  bottom: 20px;
}

.first-name {
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
}

.coin-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.coin-container > img {
  height: 27px;
}

.coin {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
}

.btn {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 821px) {
  .container {
    height: 350px;
  }
  .width-container {
    max-width: 1000px;
    margin: 0px auto;
  }

  .padding-container {
    margin-inline: 16px;
  }

  .first-name {
    font-size: 24px;
  }
  .sub-container {
    padding: 30px 60px;
  }
}
/* @media screen and (min-width: 1000px) {
  .width-container {
    max-width: 60%;
  }
} */
