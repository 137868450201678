.container {
  margin-bottom: 24px;
}
.period-btn {
  cursor: pointer;
  padding: 8px 16px;
  border: 1px solid #404d56;
  background: #ffff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  transition: ease 0.3s;
  display: flex;
  align-items: center;
  gap: 4px;
}

.period-btn.active-btn {
  background-color: #404d56;
  color: #fff;
  font-weight: 500;
}

.save-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
}

.save {
  cursor: pointer;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  transition: ease 0.3s;
  margin-bottom: 8px;
}

.save:hover {
  background-color: #394147;
}

.group-button {
  display: inline-flex !important;
  flex-wrap: wrap !important;
}
