.loged-in {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 15;
  cursor: pointer;
}

.menu-settings {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.161) !important;
}

.menu-settings_text {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
  gap: 4px;
}

.menu-settings_text-header {
  font-family: 'Roboto' !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #36434b !important;
}

.menu-settings_text-subheader {
  font-family: 'Roboto' !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #c9cacb !important;
}

.header-section_menu-mobile {
  display: none !important;
}

.arrow-up {
  transform: rotate(180deg);
}

.MuiPopover-paper {
  padding: 12px;
}

.show-on-mobile {
  display: none;
}

.hide-on-mobile {
  display: inline;
}
@media screen and (max-width: 750px) {
  .loged-in {
    justify-content: space-around;
    margin-left: 10px;
  }
  .mobile_hide {
    display: none !important;
  }
}

@media screen and (max-width: 1045px) {
  .header-section_menu-mobile {
    display: flex !important;
  }
  .header-section_menu-mobile a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-section_menu-mobile img {
    width: 60%;
    height: 60%;
  }
}

@media screen and (max-width: 500px) {
  .show-on-mobile {
    display: inline;
  }
  .hide-on-mobile {
    display: none;
  }
}
