.heading-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.heading {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}
.message {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 139.688%;
  margin-bottom: 30px;
}

.footer {
  display: flex;
  align-self: center;
  gap: 32px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #f23131;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.activate {
  background-color: #404d56;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.footer-container {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 480px) {
  .footer-container {
    align-items: unset;
    justify-content: unset;
  }
  .footer {
    flex-direction: column;
    width: 100%;
  }
}
