.deceased-header-container {
  border-radius: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  margin-bottom: 24px;
  gap: 24px;
  flex-wrap: wrap;
}
.header-deceased-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.relations-title {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
}

.about-container {
  display: flex;
  gap: 42px;
  min-height: 500px;
}

.heading-container {
  display: flex;
  align-items: center;
  gap: 32px;
}

.heading {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 38px;
  font-weight: 500;
  hyphens: auto;
  -webkit-hyphens: auto; /* For Safari */
  -moz-hyphens: auto; /* For Firefox */
  -ms-hyphens: auto; /* For Internet Explorer */
  word-wrap: break-word;
}

.deceased-grave-info {
  display: flex;
  gap: 24px;
}

.death-age {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
}

.date-of-birth,
.date-of-death {
  margin-left: 8px;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.side-border {
  width: 2px;
  background: rgba(0, 0, 0, 0.2);
}

.reason-of-death {
  color: #96a3ad;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
}

.add-invites {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}

.relationships-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.claim-grave-button {
  color: #008905;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 170%;
  border: none;
  border-radius: 10px;
  background: #d8ffe5;
  padding: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.join-grave-button {
  color: #fff;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 170%;
  border: none;
  border-radius: 10px;
  background: #404d56;
  padding: 10px;
  cursor: pointer;
}

.header-right-container-button-wrapper {
  margin-bottom: 15px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.waiting-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.set-relation-green {
  color: #008905;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 170%;
  padding: 10px;
  border-radius: 10px;
  background: #d8ffe5;
  margin-bottom: 12px;
}

.conformation-days {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
}

.grave-avatar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}

@media screen and (max-width: 1020px) {
  .deceased-header-container {
    border-radius: 0;
  }
  .side-border {
    display: none;
  }
  .deceased-grave-info {
    flex-wrap: wrap;
  }
}
