.year-btn,
.month-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 12px;
}

.year-btn > button,
.month-btn > button {
  color: #9aa8b2;
  font-family: 'Roboto' Condensed;
  font-size: 24px;
  font-weight: 400;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.event-wrapper {
  min-height: 400px;
  width: 300px;
}

.event {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
}

.container-year,
.container-month {
  display: flex;
  gap: 60px;
  flex-direction: row;
  margin-bottom: 24px;
  transition: all ease 0.3s;
}

.side-container-year,
.side-container-month {
  display: flex;
  align-items: center;
  margin-left: 26px;
  transition: all ease 0.3s;
}

.line-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}
.line {
  height: 100%;
  width: 2px;
  background-color: #9aa8b2;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #9aa8b2;
}

@media screen and (max-width: 560px) {
  .side-container-year {
    display: none;
  }
  .year-btn {
    width: max-content;
    background: #cccccc;
    padding: 12px;
    border-radius: 50px;
  }
}

@media screen and (max-width: 480px) {
  .container-month {
    gap: 8px;
  }
  .event-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .side-container-month {
    display: none;
  }
}
