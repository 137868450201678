.image-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.delete-icon,
.download-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: 0.3s;
}

.download-icon:hover {
  background-color: #cccc;
}

.grave-image {
  border-radius: 10px;
  object-position: center;
  object-fit: cover;
  height: 200px;
  width: 100%;
  border: 1px solid lightgray;
}

.delete-container {
  position: absolute;
  top: 8px;
  right: 8px;
}

.delete-btn {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

.delete-btn:hover {
  background-color: #cccc;
}

.edit-virtue-modal_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
