.autocomplete-input {
  border-radius: 4px !important;
  background: #f5f5f5;
  border: 1px solid transparent;
  width: 100%;
}

.MuiAutocomplete-endAdornment {
  right: 10px !important;
  top: 10px !important;
  transform: translate(0px, 0px) !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 6px;
}

.autocomplete-input:hover {
  border-color: #36434b !important;
}

.autocomplete-input-error {
  border-color: #c65c00 !important;
}

.autocomplete-input-error:hover {
  border-color: #c65c00 !important;
}
