.add-new-grave {
  width: 100%;
  background: #f2f5f8;
  padding-bottom: 90px;
}

.loading-container {
  background: #f2f5f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 83vh;
}

.heading {
  font-family: 'Roboto' Condensed;
  font-size: 31px;
  font-weight: 500;
  line-height: 37px;
  color: #19262d;
}

.sub-heading-header {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #404d56;
}

.back-button-container {
  padding-left: 68px;
}

.loader {
  width: 120px !important;
  height: 120px !important;
  color: #fea65a !important;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  padding: 30px;
  width: max-content;
}

.header-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 750px) {
  .add-new-grave {
    padding-bottom: 0px;
    background: #fff;
  }

  .back-button-container {
    padding-left: 24px;
    background: #f2f5f8;
  }
}
