.ask-question-box-container {
  min-height: 265px;
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
}

.user-info-container {
  display: flex;
}

.user-info_name {
  margin-left: 15px;
  align-self: center;
}

.user-info_name > span {
  font-family: "Minion Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
  color: #36434b;
}

.user-info_avatar_wrapper {
  margin-left: 15px;
}

.text-area-wrapper {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.ask-question-text-area {
  width: 100%;
  min-height: 100px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #36434b;
  padding: 15px;
  outline: none;
  resize: none;
  overflow: auto;
  height: unset;
  border: 2px solid #e5e5e5;
  border-radius: 7px;
  margin-bottom: 10px;
  background: #f5f5f5;
}

.ask-question-input-group_icons {
  margin: 0px 10px;
  cursor: pointer;
}

.ask-question-input-group {
  display: flex;
  width: 40%;
  max-height: 32px;
}

.ask-question-input-group > {
  justify-content: flex-end;
}

.image-post-wrapper {
  width: 100%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: repeat(100, 1%);
}

.image-post {
  border-radius: 10px;
  width: 10000%;
  margin: auto 0;
}

.bin-icon {
  grid-column-start: 95;
  cursor: pointer;
}

.video-cancel-selection {
  text-align: right;
  margin-right: calc(25% - 30px);
}

.submit-question_button {
  background: #fea65a;
  box-shadow: 0px 1px 6px rgba(254, 166, 90, 0.302);
  border-radius: 10px;
  height: 40px;
  width: 110px;
  margin-left: auto;
  border: none;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.submit-question_button:disabled {
  background: #777777;
  cursor: default;
}

.footer-wrapper {
  display: flex;
}

@media (max-width: 750px) {
  .ask-question-box-container {
    width: 96%;
    padding: 0;
    margin: auto;
  }

  .user-info-container {
    padding: 10px;
  }

  .user-info_name > span {
    font-size: 20px;
  }

  .text-area-wrapper {
    margin: 10px 10px;
  }

  .image-post-wrapper {
    width: 100%;
    margin: 0px auto 30px;
  }

  .bin-icon {
    grid-column-start: 92;
  }

  .submit-question_button {
    margin-bottom: 15px;
    margin-right: 10px;
  }

  .video-cancel-selection {
    margin-right: 5px;
  }
}

@media (min-width: 700px) and (max-width: 950px) {
  .ask-question-box-container {
    width: 70%;
    margin: 0 auto 20px;
  }
}
