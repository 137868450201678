.deceased-page-container {
  width: 100%;
  overflow: auto;
  background: #f2f5f8;
  min-height: calc(100vh - 90px);
  padding-bottom: 12px;
}

.deceased-page {
  width: 90%;
  margin: 0 auto;
  padding-top: 24px;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.deceased-virtue {
  border-radius: 10px;
  border: 1px solid #fff;
  background: #fff;
  margin-bottom: 38px;
  padding: 24px;
}

.menu-tabs-deceased {
  border-radius: 10px;
  background: #fff;
  padding: 24px;
  margin-bottom: 50px;
}

.about-container {
  display: flex;
  gap: 42px;
}

@media screen and (max-width: 1020px) {
  .deceased-page {
    width: 100%;
  }
  .deceased-virtue {
    border-radius: 0;
  }
  .menu-tabs-deceased {
    border-radius: 0px;
    margin-bottom: 24px;
  }
}
