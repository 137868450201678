.grave-picture-gallery_comments {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 0px 0px 10px 10px;
}

.grave-picture-gallery_comments-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0px 28px 0px;
}

.grave-picture-gallery_comments-content_post {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 100px 0px 100px;
  margin-bottom: 35px;
}

.grave-picture-gallery_comments-content_post-avatar {
  width: 44px !important;
  height: 44px !important;
  margin-right: 14px;
}

.grave-picture-gallery_comments-content_post > input {
  width: 100%;
  border-radius: 10px 0px 0px 10px;
  background: #ffffff;
  border: none;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
  box-sizing: border-box;
  padding: 12px 16px;
}

.grave-picture-gallery_comments-content_post-button {
  border-radius: 0px 10px 10px 0px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #36434b !important;
  text-transform: none !important;
  box-sizing: border-box !important;
  padding: 12px 0px 12px 0px !important;
  background-color: #fff !important;
}

.grave-picture-gallery_comments-content_post
  > input:focus
  + .grave-picture-gallery_comments-content_post-button {
  color: #fea65a !important;
}

.grave-picture-gallery_comments-content_hide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.grave-picture-gallery_comments-content_hide-button {
  border: 1px solid #fea65a !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  width: 158px !important;
  height: 37px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #fea65a !important;
  text-transform: none !important;
  background-image: url('../../../../img/SmallPointerUpper.svg') !important;
  background-repeat: no-repeat !important;
  background-position: 92% !important;
}

.grave-picture-gallery_comments-content_comments {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 200px 0px 159px;
}

.grave-picture-gallery_comments-content_comments > div {
  margin-bottom: 30px;
}

.grave-picture-gallery_comments-content_comments > div:last-child {
  margin-bottom: 20px;
}

@media screen and (max-width: 720px) {
  .grave-picture-gallery_comments-content {
    padding: 24px 0px;
  }
  .grave-picture-gallery_comments-content_comments,
  .grave-picture-gallery_comments-content_post {
    padding: 0px 24px;
  }
}
