.container {
  box-shadow: 1px 3px 20px 0px #0000001a;
  background: #ffffff;
  padding: 24px;
  margin-bottom: 24px;
}

.empty-message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 29.3px;
  color: #36434b;
  width: 100%;
  min-height: 200px;
}
