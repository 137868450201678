.footer {
  display: flex;
  align-self: center;
  justify-content: center;
  gap: 32px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  padding: 8px 32px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 8px 32px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.message {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 18px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 24px;
}

.image {
  position: absolute;
  right: 24px;
  top: 24px;
  transition-duration: 0.3s;
}

.workspace-content_header > img:hover {
  opacity: 0.6;
}
