.homepage-investigate_first {
    width: 100%; 
    margin-bottom: 105px;  
}

.homepage-investigate_first-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.homepage-investigate_first-content_text {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 500px;
    left: 0;
    margin-left: 70px;
}

.homepage-investigate_first-content_text-header {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #36434B;
    margin-bottom: 32px;
}

.homepage-investigate_first-content_text-header > span {
    color: #FEA65A;
}

.homepage-investigate_first-content_text-subheader {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #36434B;
    opacity: 0.8;
    margin-bottom: 14px;
}

.homepage-investigate_first-content_text-subheader:last-child {
    margin-bottom: 0px;
}

.homepage-investigate_first-content_image {
    width: 70.2%;
    z-index: -1;
}

.homepage-investigate_first-content_image-mobile {
    display: none;
}

@media (max-width: 1120px) {
    .homepage-investigate_first-content_image {
        width: 65.2%;
    }
}

@media (max-width: 1050px) {
    .homepage-investigate_first-content_image {
        width: 60.2%;
    }

    .homepage-investigate_first-content_text {
        margin-left: 30px;
    }
}

@media (max-width: 930px) {
    .homepage-investigate_first-content_text {
        width: 450px;
    }
}

@media (max-width: 800px) {
    .homepage-investigate_first-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .homepage-investigate_first-content_image {
        width: 100%;
    }

    .homepage-investigate_first-content_text {
        position: relative;
        margin: 0px;
        width: 100%;
        margin-top: 50px;
        box-sizing: border-box;
        padding: 0px 16px;
    }

    .homepage-investigate_first-content_text-header {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
        align-self: center;
    }

    .homepage-investigate_first-content_text-subheader {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 25px;
    }
}

@media (max-width: 680px) {
    .homepage-investigate_first-content_image {
        display: none;
    }

    .homepage-investigate_first-content_image-mobile {
        display: block;
        width: 100%;
    }
}