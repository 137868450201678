.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 36px !important;
  height: 36px !important;
  align-self: flex-start;
}

.input-container {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  border-radius: 10px;
  padding: 12px;
  background: #f5f5f5;
  cursor: pointer;
}

.send-icon {
  align-self: flex-end;
  padding: 6px;
  background: #7dea59;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel {
  font-size: 12px;
  font-family: 'Roboto';
  color: #777777;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  transition: all ease 0.3s;
  padding-bottom: 2px;
  cursor: pointer;
  margin: 8px 0px;
  width: max-content;
}

.cancel:hover {
  border-color: #777777;
}
