.action-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
}

.action-btn:hover {
  background-color: #f2f5f8;
}

.action-btn img {
  width: 16px;
}

.action-btn > button {
  border: none;
  background: none;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

@media screen and (min-width: 821px) {
  .action-btn > button {
    font-size: 16px;
  }
}
