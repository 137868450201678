.joined-graves-container {
  max-width: 360px;
  background: #ffffff;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
  margin: 40px 6% 40px auto;
}

.joined_graves_item {
  min-height: 160px;
  /* justify-content: center;
  align-items: center;
  border-radius: 15px;
  text-align: center; */
  cursor: pointer;
}

.joined_graves_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  column-gap: 10px;
  margin-top: 10px;
}

.joined-graves-header {
  width: 100%;
  display: flex;
}

.joined-graves-header_title > span {
  height: 15px;
  font-family: Minion Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 18px;
  text-align: center;
  color: #36434b;
  margin-right: auto;
}

.joined-graves-container > hr {
  width: 75%;
  height: 0.5px;
  background: #c4c4c4;
}

.grave_name {
  font-family: 'Minion Pro';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #36434b;
  hyphens: auto;
  -webkit-hyphens: auto; /* For Safari */
  -moz-hyphens: auto; /* For Firefox */
  -ms-hyphens: auto; /* For Internet Explorer */
  word-break: break-word;
  text-align: center;
}

.grave_info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grave_relation {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #777777;
}

.joined-graves-container > h1 {
  text-align: center;
  font-size: 24px;
  color: #fea65a;
  margin: 75px 0px;
}

.joined-graves-header_title {
  width: 100%;
  display: inline-flex;
  height: 42px;
  align-items: center;
  padding-bottom: 7px;
  padding-left: 10px;
  border-bottom: 1px solid #777;
}

.select-roles {
  justify-content: flex-end !important;
  display: flex;
}

.select-roles-filter_form_control {
  height: 30px !important;
}

.select-roles-filter_form_control > div {
  display: flex !important;
  text-align: center !important;
  background: inherit !important;
  padding: 0px 25px 0px 10px !important;
}

.privacy-select-roles {
  min-width: 30px !important;
  padding: 5px !important;
  background: #f5f5f5;
  border-radius: 7px;
  height: auto;
}

@media (max-width: 750px) {
  .joined-graves-container {
    width: unset;
    margin: 15px 10px;
    padding: 10px;
    max-width: 100%;
  }

  .joined_graves_grid {
    grid-template-columns: repeat(3, 30%);
    column-gap: 10px;
  }

  /* .joined_graves_item {
    height: 170px;
  } */

  .select-roles {
    width: 25%;
  }
}

@media (min-width: 700px) and (max-width: 950px) {
  .joined-graves-container {
    margin: 35px auto;
    padding: 10px;
    max-width: 70%;
  }

  .joined_graves_grid {
    grid-template-columns: repeat(4, 23%);
    column-gap: 10px;
  }

  /* .joined_graves_item {
    height: 170px;
  } */

  .select-roles {
    width: 25%;
  }

  .joined-graves-header_title {
    padding-left: 40px;
  }
}

/*@media (min-width: 800px) and (max-width:950px) {
  .joined-graves-container {
    width: 70%;
    margin: 35px auto;
    padding: 10px
  }

  .joined_graves_grid {
    grid-template-columns: repeat(4,23%);
    column-gap: 10px;
  }

  .joined_graves_item {
    height: 170px;
  }

  .select-roles {
    width: 25%;
  }

  .joined-graves-header_title {
    padding-left: 40px;
  }
}*/
