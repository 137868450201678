.add-new-image {
  border-radius: 10px;
  border: 1px solid #fea65a;
  background: #fff;
  display: flex;
  height: 100px;
  width: 100px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cropper-modal_content-input-image_image-input {
  display: none;
}

.add-new-image > span {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 750px) {
  .add-new-image {
    border-radius: 10px;
    height: max-content;
    width: max-content;
    cursor: pointer;
  }
  .add-new-image > span {
    color: #fea65a;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    padding: 12px 42px;
  }
}
