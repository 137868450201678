.homepage-findgrave_fourth {
    width: 100%;
    margin-bottom: 193px;
}

.homepage-findgrave_fourth-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.homepage-findgrave_fourth-content_image {
    position: relative;
    width: 130%;
    margin: auto 0px;
}

.homepage-findgrave_fourth-content_image-image {
    width: 100%;
}

.homepage-findgrave_fourth-content_empty {
    min-width: 45px;
}

.homepage-findgrave_fourth-content_main {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.homepage-findgrave_fourth-content_image_span {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
    color: #36434B;
    max-width: 64.3%;
    position: absolute;
    left: 10.1%;
    top: 5.8%;
}

.homepage-findgrave_fourth-content_image > span > span {
    color: #FEA65A;
}

.homepage-findgrave_fourth-content_main-block_text-header {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    color: #36434B;
    margin-bottom: 10px;
    min-width: 237px;
}

.homepage-findgrave_fourth-content_main-block_text-subheader {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #36434B;
}

.homepage-findgrave_fourth-content_main-block_text {
    display: flex;
    flex-direction: column;
}

.homepage-findgrave_fourth-content_main-block {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding-right: 96px;
    margin-bottom: 35px;
}

.homepage-findgrave_fourth-content_main-block_number {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 10px;
    margin-right: 45px;
}

.homepage-findgrave_fourth-content_main-block_number > span {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #FEA65A;
    margin-bottom: -5px;
}

.homepage-findgrave_fourth-content_main-block:last-child {
    margin-bottom: 0px;
}

.homepage-findgrave_fourth-content_image-image_mobile {
    display: none;
}

@media (max-width: 800px) {
    .homepage-findgrave_fourth {
        margin-bottom: 135px;
    }

    .homepage-findgrave_fourth-content {
        flex-direction: column;
    }

    .homepage-findgrave_fourth-content_image > span {
        font-size: 20px;
        line-height: 24px;
        width: 91.5%;
        top: auto;
        bottom: 4.5%;
        left: 4.2%;
        right: 4.2%;
        align-self: center;   
    }

    .homepage-findgrave_fourth-content_image{
        width: 100%;
    }

    .homepage-findgrave_fourth-content_empty {
        min-height: 50px;
    }

    .homepage-findgrave_fourth-content_main {
        box-sizing: border-box;
        padding: 0px 16px;
    }

    .homepage-findgrave_fourth-content_main-block {
        padding-right: 0px;
        margin-bottom: 50px;
    }
}

@media (max-width: 500px) {
    .homepage-findgrave_fourth-content_image-image {
        display: none;
    }

    .homepage-findgrave_fourth-content_image-image_mobile {
        display: block;
        width: 100%;
    }
}