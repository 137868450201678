.close-btn {
  position: absolute;
  top: 32px;
  right: 32px;
  background: none;
  border: none;
  cursor: pointer;
}

.true-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 8px;
}

.saved-title {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 8px;
}

.saved-message {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}
