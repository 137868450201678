.comment-reply {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #0000000d;
  padding: 12px;
}
.comment-reply_content {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 9px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.comment-reply_content_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
}
.comment-reply_content_header_user {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #36434b;
}
.comment-reply_content_header_dots {
  min-width: 0px !important;
  width: 41px !important;
}
.comment-reply_content_reply-text {
  width: 100%;
}
.comment-reply_content_reply-text > span {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
  word-break: break-word;
}

.music-post_content-title_form {
  display: flex;
  flex-direction: column;
}

.music-post_content-title_textarea_editing {
  resize: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid #9aa8b2;
  background: #fff;
  color: #777;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  background: white;
  overflow-y: scroll;
  max-height: 170px;
  cursor: text;
  overflow-y: scroll;
  overflow-wrap: break-word;
  background: #ffff;
  padding: 10px;
  margin-bottom: 12px;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 9px 14px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}
