.cropper-modal_content-input-image_image-input {
  display: none;
}

.cropper-modal_content-input-image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-bottom: 24px;
  /* margin-top: 24px; */
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  background: #fafafa;
}

.cropper-modal_content-input-image_image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.drop-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
  padding-top: 24px;
  background: #f2f5f8;
  margin-bottom: 24px;
  margin-top: 24px;
}

.upload-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-text-message {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #404d56;
}

.upload-image-input {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.upload-image-text {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #9aa8b2;
}

.upload-image-sub-heading {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #9aa8b2;
  margin-bottom: 12px;
}
.file-btn {
  background: #e4f3fd;
  border: none;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  padding: 16px 32px;
  color: #985000;
  box-shadow: 0px 4px 10px 0px #fea65a59;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 12px;
}

.file-name {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  text-align: center;
  color: #19262d;
  margin-bottom: 8px;
}
