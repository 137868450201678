.container {
  background-color: #fff;
  margin-bottom: 16px;
  padding: 12px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 40px !important;
  height: 40px !important;
  align-self: flex-start;
}

.btn {
  border: 1px solid #eee;
  border-radius: 20px;
  padding: 12px;
  min-height: 40px;
  background-color: #fff;
  width: 100%;
  text-align: start;
  cursor: pointer;
  transition: all ease 0.3s;
}

.btn:hover {
  background-color: #f2f5f8;
}

@media screen and (min-width: 821px) {
  .container {
    border-radius: 10px;
  }
}
