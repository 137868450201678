.music-modal_mobile {
    width: 375px;
    height: 680px;
    background-color: #fff;
    border-radius: 10px;
}

.music-modal_mobile-close {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 17px 11px 17px;
    border-bottom: 1px solid #F5F5F5;
}

.music-modal_mobile-close > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #C9CACB;
    margin-left: 5px;
}

.music-modal_mobile-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    height: calc(100% - 40px);
}

.music-modal_mobile-content_header {
    margin-bottom: 47px;
}

.music-modal_mobile-content_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.music-modal_mobile-content_input > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #36434B;
    margin-bottom: 8px;
}

.music-modal_mobile-content_input > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.music-modal_mobile-content_input > div > input {
    width: 100%;
    border: none;
    outline: none;
    background: #F5F5F5;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 12px 0px 12px 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #36434B;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

.music-modal_mobile-content_subheader {
    display: flex;
}

.add-by-link_header-subtitle.mobile {
    text-align: center;
}

.music-modal_mobile-content_buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
}

.music-modal_mobile-content_song {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: 7px;
}

.music-modal_mobile-content_song > img {
    margin-right: 5px;
}

.music-modal_mobile-content_song-list {
    width: 100%;
    overflow: auto;
}