.comment-reply-form {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  gap: 12px;
}
.comment-reply-form-input {
  width: 100%;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #777;
  background: #fff;
  border-radius: 25px;
  padding: 8px;
}

.reply-input {
  border-radius: 6px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  gap: 8px;
}

.comment-reply-form_input-button {
  border: none;
  background: #fea65a;
  border-radius: 50%;
  cursor: pointer;
  padding: 8px;
  display: flex;
}
