.tabs-container {
  display: flex;
  gap: 26px;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.btn {
  border: none;
  background-color: transparent;
  color: #6c7a82;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px;
  transition: ease 0.3;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.active-btn {
  border: none;
  background-color: transparent;
  color: #19262d;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px;
  transition: ease 0.3;
  cursor: pointer;
  border-bottom: 1px solid #2c3941;
}
