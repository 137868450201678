.grave-picture-gallery {
  max-width: 1140px;
  background: #ffffff;
  border-radius: 10px;
  overflow-x: hidden;
}

.set-cover-image {
  display: flex;
  align-items: center;
  padding: 15px 13px;
  flex-direction: row;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  margin-bottom: 42px;
}

.set-cover-image > input[type='checkbox'] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  color: #fea65a;
  width: 16px;
  height: 16px;
  border: 0.15em solid #77848d;
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.set-cover-image > input[type='checkbox']:checked {
  border: 0.15em solid #fea65a;
}

.set-cover-image > input[type='checkbox']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #fea65a;
}

.set-cover-image > input[type='checkbox']:checked::before {
  transform: scale(1);
}

.set-cover-image > label {
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 19px;
  color: #36434b;
  margin-left: 20px;
}

.grave-picture-gallery_photos {
  width: 100%;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.grave-picture-gallery_photos-photo {
  width: 100%;
  height: 500px;
  object-fit: contain;
  object-position: center;
  border-radius: 10px 10px 0px 0px;
  backdrop-filter: blur(20px);
}

.grave-picture-gallery_photos-cropper {
  width: 100% !important;
}

.grave-picture-gallery_photos-cropper > img {
  width: 100% !important;
  opacity: 1 !important;
}

.grave-picture-gallery_photos-details {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 1;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 16px;
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(180deg, #343434 0%, rgba(0, 0, 0, 0) 100%);
}

.grave-picture-gallery_photos-details_address {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grave-picture-gallery_photos-details_address > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 3px;
  text-align: center;
}

.grave-picture-gallery_photos-navigations {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* top: 92%;
  margin: 5px 10px 0px 10px; */
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.grave-picture-gallery_photos-navigations_dot {
  min-width: 0px !important;
}

.grave-picture-gallery_photos-details_address > div {
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(254, 166, 90, 0) 0%,
    #fea65a 50.52%,
    rgba(254, 166, 90, 0) 100%
  );
  height: 2px;
}

.grave-picture-gallery_photos-photo:hover
  ~ .grave-picture-gallery_photos-details,
.grave-picture-gallery_photos-photo:hover
  ~ .grave-picture-gallery_photos-buttons,
.grave-picture-gallery_photos-photo:hover ~ .grave-picture-gallery_photos-age {
  opacity: 1 !important;
  transition: 0.5s;
}

.grave-picture-gallery_photos-buttons:hover,
.grave-picture-gallery_photos-details:hover {
  opacity: 1;
  transition: 0.5s;
}

.grave-picture-gallery_photos-buttons:hover
  ~ .grave-picture-gallery_photos-details {
  opacity: 1;
  transition: 0.5s;
}

.grave-picture-gallery_photos-details > img {
  cursor: pointer;
  transition-duration: 0.4s;
}

.grave-picture-gallery_photos-details > img:hover {
  opacity: 0.5;
}

.grave-picture-gallery_photos-details.edit {
  opacity: 1 !important;
  background: none !important;
  display: flex;
  justify-content: flex-end !important;
}

.grave-picture-gallery_photos-buttons {
  opacity: 1;
  position: absolute;
  width: 100%;
  transition: 0.5s;
  top: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.grave-picture-gallery_photos-buttons_prev {
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-radius: 0px 6px 6px 0px !important;
  min-width: 0 !important;
  width: 36px;
  height: 71px;
}

.grave-picture-gallery_photos-buttons_next {
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-radius: 6px 0px 0px 6px !important;
  min-width: 0 !important;
  width: 36px;
  height: 71px;
}

.grave-picture-gallery_photos-buttons_prev:hover,
.grave-picture-gallery_photos-buttons_next:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.grave-picture-gallery_content {
  box-sizing: border-box;
  width: 100%;
  padding: 24px 100px 8px 100px;
}

.grave-picture-gallery_content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.grave-picture-gallery_content-header_profile {
  display: flex;
  flex-direction: row;
}

.grave-picture-gallery_content-header_profile-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
}

.grave-picture-gallery_content-header_profile-name > a {
  text-decoration: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #fea65a;
}

.grave-picture-gallery_content-header_profile-name > span:last-child {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #777777;
}

.grave-picture-gallery_content-header_famile {
  display: flex;
  align-items: center;
}

.grave-picture-gallery_content-header_famile > img {
  width: 17px;
}

.grave-picture-gallery_content-header_famile > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #9f9f9f;
  margin-left: 5px;
}

.grave-picture-gallery_photos-buttons_prev.hide,
.grave-picture-gallery_photos-buttons_next.hide {
  opacity: 0;
}

.grave-picture-gallery_content-post {
  width: 100%;
  margin-bottom: 14px;
}

.grave-picture-gallery_content-post > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
  color: #36434b;
}

.grave-picture-gallery_buttons {
  width: 100%;
  border-top: 1px solid #d8d8d8;
}

.grave-picture-gallery_buttons-hug {
  width: 50%;
  height: 57px;
  border-radius: 0px 0px 0px 10px !important;
}

.grave-picture-gallery_content-comments {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #9f9f9f;
}

.grave-picture-gallery_buttons-memorial {
  width: 50%;
  height: 57px;
  border-radius: 0px 0px 10px 0px !important;
  text-transform: none !important;
  color: #9f9f9f !important;
  font-weight: 500 !important;
}

.grave-picture-gallery_photos-details_menu-item {
  box-sizing: border-box;
  padding: 15px 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grave-picture-gallery_photos-details_menu-item > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #36434b;
  margin-left: 20px;
}

.grave-picture-gallery_photos-age {
  background-image: url('../../../../img/AgeBox.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  /* width: 59px;
    height: 26px; */
  width: 70px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  opacity: 0;
}

.grave-picture-gallery_photos-age.edit {
  opacity: 1 !important;
}

.grave-picture-gallery_photos-age_close {
  position: absolute;
  top: 5px;
  right: -2px;
  cursor: pointer;
  width: 10px;
  height: 10px;
}

.grave-picture-gallery_photos-age > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.grave-picture-gallery_edit-post {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 26px;
}

.grave-picture-gallery_edit-post_second {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.grave-picture-gallery_edit-post_second > input {
  border: none;
  outline: none;
  width: 48%;
  box-sizing: border-box;
  padding: 13px 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  background: #f5f5f5;
  border-radius: 8px;
}

.grave-picture-gallery_edit-post_second-button {
  background: #36434b !important;
  border-radius: 8px !important;
  width: 48% !important;
  height: 44px !important;
  text-transform: none !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  box-sizing: border-box !important;
  padding-left: 20px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #fff !important;
}

.grave-picture-gallery_edit-post > textarea {
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  background: #f5f5f5;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 9px 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
  color: #36434b;
}

.grave-picture-gallery_edit-post_save {
  width: 180px !important;
  height: 55px !important;
  background: #fea65a !important;
  border-radius: 10px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #fff !important;
  text-transform: none !important;
  align-self: center !important;
}

.grave-picture-gallery_photos-choose {
  width: 165px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 18px auto 0px;
}

.grave-picture-gallery_photos-choose > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
}

.grave-picture-gallery_photos-choose_unactive {
  min-width: 0px !important;
  width: 21px !important;
  height: 21px !important;
  background-color: #f5f5f5 !important;
  border-radius: 3px !important;
}

.grave-picture-gallery_photos-choose_active {
  min-width: 0px !important;
  width: 21px !important;
  height: 21px !important;
  padding: 0px !important;
}

.grave-picture-gallery_photos-choose_active-image {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 720px) {
  .grave-picture-gallery_content {
    padding: 24px;
  }
}
