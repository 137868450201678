.notes-container {
  border-radius: 10px;
  background: #404d56;
  padding: 35px 24px;
}

.heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.heading {
  color: #e4f3fd;
  font-family: 'Roboto';
  font-size: 25px;
  font-weight: 500;
}

.action-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
}

.action-btn:hover {
  background-color: #576062;
}

.action-btn img {
  width: 16px;
}

.action-btn > button {
  border: none;
  background: none;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.notes {
  color: #edece9;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 133.687%;
}

@media screen and (max-width: 1020px) {
  .notes-container {
    border-radius: 0;
    padding: 24px 16px;
  }
  .action-btn > button {
    display: none;
  }
}
