.member-heading {
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 19px;
  font-size: 22px;
  margin-bottom: 8px;
  color: #19262d;
}

.member-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
}

.member-sub-heading {
  margin-bottom: 24px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 24px;
  line-height: 19px;
  color: #404d56;
}

.member-container {
  margin-bottom: 12px;
  padding: 24px 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 4px 10px 3px rgba(0, 0, 0, 0.05);
}

.avatar-wrapper {
  display: flex;
  gap: 12px;
}

.user-name,
.user-phone-number {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
}

.remove-button {
  cursor: pointer;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #f2f5f8;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  transition: ease 0.3s;
}

.remove-button:hover {
  background-color: #cecfd0;
}

/* .remove-button {
  background-color: transparent;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  color: red;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
}

.remove-button:hover {
  background-color: #f2f5f8;
} */

.invitation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.subscription-invitations,
.subscription-members {
  margin-bottom: 42px;
}

.empty-message {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 24px;
  line-height: 19px;
  color: #404d56;
}
