.virtue-modal {
  background-color: #fff;
  border: none;
  outline: none;
  border-radius: 10px;
  /* width: 615px; */
}

.loading {
  min-width: 500px;
}

.virtue-modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 28px 20px; */
}

.virtue-modal_content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.virtue-modal_content-header > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #36434b;
}

.virtue-modal_content-header > img {
  transition-duration: 0.4s;
  cursor: pointer;
}

.virtue-modal_content-header > img:hover {
  opacity: 0.6;
}

.virtue-modal_content-subheader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

.virtue-modal_content-subheader > span,
.virtue-modal_content-text > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
}

.virtue-modal_content-suggestions-list,
.virtue-modal_content-add-suggestions-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 80px;
}

.virtue-modal_content-suggestions-list img {
  opacity: 0.7;
  right: 23px;
  position: absolute;
  z-index: 1;
  margin-top: 3px;
  cursor: pointer;
  width: 14px;
  height: 14px;
}

.select-roles-filter_form_control {
  height: 30px !important;
}

.select-roles-filter_form_control > div {
  display: flex !important;
  text-align: center !important;
  background: #fff !important;
  padding: 0px 30px 0px 10px !important;
}

.virtue-modal_content-suggestions-list button > span:nth-child(1) {
  padding: 0 10px;
}

.virtue-modal_content-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 14px;
}

.virtue-modal_content-add-new-suggestion {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.virtue-modal_content-add-story {
  width: 100%;
  display: flex;
  margin-bottom: 25px;
}

.virtue-modal_content-add-story > textarea {
  outline: none;
  resize: none;
  border: none;
  border-radius: 10px;
  background: #f5f5f5;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 11px;
  width: 100%;
}

.virtue-modal_content-input-new-suggestion {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.virtue-modal_content-input-new-suggestion.disabled {
  display: none;
}

.virtue-modal_content-input-new-suggestion > input {
  width: 100%;
  outline: none;
  border: none;
  padding: 11px 142px 12px 11px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #36434b;
  background: #f5f5f5;
  border-radius: 10px;
}

.virtue-modal_content-suggestion-description {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 14px;
}

.virtue-modal_content-suggestion-description.disabled {
  display: none;
}

.virtue-modal_content-suggestion-description > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
}

.virtue-modal_content-suggestion-description > span > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fea65a;
}

@media (max-width: 750px) {
  .loading {
    min-width: 100%;
  }
}
