.music-player {
  box-shadow: none !important;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.music-player .rhap_controls-section,
.music-player .rhap_time.rhap_current-time,
.music-player .rhap_progress-indicator {
  display: none !important;
}

.music-player .rhap_progress-filled {
  background-color: #fea65a !important;
}

.player_button-image {
  width: 9px;
  height: 12px;
  margin-right: -2px;
}

.player_button {
  min-width: 0px !important;
  min-height: 0px !important;
  width: 29px !important;
  height: 25px !important;
  border-radius: 50% !important;
  background-color: #fea65a !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px !important;
}

.player_button-secs {
  min-width: 0px !important;
  min-height: 0px !important;
  padding: 0px !important;
  margin-left: 10px !important;
}

.remove-audio {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: 0.3s ease;
  padding: 4px;
}

.remove-audio:hover {
  background-color: #cccc;
}
