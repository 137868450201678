.delete-virtue {
  background-color: #fff;
  border: none;
  outline: none;
  border-radius: 10px;
}

.delete-virtue_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-virtue_content-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.delete-virtue_content-header > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #36434b;
}

.delete-virtue_content-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
}

.delete-virtue_content-main > img {
  width: 245px;
  height: 167px;
  margin-bottom: 35px;
}

.delete-virtue_content-main > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #36434b;
}

.delete-virtue_content-main > span > span {
  color: #fea65a;
}

.delete-virtue_buttons {
  width: 100%;
}

@media (max-width: 750px) {
  .delete-virtue_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
