.memories-buttons-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.memories-buttons {
  display: flex;
  justify-content: center;
  margin: auto;
}

.cropper-modal_content-input-image_image-input {
  display: none;
}

.memories-buttons-group_post {
  border-radius: 10px;
  border: 1px solid #fea65a;
  background-color: #fff;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 30px;
  cursor: pointer;
}

.memories-buttons-group_post:disabled {
  background: #ccc;
  cursor: not-allowed;
  color: #fff;
  border: 1px solid transparent;
}

.memories-buttons-group_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.memories-buttons-group_btn-video {
  cursor: pointer;
  transition-duration: 0.3s;
}

.memories-buttons-group_btn-video-mobile {
  display: none !important;
  visibility: hidden !important;
}

@media screen and (max-width: 750px) {
  .memories-buttons-group {
    flex-direction: column;
    margin-top: 0;
    padding-left: 0;
    width: 95%;
  }

  .memories-buttons {
    display: flex;
    justify-content: center;
  }

  .memories-buttons-group_btn {
    width: 100%;
    background: #ffffff;
    justify-content: space-around;
    padding-bottom: 18px;
    padding-top: 10px;
  }

  .memories-buttons-group_btn-video {
    display: none;
  }

  .memories-buttons-group_btn-video-mobile {
    display: block !important;
    visibility: visible !important;
  }

  .photo-menu_simple-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .photo-menu_simple-menu > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize !important;
    color: #36434b;
    margin-left: 12px;
  }
}

@media (min-width: 750px) and (max-width: 850px) {
  .memories-buttons {
    justify-content: center;
  }

  .memories-buttons-group {
    width: 90%;
    padding: 0;
  }
}
