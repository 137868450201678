.homepage-findgrave_third {
    width: 100%;
    margin-bottom: 20px;
}

.homepage-findgrave_third-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepage-findgrave_third-content_image {
    width: 100%;
    margin-bottom: 30px;
}

.homepage-findgrave_third-content_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.homepage-findgrave_third-content_header-main {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #36434B;
    margin-bottom: 30px;
}

.homepage-findgrave_third-content_header-main > span {
    color: #FEA65A;
}

.homepage-findgrave_third-content_header-submain {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #36434B;
    opacity: 0.8;
    margin-bottom: 30px;
    max-width: 800px;
}

.homepage-findgrave_third-content_header-button {
    width: 152px !important;
    height: 55px !important;
    background: #36434B !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161) !important;
    border-radius: 10px !important;
    font-family: 'Roboto Medium' !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #FFFFFF !important;
    text-transform: none !important;
}

.homepage-findgrave_third-content_image-mobile {
    display: none;
}

.homepage-findgrave_third-content_header-button.extra {
    width: fit-content !important;
    box-sizing: border-box !important;
}

.homepage-findgrave_third-content_image-extra {
    margin-bottom: 30px;
    width: 100%;
}

.homepage-findgrave_third-content_header-find {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    margin: 0 auto;
}

.homepage-findgrave_third-content_header-find_input {
    border: 1px solid #D8D8D8 !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    width: 100% !important;
    outline: none !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important; 
    font-size: 16px !important;
    line-height: 19px !important;
    margin: 0 20px;
}

.homepage-findgrave_third-content_header-find_input div {
    margin: 0px !important;
}

.homepage-findgrave_third-content_header-find_input input {
    padding: 15px 13px 15px 13px !important;
    box-sizing: border-box !important;
    width: 100% !important;
    height: 100% !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #36434B !important;
}

.homepage-findgrave_third-content_header-find_button {
    text-decoration: none;
    background: #36434B;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161);
    border-radius: 10px;
    height: 55px;
    padding: 0px 10px;
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-top: 20px;
    transition: 0.4s;
    visibility: visible;
    font-weight: 500;
}

.homepage-findgrave_third-content_header-find_button.hide {
    visibility: hidden;
}

.homepage-findgrave_third-content_header-find_button > span {
    text-transform: capitalize;
}

@media (max-width: 800px) {
    .homepage-findgrave_third {
        margin-bottom: 50px;
    }

    .homepage-findgrave_third-content_header-find {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .homepage-findgrave_third-content_header-find_input {
        margin: 10px 0;
    }

    .homepage-findgrave_third-content_header-main {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    .homepage-findgrave_third-content_image {
        margin-bottom: 40px;
    }

    .homepage-findgrave_third-content_header-submain {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 50px;
        max-width: 91.5%;
    }
}

@media (max-width: 500px) {
    .homepage-findgrave_third-content_image {
        display: none;
    }

    .homepage-findgrave_third-content_image-mobile {
        display: block;
        margin-bottom: 40px;
        width: 100%;
    }
}


.MuiAutocomplete-popper {
    margin: 0 auto;
    max-height: 700px;
    position: relative;
    overflow: hidden;
    display: block;
}