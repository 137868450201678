.about-item-content_row-container {
  display: flex;
  flex-direction: column;
}

.about-item-content_row {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 0px;
}

.icon {
  background: #e4f3fd;
  padding: 8px;
}

.about-item-value-container {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.about-item-key {
  color: #737a7f;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
}

.about-item-value {
  color: #36434b;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  /* line-height: 18px; */
}
.empty-grave-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(300px - 24px);
}

.empty-message {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.add-btn {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
}

@media screen and (min-width: 1020px) {
  .about-item-content_row-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 24px;
  }

  .about-item-content_row {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0;
  }

  .icon {
    padding: 14px;
  }
}
