/* .settings-page {
  background-color: #f2f5f8;
} */

.settings-page-wrapper {
  padding: 20px 0px 40px 0px;
}

.settings-page-heading {
  padding-bottom: 20px;
  background-color: #fff;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.page-heading-title {
  color: #36434b;
  font-family: 'Minion Pro';
  font-size: 20px;
  font-weight: 700;
}

.settings-page_content {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.content_page_border {
  background: #fff;
  border-radius: 10px;
  padding: 24px;
}

@media screen and (min-width: 900px) {
  .settings-page_content {
    flex-direction: row;
  }
  .settings-page-wrapper {
    width: 90%;
    margin: 0 auto;
    padding: 20px 14px;
  }
  .content_page_border {
    flex: 1;
    padding: 40px 36px;
  }
  .page-heading-title {
    font-size: 26px;
    font-weight: 400;
  }
}
