.relation-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.relations-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.relations-title {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: all ease 0.3s;
  width: max-content;
}

.relations-title:hover {
  border-color: #404d56;
}

.relations-avatar-group {
  cursor: pointer;
}

.popover-row-relations {
  display: flex;
  width: auto;
  padding: 12px;
  align-items: center;
  border-bottom: 1px solid #36434b;
  gap: 8px;
}

.popover-row-relations:hover {
  background: #d8d8d8;
  cursor: pointer;
}

.grave-information-wrapper {
  display: flex;
  flex-direction: column;
}

.grave-information_name {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
}

.grave-information_lifespan {
  color: #8d9093;
  font-family: 'Roboto';
  font-size: 14px;
}

.avatar {
  height: 38px !important;
  width: 38px !important;
}
