.card {
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease;
  color: #999;
  padding: 16px;
  background: #fff;
  box-shadow: 1px 4px 10px 3px #0000000d;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #fea65a;
  box-sizing: border-box;
  border-radius: 70px 70px 0 0;
  background-color: #fff;
  margin-bottom: 20px;
  width: 122px;
  height: 130px;
}

.image-container > img {
  width: 60px;
  padding: 8px;
  background-color: #fff;
}

.name {
  font-family: 'Roboto';
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
}

.country {
  font-family: 'Roboto';
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.btn {
  background-color: #fff;
  color: #36434b;
  border: 1px solid #36434b;
  border-radius: 6px;
  padding: 12px 42px;
  width: max-content;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Roboto';
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.btn:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
