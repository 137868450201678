.warning-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
}

.warning {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 20.56px;
  color: #c65c00;
}

.input-container {
  margin-bottom: 24px;
  width: 100%;
}

.MuiFilledInput-input {
  padding: 16px !important;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 12px;
}

.info-label-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.label {
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
}

.auth-button {
  width: 100%;
  border-radius: 4px !important;
  border: 1px solid transparent !important;
  background: #f5f5f5;
}

.error {
  border-color: #c65c00 !important;
}

.auth-button > div {
  border-radius: 4px !important;
}

.auth-button:not(.error):hover {
  border-color: #36434b !important;
}

.auth-button:focus {
  background-color: #f5f5f5 I !important;
  border-radius: 4px !important;
}

.auth-button > div > input,
.auth-button > input {
  padding: 19px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.change-input {
  font-family: 'Roboto' Condensed;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #777777;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  padding-bottom: 2px;
  transition: ease 0.3s;
}

.change-input:hover {
  font-weight: 500;
  border-color: #777777;
}

.info-label {
  font-family: 'Roboto' Condensed;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #777777;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 inset !important;
  box-shadow: 0 0 0px 1000px #f5f5f5 inset !important;
  -webkit-text-fill-color: #000 !important;
  transition: background-color 5000s ease-in-out 0s;
}
