.container {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 16px;
}

.sparks-list {
  flex: 1;
}

.user-container {
  display: none;
}

.sparks-container {
  display: flex;
  /* flex-direction: column-reverse; */
}

@media screen and (min-width: 821px) {
  .user-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .sparks-container {
    gap: 20px;
    flex-direction: row;
  }
  .sticky {
    position: sticky;
    top: 166px;
    height: fit-content;
  }
}
