.homepage-scientists_third {
    width: 100%;
    margin-bottom: 132px;
}

.homepage-scientists_third-content {
    width: 100%;
    position: relative;
}

.homepage-scientists_third-content_magazine {
    position: absolute;
    bottom: 0;
    right: 0;
}

.homepage-scientists_third-content_orange-box {
    min-height: 102px;
    width: 100%;
    background: #FEA65A;
    z-index: -1;
    box-sizing: border-box;
    padding: 23px 550px 23px 113px;
    display: flex;
    align-items: flex-start;
    margin-top: -4px;
}

.homepage-scientists_third-content_orange-box > span {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    letter-spacing: 0.02em;
}

.homepage-scientists_third-content_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 75px;
    left: 0;
    width: 50%;
    margin-left: 5.5%;
}

.homepage-scientists_third-content_header-main {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 48px;
    line-height: 58px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.homepage-scientists_third-content_header-submain {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #F5F5F5;
    margin-bottom: 20px;
}

.homepage-scientists_third-content_header-link {
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #45DDFF;
}

.homepage-scientists_third-content_image-mobile {
    display: none;
}

@media (max-width: 1130px) {
    .homepage-scientists_third-content_header {
        margin-left: 3.5%;
        width: 45%;
        top: 45px;
    }
}

@media (max-width: 980px) {
    .homepage-scientists_third-content_header {
        margin-left: 2.5%;
        width: 40%;
        top: 30px;
    }
}

@media (max-width: 1050px) {
    .homepage-scientists_third-content_orange-box {
        padding-left: 90px;
    }
}

@media (max-width: 1000px) {
    .homepage-scientists_third-content_orange-box {
        padding-left: 60px;
    }
}

@media (max-width: 880px) {
    .homepage-scientists_third-content_orange-box {
        padding: 73px 180px 73px 16px;
        display: flex;
        align-items: center;
        min-height: 237px;
    }

    .homepage-scientists_third {
        width: 100%;
        margin-bottom: 107px;
    }

    .homepage-scientists_third-content_header-link {
        align-self: flex-start;
    }

    .homepage-scientists_third-content_orange-box > span {
        font-size: 16px;
        line-height: 24px;
    }

    .homepage-scientists_third-content_magazine {
        right: -340px;
    }

    .homepage-scientists_third-content_header {
        width: 100%;
        padding: 0px 15px 0px;
        margin: 0;
        box-sizing: border-box;
        top: 105px;
        display: flex;
        align-items: center;
    }

    .homepage-scientists_third-content_header-main {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 42px;
    }

    .homepage-scientists_third-content_header-submain {
        font-size: 14px;
        line-height: 19px;
    }
}

@media (max-width: 500px) {
    .homepage-scientists_third-content_image-mobile {
        display: block;
        width: 100%;
    }

    .homepage-scientists_third-content_image {
        display: none;
    }
}