.memory-full-mode {
  width: 1090px;
  height: 772px;
  overflow: auto;
  padding: 10px;
}

.post-details {
  padding: 22px;
}

.video-post-user-title-container {
  display: flex;
  gap: 10px;
}

.title-on-post-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title-on-post {
  font-family: 'Roboto';
  font-size: 28px;
  font-weight: 500;
  color: #36434b;
}

.uploading-details {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: #36434b;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.uploaded-text {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: #9aa8b2;
}

.post-create-date {
  border-left: 1px solid #0000001a;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: #36434b;
  padding-left: 8px;
  margin-left: 8px;
}

.media-holder {
  float: left;
  width: 60%;
  border: 1em;
  border-color: black;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-holder {
  float: right;
  width: 40%;
}

.memory_photos-navigations {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 92%;
  margin: 5px 10px 0px 10px;
}

.photo-post_content-photo_photo {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: contain;
  object-position: center;
}

.music-post_content-photo {
  position: relative;
  height: 500px;
  width: 100%;
  background-color: #f2f5f8;
  margin-bottom: 12px;
}

.photo-post-content_loading {
  background-image: url('../../../../service/Loading_animation.gif');
  background-size: 150px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
}

.buttons-prev {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 6px 6px 0px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 50%;
  left: 0;
  cursor: pointer;
}

.navigation_dot {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons-next {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px 0px 0px 6px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
}

.photo-post-modal {
  width: 100%;
}

.photo-post-modal_image-buttons_prev.hide,
.photo-post-modal_image-buttons_next.hide {
  opacity: 0;
}

.photo-post-modal_image-buttons_prev,
.photo-post-modal_image-buttons_next {
  min-width: 40px !important;
}

.photo-post-modal_image {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
}

.photo-post-modal_image-main {
  margin-top: 45px;
  border-radius: 10px;
  max-width: 99%;
  max-height: 440px;
}

.photo-post-full-mode-loading {
  min-height: 300px;
  margin-bottom: 30px;
  padding: 5px;
  border-radius: 10px;
  text-align: center !important;
  border-radius: 6px;
}

.photo-post-modal_image-close {
  opacity: 1;
  position: absolute;
  right: 0;
  border-radius: 0px 10px 0px 0px;
  box-sizing: border-box;
  padding: 16px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 1;
}

.music-post_content-category.video-post.video-post-modal {
  width: 100%;
}

.music-post_content-category.video-post.video-post-modal > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.music-post_content-category.video-post.video-post-modal > div > div {
  width: 60px;
  height: 2px;
  background-color: #fea65a;
  margin-top: 6px;
}

.photo-post-modal_image-close:hover {
  opacity: 0.5;
}

.photo-post-modal_image-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  opacity: 1;
  transition: 0.5s;
}

.photo-post-modal_image-navigations {
  /* position: absolute;
  top: 92%; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-post-modal_image .music-post_content_in-modal {
  margin-top: 70px;
}

.video-post-modal_content-user {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  /* padding: 0px 16px 12px; */
  margin-bottom: 12px;
}

.video-post-modal_content-user_info {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
}

.video-post-modal_content-user_info > a {
  text-decoration: none;
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.video-post-modal_content-user_menu {
  margin-left: auto !important;
}

.video-post-modal_content-user_info > span:first-child {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #fea65a;
}

.video-post-modal_content-user_info > span:last-child {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #777777;
}

.music-post_content-title_form {
  display: flex;
  flex-direction: column;
  /* padding: 0px 16px 12px; */
  margin-bottom: 12px;
}

.music-post_content-title_textarea {
  color: #777;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  background: white;
  overflow-y: scroll;
  max-height: 170px;
  overflow-wrap: break-word;
  /* padding: 0px 16px 12px; */
  margin-bottom: 12px;
}

.music-post_content-title_textarea_editing {
  resize: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid #9aa8b2;
  background: #fff;
  color: #777;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  background: white;
  overflow-y: scroll;
  max-height: 170px;
  cursor: text;
  overflow-y: scroll;
  overflow-wrap: break-word;
  background: #ffff;
  padding: 10px;
  margin-bottom: 12px;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 9px 14px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.video-post-modal_content-comments {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  box-shadow: 1px 3px 4px 0px #0000001a;
  background: #fff;
  margin-bottom: 12px;
}

.header {
  margin-bottom: 60px;
  width: max-content;
}

.header-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .memories_comments-area-containers {
  padding: 0px 14px;
} */

.video-post-modal_content-comments > span {
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  color: #36434b;
}
/* 
.memories_comments-area_comments {
  padding: 0 10px;
} */

@media only screen and (max-width: 900px) {
  .title-on-post {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
  }
}

@media only screen and (max-width: 620px) {
  .post-create-date {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 900px) {
  /* .photo-post-modal {
    width: 100vw;
    min-height: 100vh;
  } */
  .MuiDialog-container {
    /* height: auto !important; */
    padding: 0 !important;
  }
  .MuiDialog-paper {
    margin: 0 !important;
    border-radius: 0 !important;
  }
  /* ::-webkit-scrollbar {
        width: 0;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
    } */
  .music-post_content-category.video-post.video-post-modal {
    padding: 17px 0 18px;
  }

  .photo-post-modal .memories_comments-area_content {
    padding-bottom: 45px;
  }

  .photo-post-modal_image-main {
    width: auto;
    height: auto;
    border-radius: 10px;
  }
}
