.cropper-modal_content-input-image_image-input {
  display: none;
}

.cropper-modal_content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 12px;
}

.cropper-modal_content-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 43px;
  justify-content: flex-end;
}

.cropper-modal_content-header > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #36434b;
}

.cropper-modal_content-header > img {
  cursor: pointer;
  transition-duration: 0.4s;
}

.cropper-modal_content-header > img:hover {
  opacity: 0.6;
}

.cropper-modal_content-subheader {
  align-self: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #36434b;
  margin-bottom: 16px;
}

.cropper-modal_content-choosen-photos {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  margin-bottom: 50px;
}

.cropper-modal_content-choosen-photos_image {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 27px;
}

.cropper-modal_content-choosen-photos_image-loaded {
  min-width: 170px;
  height: 170px;
  border-radius: 10px;
}

.cropper-modal_content-choosen-photos_image-delete {
  position: absolute;
  align-self: flex-end;
  margin: 10px;
  margin-left: -10px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.cropper-modal_content-choosen-photos_image-delete:hover {
  opacity: 0.7;
}

.cropper-modal_content-choosen-photos_image-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 20px;
}

.cropper-container {
  width: 100%;
  height: 100%;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0px;
}

.save-btn {
  background-color: #404d56;
  color: white;
  padding: 12px 42px;
  border-radius: 10px;
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 19px;
  border: none;
  cursor: pointer;
}
.cropper-modal_content-choosen-photos_image-button > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #36434b;
}

.second-modal_content-go-back {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition-duration: 0.4s;
  margin-bottom: 13px;
}

.second-modal_content-go-back:hover {
  opacity: 0.6;
}

.second-modal_content-go-back > span {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  margin-left: 11px;
}

.cropper-modal_content-input-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* .cropper-modal_content-input-image > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #36434B;
    margin-bottom: 16px;
} */

.cropper-modal_content-input-image_image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.upload-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.image-text-message {
  color: #404d56;
  text-align: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 133.187%;
}

.upload-image-input {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.upload-image-text {
  color: #fea65a;
  text-align: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 170%;
}

/* .cropper-modal_content-input-image_image-upload {
    transition-duration: 0.4s;
    cursor: pointer;
}

.cropper-modal_content-input-image_image-upload:hover {
    opacity: 0.5;
} */

.cropper-modal_content-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px;
}

/* .cropper-modal_content-cropper-block-crop {
  width: 200px !important;
  height: 200px !important;
} */

.cropper-modal_content-cropper-block {
  /* display: flex;
  flex-direction: column;
  background-color: rgba(54, 67, 75, 0.5);
  border-radius: 10px;
  padding-bottom: 39px; */
  width: 100%;
  height: 100%;
}
/* 
.cropper-modal_content-cropper-block > div {
  display: flex;
  justify-content: flex-end;
}

.cropper-modal_content-cropper-block > div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cropper-modal_content-cropper-block_delete-image {
  margin: 5px;
} */
.cropper-modal_content-inputs-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* .cropper-modal_content-inputs-block > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
} */

/* SCROLLBAR 8*/

/* ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	height: 8px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
} */

/* SCROLLBAR 8*/

/* Cropper */

.cropper-modal {
  opacity: 1 !important;
}

.cropper-drag-box {
  opacity: 0 !important;
}

/* .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    background-color: #fff !important;
} */

.MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiInput-input:focus {
  background: inherit !important;
}

.cropper-modal_content-inputs-block_select-cemetery {
  background-color: #f2f5f8 !important;
  padding: 0;
}

.grave-picture-gallery_photos-cropper {
  width: 100% !important;
}

.grave-picture-gallery_photos-cropper > img {
  width: 100% !important;
  opacity: 1 !important;
}

/* Cropper */

/* @media (max-width: 750px) {
  .crop-modal {
    width: 100%;
    overflow-x: hidden;
  }
} */
