.registration-page {
  background-color: #fff;
  display: flex;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 570px;
}
