.homepage-grave_second {
    width: 100%;
    margin-bottom: 107px;
}

.homepage-grave_second-content {
    width: 100%;
}

.homepage-grave_second-content_header {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 0px 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homepage-grave_second-content_header-text {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #36434B;
    margin-bottom: 12px;
    letter-spacing: 0.02em;
}

.homepage-grave_second-content_header-text > span {
    color: #FEA65A;
}

.homepage-grave_second-content_header-subtext {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #9F9F9F;
    max-width: 596px;
    letter-spacing: 0.02em;
}

.homepage-grave_second-content_main {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.homepage-grave_second-content_main-first {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 173px;
}

.homepage-grave_second-content_main-first_text {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding: 0px 89px 0px 71px;
}

.homepage-grave_second-content_main-first_images {
    width: 100%;
    position: relative;
}

.homepage-grave_second-content_main-first_text-header {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #36434B;
    margin-bottom: 16px;
    letter-spacing: 0.02em;
    color: #36434B;
}

.homepage-grave_second-content_main-first_text-subheader {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #4F4F4F;
    margin-bottom: 32px;
    opacity: 0.8;
    letter-spacing: 0.02em;
}

.homepage-grave_second-content_main-first_text-subheader > span {
    color: #FEA65A;
}

.homepage-grave_second-content_main-first_text-button {
    text-decoration: none;
    width: 180px;
    height: 55px;
    background: #36434B;
    box-shadow: 0px 1px 6px rgba(254, 166, 90, 0.302);
    border-radius: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    transition-duration: .4s;
}

.homepage-grave_second-content_main-first_images-main {
    width: 100%;
}

.homepage-grave_second-content_main-first_images-grey {
    background: rgba(245, 245, 245, 0.6);
    border-radius: 10px;
    width: 149px;
    height: 149px;
    position: absolute;
    right: 0;
    top: -17%;
    z-index: -1;
}

.homepage-grave_second-content_main-first_images-orange {
    background: #FFE4CE;
    border-radius: 10px;
    width: 149px;
    height: 149px;
    position: absolute;
    z-index: -1;
    bottom: -9%;
    left: -5%;
}

.homepage-grave_second-content_main-first.second {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
}

.homepage-grave_second-content_main-first_images-grey.second {
    top: -9%;
    right: -5%;
}

.homepage-grave_second-content_main-first_images-orange.second {
    left: 0;
    bottom: -17%;
}

.homepage-grave_second-content_main-first_text-button.second {
    background: #FEA65A;
    box-shadow: 0px 1px 6px rgba(254, 166, 90, 0.302);
    border-radius: 10px;
}

.homepage-grave_second-content_main-first_text-button:hover {
    opacity: 0.5;
}

@media (max-width: 750px) {
    .homepage-grave_second {
        margin-bottom: 80px;
    }

    .homepage-grave_second-content_main-first {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-bottom: 94px;
    }

    .homepage-grave_second-content_header-subtext {
        width: 343px;
    }

    .homepage-grave_second-content_header {
        padding: 54px 0px 60px;
    }

    .homepage-grave_second-content_main-first_images {
        margin-bottom: 79px;
    }

    .homepage-grave_second-content_main-first_images-grey, .homepage-grave_second-content_main-first_images-orange {
        width: 78px;
        height: 78px;
    }

    .homepage-grave_second-content_main-first_images-orange {
        bottom: -12%;
    }

    .homepage-grave_second-content_main-first_text {
        width: 100%;
        padding: 0px 16px 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .homepage-grave_second-content_main-first_text-header {
        font-size: 20px;
        line-height: 24px;
    }

    .homepage-grave_second-content_main-first_text-subheader {
        font-size: 14px;
        line-height: 20px;
    }

    .homepage-grave_second-content_main-first_text-button {
        align-self: center;
    }

    .homepage-grave_second-content_main-first.second {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
    }

    .homepage-grave_second-content_main-first_images-grey.second {
        right: 0 !important;
        top: -17% !important;
    }

    .homepage-grave_second-content_main-first_images-orange.second {
        left: -5%;
        bottom: -12%;
    }
}