.memories_comment {
  width: 100%;
}

.memories_comment-content {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #0000000d;
  padding: 16px 20px;
  gap: 12px;
}

.memories_comment-content_main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.memories_comment-content_main-white {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  /* padding: 12px; */
  margin-bottom: 10px;
}

.memories_comment-content_main-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 11px;
}

.memories_comment-content_main-header_user {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #36434b;
}

.memories_comment-content_main-header > a {
  text-decoration: none;
}

.memories_comment-content_main-header_time {
  margin-left: auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
}

.memories_comment-content_main-header_dots {
  min-width: 0px !important;
  width: 41px !important;
}

.memories_comment-content_main-text {
  width: 100%;
}

.memories_comment-content_main-text > span {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #36434b;
  word-break: break-word;
}

.memories_comment-content_main-options {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 20px;
  margin-bottom: 5px;
}

.memories_comment-content_main-options_button {
  min-height: 0px !important;
  min-width: 0px !important;
  height: 20px;
  width: 50px !important;
  text-transform: none !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #777777 !important;
}

.memories_comment-content_main-options_button:first-child {
  margin-right: 18px;
}

.memories_comment-content_main-options_button-image {
  margin-right: 2.5px;
}

.memories_comment-content_main-options > span {
  margin-left: auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #777777;
  cursor: pointer;
}

.music-post_content-title_form {
  display: flex;
  flex-direction: column;
}

.music-post_content-title_textarea_editing {
  resize: none;
  outline: none;
  border-radius: 6px;
  border: 1px solid #9aa8b2;
  background: #fff;
  color: #777;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  background: white;
  overflow-y: scroll;
  max-height: 170px;
  cursor: text;
  overflow-y: scroll;
  overflow-wrap: break-word;
  background: #ffff;
  padding: 10px;
  margin-bottom: 12px;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 9px 14px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 8px 14px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}
