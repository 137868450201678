.countries-popover_content {
    padding: 12px 13px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.countries-popover_content-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.countries-popover_content-header > input {
    width: 100%;
    border: none;
    outline: none;
    background: #F5F5F5;
    border-radius: 8px;
    height: 36px;
    box-sizing: border-box;
    padding: 0px 10px 0px 37px;
    background-image: url('../../../img/SearchImage.svg');
    background-repeat: no-repeat;
    background-position: 5%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 10px;
}

.countries-popover_content-line {
    width: 100%;
    border: 1px solid #F5F5F5;
    height: 0px;
    background-color: #F5F5F5;
    margin-bottom: 15px;
}

.countries-popover_content-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.countries-popover_content-header_country {
    display: flex !important;
    justify-content: flex-start !important;
    text-transform: none !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 15px !important; 
    color: #36434B !important;
    text-align: left !important;
}

.countries-popover_content-header_country:hover {
    font-family: 'Roboto Medium' !important;
    font-style: normal !important;
    color: #36434B !important;
}

.countries-popover_content-header_country-dial {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #C9CACB;
    margin-left: 5px;
}

.countries-popover_content-header_country.chosen {
    font-family: 'Roboto Medium' !important;
}

.countries-popover_content-header_country-dial.chosen {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #36434B;
}