.paid-container {
  display: flex;
  flex-direction: column;
}

.detail {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}

.title {
  color: #9aa8b2;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  min-width: 200px;
}

.value {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
}

@media screen and (max-width: 430px) {
  .title {
    min-width: auto;
  }
  .detail {
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
  }
}

@media screen and (max-width: 720px) {
  .title {
    min-width: auto;
  }
  .detail {
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (min-width: 721px) and (max-width: 900px) {
  .detail {
    width: auto;
  }
}

@media screen and (min-width: 901px) and (max-width: 1300px) {
  .title {
    min-width: auto;
  }
  .detail {
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (min-width: 1301px) {
  .detail {
    width: auto;
  }
}
