/* .menu-section {
  display: none;
} */

.container {
  padding: 18px;
  display: flex !important;
  align-items: center !important;
  gap: 42px;
  background-color: #fff;
  margin-bottom: 16px;
  padding-bottom: 0px;
  overflow-x: auto;
  position: sticky;
  top: 64px;
  z-index: 10;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.menu-item {
  padding-bottom: 16px;
  transition: ease 0.2s;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

.active {
  border-color: #985000;
}

.item {
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
  line-height: 21.09px;
  color: #985000;
}

@media screen and (min-width: 821px) {
  .menu-section {
    display: inline;
  }
  .container {
    top: 90px;
    border-radius: 10px;
  }
}
