.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.header > span {
  color: #19262d;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
}

.header > img {
  cursor: pointer;
  transition-duration: 0.3s;
}

.header > img:hover {
  opacity: 0.6;
}

.sub-heading {
  color: #19262d;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: center;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
}
