.comments {
  font-family: 'Roboto';
  font-size: 13px;
  color: hsl(0deg 0% 50%);
  cursor: pointer;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  transition: all ease 0.3s;
}

.comments:hover {
  border-color: hsl(0deg 0% 50%);
}
