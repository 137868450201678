.learn-more_third {
    width: 100%;
    margin-bottom: 88px;
}

.learn-more_third-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.learn-more_third-content_empty {
    min-width: 20%;
    display: flex;
    justify-content: center;
}

.learn-more_third-content_images {
    width: 100%;
    position: relative;
}

.learn-more_third-content_images-claim {
    width: 100%;
}

.learn-more_third-content_images-bottom {
    position: absolute;
    bottom: -15%;
    left: -10%;
    z-index: -1;
    width: 45%;
}

.learn-more_third-content_images-upper {
    position: absolute;
    top: -8%;
    right: 15%;
    width: 14%;
}

@media(max-width: 900px) {
    .learn-more_third-content {
        padding: 0;
        flex-direction: column-reverse;
    }

    .learn-more_second-content_main-text.third {
        margin-bottom: 60px;
    }

    .learn-more_third {
        width: 100%;
        margin-bottom: 168px;
    }

    .learn-more_third-content_empty {
        display: none;
    }
}