.passion_content-textarea {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

.select-roles-filter_form_control {
  height: 30px !important;
}

.select-roles-filter_form_control > div {
  display: flex !important;
  text-align: center !important;
  background: #fff !important;
  padding: 0px 25px 0px 10px !important;
}

.passion_content-textarea > textarea {
  width: 100%;
  border: 1px solid transparent;
  outline: none;
  resize: none;
  background-color: #f2f5f8;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.4;
  color: #36434b;
  height: 229px;
  background-image: url('../../../../img/Flowers.svg');
  background-repeat: no-repeat;
  background-size: 43px 60px;
  background-position: 99% 90%;
}

.passion_content-textarea > textarea:focus {
  border: 1px solid;
}

.passion_content-buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.passion_content-buttons_privacy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.passion_content-buttons_privacy > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #9f9f9f;
  margin-bottom: 6px;
}
