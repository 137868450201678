.cookies {
  position: fixed;
  z-index: 175;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 70px 21px 86px;
  background: #36434b;

  font-weight: 500;
  font-size: 14px;
}

.cookies .cookies_text {
  max-width: 768px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.cookies .cookies_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookies_controls_link {
  line-height: 16px;
  text-decoration-line: underline;
  color: #fea65a;
  margin-right: 30px;
}

.cookies_controls_button {
  width: 127px;
  height: 34px;
  padding: 9px 41px;
  background: #fea65a;
  border-radius: 4px;
  border: none;
  outline: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.cookies_controls_button,
.cookies_controls_link {
  cursor: pointer;
}


@media screen and (max-width: 700px) {
    .cookies {
        padding: 10px;
        font-size: 12px;
    }
    .cookies .cookies_controls {
        flex-direction: column;
    }
    .cookies_controls_link {
        margin: 0;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 700px) {
    .cookies {
        padding: 10px;
        font-size: 12px;
    }
    .cookies .cookies_controls {
        flex-direction: column;
        padding-left: 5px;
    }
    .cookies_controls_link {
        margin: 10px 0 25px;
    }
}