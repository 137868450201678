.cancel-border-container {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #f23131;
  background: #fff;
  margin-bottom: 44px;
  position: relative;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.heading {
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
}

.cancel-subscription {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  background: #f23131;
  color: #fff;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  transition: ease 0.3s;
}

.cancel-subscription:hover {
  background-color: #d00202;
}

.description {
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 20px;
}

@media screen and (max-width: 765px) {
  .heading {
    color: #19262d;
    font-family: 'Roboto' Condensed;
    font-size: 25px;
    font-weight: 500;
  }
}
