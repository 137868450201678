.cropper-modal_content-input-image_image-input {
  display: none;
}

.cropper-modal_content-input-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.cropper-modal_content-input-image_image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.drop-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
  padding-top: 24px;
  background: #f2f5f8;
  margin-bottom: 24px;
  margin-top: 24px;
}

.upload-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.image-text-message {
  color: #55636b;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.upload-image-input {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.upload-image-text {
  color: #404d56;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}
