.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 500px;
  padding: 0 24px;
}

.icon {
  font-size: 200px !important;
  color: #008905;
}

.error {
  font-size: 200px !important;
  color: #ea9449;
}

.heading {
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
  line-height: 36.62px;
  text-align: center;
  margin-bottom: 14px;
}

.description {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 25.68px;
  text-align: center;
}

.login {
  cursor: pointer;
  color: #ea9449;
}
