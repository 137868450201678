.question-box-container {
  min-height: 115px;
  background: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
  margin: 35px 0px;
  width: 94%;
}

.comments-count-collapsed, .comments-count-expanded{
  text-align: center;
  padding: 10px 0px;
  cursor: pointer;
  font-size: 18px;
}

.comments-count-collapsed:hover {
  background-color: #fea65a;
  opacity: 0.8;
  color: #fff;
  border-radius: 6px;
}

.comments-count-expanded:hover {
  background-color: #f5f5f5;
  opacity: 0.8;
  color: #36434B;
  border-radius: 6px;
}

.question-box-row {
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.question-box-row-flex-wrapper {
  display: flex;
}

.question-box-text-area-wrapper {
  display: flex;
  justify-content: center;
  margin: 10px 0px 0px;
  float: left;
  width: 93%;
  margin-left: 15px;
}

.cancel-edit {
  width: 100px;
  height: 35px;
  border-radius: 7px;
  border: none;
  font-size: 17px;
  font-family: "Roboto";
  background: #f5f5f5;
  cursor: pointer;
}

.save-edit {
  width: 100px;
  height: 35px;
  border-radius: 7px;
  border: none;
  color: #fff;
  font-size: 17px;
  font-family: "Roboto";
  background: #fea65a;
  margin-left: 15px;
  cursor: pointer;
}

.edit-actions-button {
  display: flex;
  justify-content: center;
}

.video-player-wrapper {
  width: 80%;
  height: 400px;
  margin: auto;
}

@media (max-width: 700px) {
  .question-box-container {
    padding: 15px;
    width: 88%;
    margin: 35px auto;
  }

  .video-player-wrapper {
    height: 250px;
    width: unset;
  }
}

@media (min-width: 700px) and (max-width: 950px) {
  .question-box-container {
    padding: 15px;
    width: 70%;
    margin: 35px auto;
  }

  .video-player-wrapper {
    height: 250px;
    width: unset;
  }
}
