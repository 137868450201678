.auth-welcome_input {
  width: 100%;
}

.countries-arrow_popover {
  margin-left: 4px;
}

.phone-number-container {
  display: flex;
  align-items: center;
}

.create-membership_first-input_block-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 6px;
  background: #f5f5f5;
  border: 1px solid transparent !important;
}

.create-membership_first-input_block-inputs:hover {
  border-color: #36434b !important;
}

.create-membership_first-input_block-inputs input {
  height: 40px;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: #36434b;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  background: #f5f5f5;
  width: 100%;
}

.create-membership_first-input_block-inputs input:disabled {
  color: #8888;
}

.create-membership_first-input_block-inputs_button {
  height: 40px !important;
  min-width: 75px !important;
  font-size: 16px !important;
  color: #36434b !important;
}

.create-membership_first-input_block-inputs_button:hover {
  background-color: transparent !important;
}

.create-membership_first-input_block-inputs_button:active {
  background-color: transparent !important;
}

.create-membership_first-input_block-inputs_button:focus-visible {
  background-color: transparent !important;
  outline: none;
}

.verify-now {
  font-size: 14px;
  color: #fea65a;
  margin-right: 10px;
  cursor: pointer;
}

.loading-container-invites {
  display: flex;
  align-items: center;
  gap: 8px;
}

.loading-container-invites > span {
  color: #404d56;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 20px;
  margin-top: 8px;
}

.loading-container > span {
  color: #404d56;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.desktop {
  display: flex;
}

.mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }
}
