.cropper {
  width: 100% !important;
  overflow: hidden;
  margin-bottom: 24px;
}

.cropper > img {
  width: 100% !important;
  opacity: 1 !important;
}

.btn {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
