.autocomplete {
  background-color: #f2f5f8;
  width: 100%;
  border-radius: 8px;
  position: relative;
}

.searchInput {
  width: 100%;
  display: flex;
}

.input {
  flex: 1 auto;
  min-width: 0;
  outline: none;
  border: none;
  background: #f2f5f8;
  font-size: 18px;
  padding: 21px 27px;
  color: #777777;
  border-radius: 8px;
}

.input::-webkit-input-placeholder,
.input:-ms-input-placeholder,
.input::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #777777;
  width: 100%;
}
.suggestionsModel {
  background: #f2f5f8;
  height: max-content;
  border-radius: 0px 0px 10px 10px;
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.autocomplete-active {
  font-size: 16px;
  padding: 10px 28px;
  cursor: pointer;
  text-align: start;
  font-family: 'Roboto';
  font-weight: 400;
  background-color: #cbcbcb;
  color: #fff;
}

.list {
  list-style: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.listItem:hover {
  background: #cbcbcb;
  color: #fff;
}

.listItem {
  font-size: 16px;
  padding: 10px 28px;
  cursor: pointer;
  text-align: start;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  color: #777777;
}
