.gift-membership-card {
  border-radius: 10px;
  border: 1px solid rgba(154, 168, 178, 0.2);
  background: #fafafa;
  padding: 24px;
}

.plan-name {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 25px;
  font-weight: 500;
}

.gifted-member-name {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.price {
  color: #565560;
  font-family: 'Roboto';
  font-size: 23px;
  font-weight: 400;
}

.price > span {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 32px;
  font-weight: 500;
}
