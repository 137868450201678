.subscribe-btn {
  width: 100%;
  margin-bottom: 24px;
}

.subscribe-btn > button {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #404d56;
  border-radius: 10px;
  color: #fbfbfc;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 18px;
  line-height: 170%;
  padding: 12px 0px;
}

.subscribe-btn > button:disabled {
  background: #cbcbcb !important;
  cursor: not-allowed;
}
