.container {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 12px;
  margin-bottom: 12px;
}

.created-at,
.reaction,
.reply {
  font-size: 12px;
  font-family: 'Roboto';
  color: #777777;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  transition: all ease 0.3s;
  cursor: pointer;
}

.created-at:hover,
.reaction:hover,
.reply:hover {
  border-color: #777777;
}
