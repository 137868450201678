.grave-information_content {
  background: #fff;
}

.form-control-block {
  margin-bottom: 32px;
}

.form-control-block .label {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
}

.grave-information_content-input-name-block .info-block {
  background: #f5f5f5;
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 8px;
}

.grave-information_content-input-name-block .info-block .user-name {
  color: #777;
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 18px;
}

.grave-information_content-input-name-block .info-block .warning-note {
  background: #fff;
  padding: 24px 14px;
  color: #777;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}

.grave-information_content-input-name-block .info-block button {
  /* width: 180px;
  height: 48px; */
  padding: 15px 33px;
  background: #fff;
  border: 1px solid #fea65a;
  border-radius: 8px;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: none;
  cursor: pointer;
}

.grave-information_content-input-name-block .have-nick-name {
  display: flex;
  justify-content: flex-end;
}

.grave-information_content-input-name-block .have-nick-name .nick-name-link {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #fea65a;
  cursor: pointer;
}

.grave-information_content-input-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 11px;
}

.grave-information_content-input-name-block > input {
  outline: none;
  border-radius: 8px;
  background: #f2f5f8;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  padding: 18px 28px;
  border: 1px solid transparent;
}

.grave-information_content-input-name-block > input:focus {
  border: 1px solid;
}

.selector {
  width: 100%;
  background: #f2f5f8;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  padding: 18px 28px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}

.selector:focus {
  border: 1px solid;
}

.grave-information_content-input-gender .gender-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
}

.grave-information_content-input-birth {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.grave-information_content-input-birth > input[type='text'],
.grave-information_content-input-death > input[type='text'] {
  border: none;
  outline: none;
  width: 96px;
  height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
  background: #f5f5f5;
  border-radius: 10px;
  text-align: center;
}

.grave-information-date-piker-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

.grave-information_content-title-birth,
.grave-information_content-title-death {
  width: 100%;
}

.grave-information_content-button_location-of-birth,
.grave-information_content-button_location-of-death,
.grave-information_content-button_reason-of-death {
  display: flex;
  width: 100%;
  margin-bottom: 14px;
}

.grave-information_content-input_location-of-birth,
.grave-information_content-input_location-of-death,
.grave-information_content-input_reason-of-death {
  display: flex;
  width: 100%;
  margin-bottom: 22px;
}

.locations {
  display: flex;
  gap: 16px;
}

.locations .birth-place,
.locations .death-place {
  flex: 1;
}

.birth-place input[type='text'],
.death-place input[type='text'],
.grave-information_content-input_reason-of-death > input[type='text'] {
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  padding: 13px 18px 11px 18px;
  background: #f5f5f5;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
}

.grave-information_content-input-death {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.grave-information_content-input_add-name {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.grave-information_content-input_add-name > input {
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 12px 10px;
  width: 100%;
  background: #f5f5f5;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
  margin-bottom: 8px;
}

.btn-all-relations {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #9f9f9f;
  margin-left: 5px;
  text-transform: none;
}

.grave-information_content-input_add-name > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.region {
  display: flex;
  align-items: center;
  gap: 16px;
}

.region .country,
.region .muncipality,
.region .cemetery {
  flex: 1;
}

.grave-information_content-title-cemetry,
.grave-information_content-title-country {
  display: flex;
  width: 100%;
  margin-bottom: 17px;
}

.grave-information_content-title-cemetry > span,
.grave-information_content-title-country > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #36434b;
}

.grave-information_content-municipality,
.grave-information_content-country {
  display: flex;
  width: 100%;
  margin-bottom: 17px;
}

.grave-information_content-cemetry {
  display: flex;
  width: 100%;
  margin-bottom: 17px;
}

.grave-information_content-subtitle-cemetry .input-list {
  display: flex;
  align-items: center;
  gap: 16px;
}

.grave-information_content-subtitle-cemetry .input-list input {
  border: none;
  outline: none;
  width: 96px;
  height: 40px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
  background: #f5f5f5;
  border-radius: 10px;
  text-align: center;
}

.grave-information_content-cemetry-disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.grave-information_content-cemetry-disabled > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #3b3b3b;
  margin-left: 9px;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.grave-information_content-privacy-settings {
  display: flex;
  flex-direction: column;
}

.grave-information_content-privacy-settings_header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #36434b;
  margin-bottom: 9px;
}

.grave-information_content-privacy-settings_subheader {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  color: #9f9f9f;
}

.grave-information_content-privacy-settings_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.grave-information_content-privacy-settings_input > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #36434b;
  margin-right: 12px;
}

.grave-information_content-privacy-settings_input > input {
  background: #f5f5f5;
  border-radius: 10px 0px 0px 10px;
  width: 176px;
  height: 37px;
  border: none;
  outline: none;
  background-image: url('../../../../img/PhoneNumber.svg');
  background-repeat: no-repeat;
  background-position: 10%;
  box-sizing: border-box;
  padding: 9px 37px 9px 47px;
}

.grave-information_content-privacy-settings_phone-numbers {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.privacy {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: max-content;
}

.grave-information_content-privacy-settings_phone-numbers_phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  background-color: #ffedde;
  border-radius: 10px;
  min-width: 130px;
  width: max-content;
  height: 40px;
  box-sizing: border-box;
  padding: 0px 14px;
  margin-bottom: 40px;
}

.grave-information_content-privacy-settings_phone-numbers_phone > span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fea65a;
}

.grave-information_content-privacy-settings_phone-numbers_phone
  > img:last-child {
  margin-left: 5px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.grave-information_content-privacy-settings_phone-numbers_phone
  > img:last-child:hover {
  opacity: 0.4;
}

.grave-information_content-privacy-settings_phone-numbers_phone:last-child {
  margin-right: 0px;
}

.grave-information_content-privacy-settings_phone-numbers_phone.hide {
  opacity: 0;
  height: 0px !important;
  width: 0px !important;
  min-width: 0px !important;
  margin: 0px;
  padding: 0px;
}

.grave-information_content-input-birth_month {
  background: #f5f5f5 !important;
  border-radius: 10px !important;
  width: 96px !important;
  height: 40px !important;
  border: none !important;
  outline: none !important;
  appearance: none !important;
  text-align-last: center !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #36434b !important;
}

@media (max-width: 800px) {
  .grave-information {
    width: 100%;
    border-radius: 0px;
    border-left: none;
  }

  .region {
    flex-direction: column;
    gap: 0px;
  }

  .region .country,
  .region .muncipality,
  .region .cemetery {
    flex: 0;
    width: 100%;
  }

  .grave-information_content-input-gender .gender-buttons {
    flex-direction: column;
  }

  .grave-information-date-piker-container {
    flex-direction: column;
    gap: 0px;
  }

  .grave-btn-container {
    padding-bottom: 12px;
  }

  .locations {
    flex-direction: column;
    gap: 0px;
  }

  .locations .birth-place,
  .locations .death-place {
    flex: 0;
    width: 100%;
  }

  .grave-information_content-verify {
    flex-direction: column;
  }

  .grave-information_content-input-birth,
  .grave-information_content-input-death {
    display: flex;
    align-items: center;
    gap: 0px;
  }

  .grave-information_content-input-birth > input[type='text'],
  .grave-information_content-input-death > input[type='text'] {
    width: 107px;
    margin-left: 0px;
    box-sizing: border-box;
  }
}

@media (min-width: 600px) {
  .input-group {
    display: flex;
    gap: 12px;
  }
}
