.image-container {
  width: 100%;
  height: 350px;
  border-radius: 6px;
  margin-bottom: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.selected-image {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: contain;
  object-position: center;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.selected-video {
  width: 100%;
  height: 100%;
}

.remove-image {
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: 0.3s ease;
  padding: 4px;
}

.remove-image:hover {
  background-color: #cccc;
}
