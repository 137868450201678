.homepage-scientists_first {
    width: 100%;
    margin-top: -22px;
}

.homepage-scientists_first-content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.homepage-scientists_first-content_text {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: 0;
    margin-left: 70px;
}

.homepage-scientists_first-content_text-header {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.07em;
    color: #36434B;
    margin-bottom: 18px;
}

.homepage-scientists_first-content_text-header > span, .homepage-scientists_first-content_text-subheader > span {
    color: #FEA65A;
}

.homepage-scientists_first-content_text-subheader {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.025em;
    color: #36434B;
    opacity: 0.8;
    margin-bottom: 18px;
}

.homepage-scientists_first-content_text-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.homepage-scientists_first-content_text-input > input {
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 13px 12px;
    outline: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    width: 360px;
}

.homepage-scientists_first-content_text-input_button {
    width: 153px !important;
    height: 46.67px !important;
    background: #FEA65A !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161) !important;
    border-radius: 10px !important;
    text-transform: none !important;
    font-family: 'Roboto Medium' !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #FFFFFF !important;
    margin-left: -37px !important;
    cursor: pointer;
}

.homepage-scientists_first-content_image-mobile, .homepage-scientists_first-content_text-header.mobile {
    display: none;
}

.homepage-scientists_first-content_image {
    width: 72%;
    z-index: -1;
}

@media (max-width: 1120px) {
    .homepage-scientists_first-content_text {
        margin-left: 40px;
        width: 450px;
    }
}

@media (max-width: 1010px) {
    .homepage-scientists_first-content_text {
        width: 400px;
        margin-left: 20px;
    }
}

@media (max-width: 900px) {
    .homepage-scientists_first-content {
        align-items: center;
    }

    .homepage-scientists_first-content_image {
        width: 100%;
    }

    .homepage-scientists_first-content_text {
        position: relative;
        align-self: flex-start;
        margin-left: 0px;
        background-color: #F5F5F5;
    }

    .homepage-scientists_first-content_text-header {
        display: none;
    }

    .homepage-scientists_first-content_text-header.mobile {
        display: block;
        text-align: center;
        top: 12%;
        width: 90%;
    }

    .homepage-scientists_first-content_text-subheader {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 25px;
    }

    .homepage-scientists_first-content_text {
        width: 100%;
        box-sizing: border-box;
        padding: 30px 16px 0px;
        margin-top: -15px;
    }

    .homepage-scientists_first-content_text-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .homepage-scientists_first-content_text-input_button {
        margin-left: -0px !important;
    }

    .homepage-scientists_first-content_text-input > input {
        width: 100%;
        margin-bottom: 20px;
    }
}  

@media (max-width: 680px) {
    .homepage-scientists_first-content_image {
        display: none;
    }

    .homepage-scientists_first-content_image-mobile {
        display: block;
        width: 100%;
    }
}