.container {
  background-color: #f2f5f8;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 24px;
  position: relative;
  margin-bottom: 8px;
  min-height: 30px;
}

.user-avatar {
  position: absolute;
  top: -45px;
  border: 5px solid #fff;
  border-radius: 50%;
}

.avatar {
  height: 100px !important;
  width: 100px !important;
}

.sub-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  margin-top: 52px;
  /* margin-left: 125px; */
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  font-family: 'Roboto' Condensed;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  color: #404d56;
}

.user-confirmation {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Roboto';
  font-size: 16px;
  color: #404d56;
  border-radius: 20px;
  background-color: #94f302;
  padding: 6px 24px;
}

.country {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #404d56;
}

.color-section {
  display: none;
}

@media screen and (min-width: 821px) {
  .container {
    background-color: #fff;
    min-height: 58px;
    border-radius: 0px 0px 20px 20px;
  }

  .sub-container {
    justify-content: space-between;
    margin-left: 0px;
    flex-direction: row;
    gap: 12px;
    margin-top: 0px;
  }

  .user-info {
    margin-left: 200px;
    align-items: flex-start;
  }

  .user-avatar {
    top: -105px;
    left: 16px;
  }

  .color-section {
    display: inline;
    height: 100px;
  }

  .name {
    font-size: 22px;
    font-weight: 700;
    line-height: 37.5px;
  }

  .country {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.09px;
  }

  .avatar {
    height: 180px !important;
    width: 180px !important;
  }
}
