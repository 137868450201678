.workplace-names {
  margin-bottom: 12px;
}

.workplace-names-form {
  margin-bottom: 42px;
}

.add-button {
  border-radius: 10px;
  border: 1px solid #fea65a;
  background: #fff;
  padding: 14px;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}

.search-input {
  display: flex;
  margin-bottom: 24px;
  background: #f2f5f8;
  border-radius: 8px;
}

.search-input > img {
  margin-left: 12px;
}

.inputs {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 17px 13px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
  flex: 1;
}

.grave-btn-container,
.grave-actions-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.grave-information-edit-delete-container {
  display: flex;
  gap: 24px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.action-btn > button {
  border: none;
  background: none;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #fea65a;
  background: #fff;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.rootDiscard:disabled {
  background: #cbcbcb !important;
  cursor: not-allowed;
}

.empty-workplaces-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.empty-workplace-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.empty-workplace-wrapper > span {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 34.5px;
}

.empty-workplace-wrapper > button {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 700;
  line-height: 34.5px;
  border: none;
  cursor: pointer;
  background: #fff;
}

@media (max-width: 800px) {
  .workspace {
    width: 100%;
    border-radius: 0px;
    border-left: none;
  }

  .grave-btn-container {
    display: inline;
  }

  .grave-information_content-verify {
    flex-direction: column;
  }
}
