.container {
  background-color: #fff;
  padding: 12px;
  width: max-content;
  position: absolute;
  left: 50px;
  top: 150px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  display: none;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  color: #404d56b2;
  cursor: pointer;
}

.menu {
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  color: #fea65a;
  background: #fff9f3;
  border-radius: 10px;
}

@media screen and (min-width: 821px) {
  .container {
    display: inline;
  }
}
