.grave-picture-gallery_photos-photo {
  height: 200px;
  cursor: pointer;
  position: relative;
  background: #fff;
}

.video-player {
  height: 100% !important;
  width: 100% !important;
  cursor: pointer;
  background-color: #000;
}

.empty-image {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
}

.empty-image > button {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.6;
}

.empty-image > button:hover {
  opacity: 1;
}

.grave-picture-gallery_photos-navigations_dot {
  min-width: 0px !important;
}

.buttons-prev {
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 6px 6px 0px;
  border: none;
  min-width: 0;
  width: 30px;
  height: 45px;
  position: absolute;
  top: 40%;
  left: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.buttons-next {
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px 0px 0px 6px;
  border: none;
  min-width: 0;
  width: 30px;
  height: 45px;
  position: absolute;
  top: 40%;
  right: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.show-buttons {
  opacity: 1;
}

.dots-container {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dots-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dots-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
