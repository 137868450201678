.container {
  margin-bottom: 10px;
}
.header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
}

.title {
  font-family: 'Roboto' Condensed;
  font-size: 25px;
  font-weight: 500;
  line-height: 29.3px;
  color: #19262d;
}

.list {
  border-radius: 40px;
  background: #f2f5f8;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 2px 12px;
  cursor: pointer;
}

.icon {
  color: #19262d !important;
}

.item {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #19262d;
  text-transform: capitalize;
}

.description {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: #404d56;
}
