.delete-virtue_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.delete-virtue_content-header > span {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 18px;
  font-weight: 400;
}
