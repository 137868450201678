.comment-textarea_music-player_player {
  box-shadow: none !important;
}

.comment-textarea_music-player {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.comment-textarea_music-player_player .rhap_controls-section,
.comment-textarea_music-player_player .rhap_time.rhap_current-time,
.comment-textarea_music-player_player .rhap_progress-indicator {
  display: none !important;
}

.comment-textarea_music-player_player .rhap_progress-filled {
  background-color: #fea65a !important;
}

.comment-textarea_music-player_button-image {
  width: 9px;
  height: 12px;
  margin-right: -2px;
}

.comment-textarea_music-player_button {
  min-width: 0px !important;
  min-height: 0px !important;
  width: 29px !important;
  height: 25px !important;
  border-radius: 50% !important;
  background-color: #fea65a !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px !important;
}

.comment-textarea_music-player_button-secs {
  min-width: 0px !important;
  min-height: 0px !important;
  padding: 0px !important;
  margin-left: 10px !important;
}
