.submission-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 265px);
  flex-direction: column;
}

.image {
  margin-bottom: 44px;
}

.heading {
  font-family: 'Roboto' Condensed;
  font-size: 31px;
  font-weight: 500;
  line-height: 37px;
  text-align: center;
  color: #19262d;
  margin-bottom: 14px;
}

.sub-heading {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #404d56;
  margin-bottom: 44px;
}

.btn-container {
  display: flex;
  align-items: center;
  gap: 44px;
  flex-wrap: wrap;
}

.dashboard {
  border: none;
  background-color: #e4f3fd;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 6px;
  padding: 8px 16px;
  color: #404d56;
  text-transform: uppercase;
  cursor: pointer;
}
