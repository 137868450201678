.deceased-connection {
  padding: 16px;
}

.set-relation-info {
  border-radius: 10px;
  background: #fff;
  padding: 24px;
}

@media screen and (min-width: 960px) {
  .deceased-connection {
    padding: 32px;
    width: 60%;
    margin: auto;
  }
}
