.reactions {
  font-family: 'Roboto';
  font-size: 13px;
  color: hsl(0deg 0% 50%);
  font-weight: 500;
}

.container {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.reaction-list {
  display: flex;
  align-items: center;
}

.inspire,
.reflect,
.enlighten {
  cursor: pointer;
  height: 10px !important;
  padding: 4px;
  width: 10px !important;
  border-radius: 50%;
}

.inspire {
  background: #ffd580;
}

.enlighten {
  background: #add8e6;
}

.reflect {
  background: #98fb98;
}
