.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  transition: ease 0.3s;
  background-color: transparent;
  position: relative;
  padding: 12px;
  border-radius: 20px;
  background-color: #f2f5f8;
}

.user-name {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: 1.3;
}

.user-relation {
  color: #8d9093;
  font-family: 'Roboto';
  font-size: 14px;
}

.user-role {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 1.3;
}

.remove-btn {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  position: absolute;
  top: 8px;
  right: 12px;
}

.remove-btn:hover {
  background-color: #cccc;
}
