.list {
  border-radius: 40px;
  background: #f2f5f8;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 2px 10px;
  cursor: pointer;
}

.icon {
  color: #19262d !important;
  font-size: 16px !important;
}

.item {
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  line-height: 16.41px;
  color: #19262d;
  text-transform: capitalize;
}

.menu-item {
  text-transform: capitalize;
  font-size: 0.8rem !important;
}

@media screen and (min-width: 821px) {
  .item {
    font-size: 14px;
  }
  .icon {
    font-size: 20px !important;
  }
  .list {
    padding: 2px 10px;
  }
  .menu-item {
    font-size: 1rem !important;
  }
}
