.menu-item {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 8px;
  cursor: pointer;
}

.item {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  line-height: 23.44px;
  color: #36434b;
}

@media screen and (min-width: 821px) {
  .container {
    display: none;
  }
}
