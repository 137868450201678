.media-tab {
  border-radius: 5px;
  background: #fff;
  padding: 12px;
  cursor: pointer;
}

.media-tab:hover {
  background-color: #f2f5f8;
}

.media-tab-selected {
  border-radius: 5px;
  background: #ffc796;
  padding: 12px;
  cursor: pointer;
}

.tab-btn {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #404d56;
  font-family: 'Roboto' Condensed;
  font-size: 25px;
  font-weight: 500;
}

.tab-btn-selected {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 25px;
  font-weight: 500;
}
