.message {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 18px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.image {
  position: absolute;
  right: 24px;
  top: 24px;
  transition-duration: 0.3s;
}

.workspace-content_header > img:hover {
  opacity: 0.6;
}
