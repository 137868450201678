.tabs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.connect-btn-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.connect-btn {
  border: none;
  background: none;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

@media (max-width: 750px) {
  .MuiTabScrollButton-root {
    display: none !important;
  }

  .connect-btn {
    display: none;
  }
}
