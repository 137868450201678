.payment-details {
  background-color: #fff;
  margin-bottom: 24px;
}

.card-information {
  margin-bottom: 40px;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
