.comment-on-toogle {
  margin-bottom: 24px;
}

.comment-on-toogle_tabs {
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 0 0 10px 10px;
}

@media (max-width: 750px) {
  .comment-on-toogle_tabs {
    width: calc(95% - 30px) !important;
  }
}
