.relationship-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 24px;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 150px;
  text-align: center;
}

.user-name {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: 1.3;
}

.user-relation {
  color: #8d9093;
  font-family: 'Roboto';
  font-size: 14px;
}

.user-role {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 1.3;
}

.empty-grave-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(300px - 24px);
}

.empty-message {
  color: #19262d;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.add-btn {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
}
