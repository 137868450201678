.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.user-avatar {
  height: 180px !important;
  width: 180px !important;
  border-radius: 10px !important;
}

.user-name {
  text-align: center;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  color: #36434b;
  margin: 24px 0px;
}

.connection-container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.connection {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fill-dot {
  height: 10px;
  width: 10px;
  background-color: #404d56;
  border-radius: 50%;
}

.line {
  height: 50px;
  width: 3px;
  background-color: #404d56;
}

.dot {
  height: 5px;
  width: 5px;
  border: 3px solid #404d56;
  border-radius: 50%;
}

.relation {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #404d56;
}

.user-relation-detail {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin: 24px 0px;
}

@media screen and (min-width: 700px) {
  .container {
    flex-direction: row;
  }
  .connection-container {
    flex-direction: column;
  }

  .connection {
    flex-direction: row;
  }
  .line {
    height: 3px;
    width: 70px;
  }
}
