.scientists-card {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161);
    max-width: 274px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 23px;
    z-index: 1;
}

.scientists-card_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 22px;
    margin-bottom: 45px;
}

.scientists-card_header > img {
    width: 40px;
    height: 40px;
}

.scientists-card_header > span {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #36434B;
    margin-left: 25px;
} 

.scientists-card > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #36434B;
}

@media (max-width: 800px) {
    .scientists-card {
        max-width: 100%;
        width: 100%;
        min-height: auto !important;
        padding: 0px 18px 30px 20px;
    }

    .scientists-card_header {
        margin-top: 32px;
        margin-bottom: 38px;
    }
}