.learn-more {
    max-width: 1280px;
    margin: 0px auto;
    /* overflow-x: hidden; */
}

.learn-more_road {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url('../../../img/LearnMoreDashedInfo.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    background-size: 13% 95%;
}

@media (max-width: 900px) {
    .learn-more_road {
        background-size: contain;
    }
}