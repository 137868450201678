.login-form {
  padding: 32px;
}
.forgot {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #777777;
  margin-bottom: 32px;
  cursor: pointer;
  width: max-content;
}

.invitation-message {
  border-radius: 10px;
  padding: 16px;
  margin: 24px 0px;
  background-color: #ddffde;
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.invitation-error-message {
  border-radius: 10px;
  padding: 16px;
  margin: 24px 0px;
  background-color: lightgoldenrodyellow;
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.message {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #36434b;
  margin-bottom: 12px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 820px) {
  .login-form {
    box-shadow: 0px 2px 16px 5px #0000000d;
    border-radius: 10px;
    width: 700px;
  }
}
