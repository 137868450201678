.icon-button {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  cursor: pointer;
}

.icon-button:hover {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.161);
}

.icon-button_icon {
  color: #FEA65A;
}

@media screen and (max-width: 470px){
  .icon-button {
    margin: 0 5px;
    width: 40px;
    height: 40px;
  }
}