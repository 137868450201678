.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 32px;
  border-radius: 10px;
  max-height: 90vh;
  overflow-y: auto;
  background: #fff;
}

.search-input {
  display: flex;
  margin-bottom: 24px;
}

.inputs {
  border-radius: 8px;
  background: #f2f5f8;
  padding: 17px 13px;
  color: #777;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  border: none;
  outline: none;
  flex: 1;
}

.workplace-names {
  margin-bottom: 12px;
}

.workplace-names > button {
  border-radius: 10px;
  border: 1px solid #fea65a;
  background: #fff;
  padding: 14px;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 170%;
  cursor: pointer;
}

.workspace-content_header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.workspace-content_header > span {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}

.workspace-content_header > img {
  cursor: pointer;
  transition-duration: 0.3s;
}

.workspace-content_header > img:hover {
  opacity: 0.6;
}

.workspace-content-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.workspace-content_subheader > span {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 800px) {
  .custom-modal {
    height: 100vh;
    width: 100%;
  }
}
