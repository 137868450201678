.richInput {
  width: 100%;
  border: none;
  background: #f5f5f5;
  text-align: start;
  transition: all ease 0.3s;
  resize: none;
  max-height: 240px;
  outline: none;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #080809;
  box-sizing: border-box;
  overflow-y: scroll;
  cursor: text;
  word-break: break-all;
}

/* Target the scrollbar itself */
.richInput::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Scrollbar thumb */
.richInput::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Add space around the thumb */
}

.container {
  position: relative;
  width: 100%;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  color: gray;
}

.btn-container {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 16px;
  background: #d8d8d8;
  padding: 6px;
  border-radius: 6px;
}

.richInput b {
  font-weight: 500 !important;
}

.richInput a {
  word-break: break-all;
  white-space: pre-wrap !important;
}
/* .wrapper {
  padding: 16px;

  .rich-input {
    cursor: pointer;
    outline: none;
    font-size: 14px;
    color: #232323;
    line-height: 1.5;
    word-break: break-all;

    img {
      width: 100%;
      height: auto;
    }

 

    p {
      margin: 0;
      word-break: break-all;
    }
  } */
/* } */
