.notes_content-textarea {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

.notes_content-textarea > textarea {
  width: 100%;
  border: 1px solid transparent;
  outline: none;
  resize: none;
  background-color: #f2f5f8;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 16px;
  color: #36434b;
  height: 229px;
}

.notes_content-textarea > textarea:focus {
  border: 1px solid;
}

.grave-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
}

.grave-information_content-verify {
  display: flex;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.disabledButton {
  cursor: not-allowed;
  border: none;
  border-radius: 8px;
  background-color: #ccc;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 16px 58px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 16px 58px;
  border-radius: 8px;
  border: 1px solid #fea65a;
  background: #fff;
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}
