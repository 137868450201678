.user-connections-container {
    max-width: 360px;
    min-height: 250px;
    background: #ffffff;
    border-radius: 15px;
    margin: 40px 6% 40px auto;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
    padding: 10px;
}

.user-connections_item {
    height: 160px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
}

.user-connections_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    margin-top: 10px;
}

.user-connections-header {
    width: 100%;
}

.user-connections-header_title>span {
    height: 15px;
    font-family: Minion Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 18px;
    color: #36434b;
}

.user-connections-container>hr {
    width: 75%;
    height: 0.5px;
    background: #c4c4c4;
}

.user_name {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #36434b;
}

.user_info {
    text-align: center;
    display: grid;
}

.user_relation {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #777777;
}

.user-connections-container>h1 {
    text-align: center;
    font-size: 20px;
    color: #fea65a;
    margin-top: 66px;
    display: flex;
}

.user-connections-header_title {
    display: flex;
    height: 50px;
    align-items: center;
    border-bottom: 1px solid #777;
    padding-left: 10px;
    padding-bottom: 7px;
}

@media (max-width: 750px) {
    .user-connections-container {
        max-width: 100%;
        margin: 15px 10px;
        padding: 10px;
    }

    .user-connections_grid {
        grid-template-columns: repeat(3, 30%);
        column-gap: 10px;
    }

    .user-connections_item {
        height: 170px;
    }

    .user-connections-header_title {
        padding-left: 10px;
    }
}

@media (min-width: 700px) and (max-width: 950px) {
    .user-connections-container {
        max-width: 70%;
        margin: 20px auto;
        padding: 10px;
    }

    .user-connections_grid {
        grid-template-columns: repeat(4, 23%);
        column-gap: 10px;
    }

    .user-connections_item {
        height: 170px;
    }

    .user-connections-header_title {
        padding-left: 10px;
    }
}