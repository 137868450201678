.faq-container {
  border-bottom: 1px solid #DCDBE0;
  padding-bottom: 42px;
  margin-bottom: 44px;
}

.faq-question {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #36434B;
}

.answer {
  margin-top: 44px;
}

.answer>span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 144.19%;
  color: #404D56;
}

@media (min-width : 630px) {
  .question {
    font-size: 24px;
    line-height: 28px;
  }

  .answer>span {
    font-size: 20px;
    line-height: 134.69%;
  }
}