.member-page {
  width: 100%;
  background-color: #F5F5F5;
  overflow-x: hidden;
}

.member-header_outer_container {
  background-color: #FFFFFF;
  /*padding: 20px 200px;*/
  /* display: grid;
  grid-template-columns: repeat(20, 5%);
  grid-template-rows: repeat(20, 25px); */
  margin: auto;
  max-width: 1040px;
}

.member-content_outer_container {
  display: flex;
}

.member-content_big_left {
  float: left;
  width: 43%;
  justify-content: flex-end;
  overflow-y: scroll;
}

.member-content_big_right {
  float: right;
    width: 40%;
    padding: 20px 29px 0px 50px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.member-header_placeholder {
  width: 100%;
  background-color: #FFFFFF;
  padding: 10px 0px 15px;
}

.unrelated-user {
  font-family: 'Minion Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 34px;
  text-align: center;
  color: #36434B;
}

@media (max-width: 950px) {
  .member-content_big_left {
    float: unset;
    width: unset;
    justify-content: unset;
    overflow-y: unset;
  }

  .member-content_outer_container {
    display: block;
  }

  .member-content_big_right {
    width: unset;
    margin: auto;
    padding: unset;
    overflow-y: unset;
  }
}