.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #f6ecd3;
  padding: 12px 22px;
  margin-bottom: 24px;
}

.link {
  color: #6f3c04;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  word-wrap: anywhere;
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
  }

  .link {
    width: 100%;
    margin-bottom: 12px;
  }
}
