.cover-image {
  position: absolute;
  height: 250px;
  width: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: 0;
  object-fit: cover;
  bottom: 122px;
}

@media screen and(min-width: 821px) {
  .cover-image {
    bottom: 130px;
  }
}
