.passion-content_header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.passion-content_header > span {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}

.passion-content_header > img {
  cursor: pointer;
  transition-duration: 0.3s;
}

.passion-content_header > img:hover {
  opacity: 0.6;
}

.passion-content_subheader {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}

.passion-content_subheader > span {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}
