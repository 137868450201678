.learn-more_third-content.fourth {
    flex-direction: row-reverse;
}

.learn-more_third-content_empty.fourth {
    min-width: 15%;
}

.learn-more_fourth {
    width: 100%;
    margin-bottom: 108px;
}

.learn-more_fourth-content_images {
    width: 100%;
    position: relative;
}

.learn-more_fourth-content_images > video {
    width: inherit;
    height: 715px
}

.added {
    margin-left: 181px!important;    
    width: 204px!important;
    height: 56px!important;
    background: #FEA65A!important;
    box-shadow: 0px 4px 10px rgba(254, 166, 90, 0.35)!important;
    border-radius: 8px!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    text-decoration: none!important;
    
    font-family: Roboto!important;
    font-style: normal!important;
    font-weight: normal!important;
    font-size: 16px!important;
    line-height: 19px!important;
    color: #FFFFFF!important;
}


@media (max-width: 900px) {
    .learn-more_third-content.fourth {
        flex-direction: column-reverse;
    }

    .learn-more_fourth {
        width: 100%;
        margin-bottom: 45px;
    }

    .added {
        margin: 0 auto!important;
    }
}