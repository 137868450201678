.grave-picture-gallery_photos-photo {
  width: auto;
  height: 600px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.gallery_photos-photo {
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
  object-fit: contain;
  object-position: center;
  backdrop-filter: blur(20px);
}

.grave-picture-gallery_photos-navigations_dot {
  min-width: 0px !important;
}

.buttons-prev {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 6px 6px 0px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 50%;
  left: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.buttons-next {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px 0px 0px 6px;
  border: none;
  min-width: 0;
  width: 36px;
  height: 71px;
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.dots-container {
  display: none;
}

.upload-cover-image {
  /* background: #e7e6e9;*/
  background: rgba(39, 47, 52, 0.5);
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 8px;
  width: max-content;
  transition: 0.2s;
}

.upload-cover-image:hover {
  background: rgba(39, 47, 52, 0.7);
}

.camera-title {
  color: #fff;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
}

.loading-image {
  position: absolute;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
  top: 50%;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.3);
}

.loading-image > span {
  color: #fea65a;
  font-size: 18px;
  font-style: Roboto;
  font-weight: 400;
}

@media screen and (max-width: 750px) {
  .buttons-prev,
  .buttons-next {
    height: 50px;
    width: 30px;
    top: 45%;
  }
  .grave-picture-gallery_photos-photo {
    height: 400px;
    border-radius: 0;
  }
  .loading-image {
    border-radius: 0;
  }
  .gallery_photos-photo {
    border-radius: 0;
  }
  .dots-container {
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    overflow-x: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .dots-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .dots-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media screen and (max-width: 480px) {
  .camera-title {
    display: none;
  }

  .upload-cover-image {
    padding: 8px;
    background: #000;
    border-radius: 50%;
    bottom: 10px;
    right: 15px;
  }
}
