.homepage-scientists_fifth {
    width: 100%;
    background-image: url('../../../../img/ScientistsLastBackground.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.homepage-scientists_fifth-content {
    width: 100%;
    box-sizing: border-box;
    padding: 146px 70px 59px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.homepage-scientists_fifth-content_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-bottom: 117px;
}

.homepage-scientists_fifth-content_header-text {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.homepage-scientists_fifth-content_header-text > img:first-child {
    margin-top: -35px;
}

.homepage-scientists_fifth-content_header-text > img:last-child {
    margin-bottom: -35px;
    transform: scale(-1, -1);
}

.homepage-scientists_fifth-content_header-text > span {
    font-family: Minion Pro;
    font-style: normal;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #36434B;
    margin: 0px 12px;
    max-width: 484px;
}

.homepage-scientists_fifth-content_header-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 52px;
    width: 100%;
}

.homepage-scientists_fifth-content_header-input > input {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.161);
    width: 100%;
    box-sizing: border-box;
    padding: 18px 110px 18px 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
}

.homepage-scientists_fifth-content_header-input_button {
    min-width: 180px !important;
    height: 55px !important;
    background: #FEA65A !important;
    border-radius: 10px !important;
    text-transform: none !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #FFFFFF !important;
    margin-left: -90px;
}

.homepage-scientists_fifth-content_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
}

.homepage-scientists_fifth-content_main-header {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #36434B;
    position: absolute;
    right: 80px;
    top: 11.6%;
    z-index: 1;
}

.homepage-scientists_fifth-content_main-header > span {
    color: #3ca0ff;
    text-transform: lowercase;
}

.homepage-scientists_fifth-content_main-first {
    display: flex;
    flex-direction: column;
}

.homepage-scientists_fifth-content_main-first > div:first-child {
    margin-bottom: 15px;
}

.homepage-scientists_fifth-content_main-first.last > div:first-child {
    margin-bottom: 0px;
}

.homepage-scientists_fifth-content_main-flower {
    position: absolute;
    right: 0;
    top: 33%;
    z-index: 1;
}

.homepage-scientists_fifth-content_main-flower.mobile {
    display: none;
}

.homepage-scientists_fifth-content_main-blocks {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media (max-width: 1240px) {
    .homepage-scientists_fifth-content_main-header {
        position: relative;
        align-self: center;
        margin-bottom: 20px;
    }

    .homepage-scientists_fifth-content_main-first > div {
        margin-bottom: 15px !important;
    }
}

@media (max-width: 800px) {
    .homepage-scientists_fifth-content_main-flower {
        display: none;
    }

    .homepage-scientists_fifth-content_main-flower.mobile {
        display: block;
        top: 80px;
        right: -40px;
    }

    .homepage-scientists_fifth-content_header {
        box-sizing: border-box;
        padding: 48px 16px 0px;
        margin-bottom: 0px;
    }

    .homepage-scientists_fifth {
        background-size: contain;
    }

    .homepage-scientists_fifth-content_main {
        background-color: #f5f5f5;
        padding-top: 90px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .homepage-scientists_fifth-content {
        padding: 0;
    }

    .homepage-scientists_fifth-content_main-first > div {
        margin-bottom: 20px;
    }

    .homepage-scientists_fifth-content_header-text {
        flex-direction: column;
        align-items: center;
    }

    .homepage-scientists_fifth-content_header-text > img:first-child {
        align-self: flex-start;
        margin: 0px;
    }

    .homepage-scientists_fifth-content_header-text > img:last-child {
        align-self: flex-end;
        margin: 0px;
    }

    .homepage-scientists_fifth-content_header-text > span {
        font-size: 20px;
        line-height: 30px;
        width: 90%;
    }

    .homepage-scientists_fifth-content_header-text > img {
        width: 24px;
        height: 20px;
    }

    .homepage-scientists_fifth-content_header-input {
        flex-direction: column;
        align-items: center;
    }

    .homepage-scientists_fifth-content_header-input > input {
        font-size: 14px;
        line-height: 16px;
        padding: 18px 10px 21px 10px;
        margin-bottom: 25px;        
    }

    .homepage-scientists_fifth-content_main-header {
        position: relative;
        right: auto;
        top: auto;
        margin-bottom: 51px;
        align-self: center;
    }

    .homepage-scientists_fifth-content_main-first.first > div:first-child {
        width: 77.3%;
    }
}