.verification-code-input {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.digit-input {
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.digit-input:focus {
  border-color: #fea65a;
  outline: none;
}
