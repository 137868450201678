.end-step_countries {
  width: 100% !important;
  background: #f5f5f5 !important;
  border-radius: 10px !important;
  border: none;
  outline: none;
  /* margin-bottom: 20px; */
  box-sizing: border-box;
  padding: 12px 10px !important;
  appearance: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.add-grave_autocomplete.mun_hint_shown .MuiAutocomplete-popupIndicatorOpen,
.add-grave_autocomplete.cem_hint_shown .MuiAutocomplete-popupIndicatorOpen,
.add-grave_autocomplete.mun_hint_shown .MuiAutocomplete-popupIndicator,
.add-grave_autocomplete.cem_hint_shown .MuiAutocomplete-popupIndicator {
  transform: none !important;
  margin-top: 5px;
  margin-right: 2px;
}

.end-step_autocomplete-with-hint {
  position: relative;
  width: 100%;
}

.end-step_autocomplete-with-hint .hint-message {
  top: -50px;
  right: -42%;
}
.end-step_autocomplete-with-hint .hint-message::after {
  right: 80%;
}

.end-step_autocomplete-with-hint.cemetery .hint-message {
  top: -60px;
}

.second-step-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
  margin-top: 33px;
  flex-wrap: wrap;
}

.first-step-back {
  border: 1px solid #404d56;
  padding: 16px 42px;
  background-color: transparent;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #404d56;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
}

.create-button {
  border: none;
  flex: 1;
  background-color: #404d56;
  padding: 17px 42px;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .end-step_autocomplete-with-hint .hint-message {
    top: -50px;
    right: -5px;
  }
  .end-step_autocomplete-with-hint .hint-message::after {
    right: 5px;
  }
}
