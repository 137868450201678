.inspired,
.reflected,
.enlightened {
  cursor: pointer;
  height: 18px;
  padding: 6px;
  width: 18px;
  border-radius: 50%;
}

.inspired-small,
.reflected-small,
.enlightened-small {
  cursor: pointer;
  height: 10px;
  padding: 4px;
  width: 10px;
  border-radius: 50%;
}

.inspired,
.inspired-small {
  background: hsl(27.8deg 98.8% 50.45%);
}

.enlightened,
.enlightened-small {
  background: hsl(194.74deg 60% 50%);
}

.reflected,
.reflected-small {
  background: hsl(120deg 60% 50%);
}
