.homepage-investigate_sixth {
    width: 100%;
}

.homepage-investigate_sixth-content {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage-investigate_sixth-content_text {
    position: absolute;
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepage-investigate_sixth-content_text > span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.025em;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 54px;
}

.homepage-investigate_sixth-content_text > a {
    text-decoration: none;
    width: 180px;
    height: 55px;
    background: #FEA65A;
    box-shadow: 0px 1px 6px rgba(254, 166, 90, 0.302);
    border-radius: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
    transition-duration: 0.3s;
    border: none;
}

.homepage-investigate_sixth-content_text > button:hover {
    box-shadow: 0px 6px 12px rgba(254, 166, 90, 0.302);
}

@media (max-width: 800px) {
    .homepage-investigate_sixth-content_text {
        width: 80%;
    }

    .homepage-investigate_sixth-content_text > span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        opacity: 0.8;
        margin-bottom: 22px;
    }
}