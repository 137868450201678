.user-wall-header > h1 {
  font-family: 'Minion Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #36434b;
  hyphens: auto;
  -webkit-hyphens: auto; /* For Safari */
  -moz-hyphens: auto; /* For Firefox */
  -ms-hyphens: auto; /* For Internet Explorer */
  word-wrap: break-word;
}

.user-wall-header > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #36434b;
}

@media (max-width: 750px) {
  .user-wall-header {
    width: inherit;
  }
}

@media (min-width: 750px) and (max-width: 950px) {
  .user-wall-header {
    width: 73%;
    margin: 30px auto;
  }
}
