.learn-more_first {
    width: 100%;
    margin-bottom: 156px;
}

.learn-more_first-content {
    width: 100%;
    box-sizing: border-box;
    padding: 150px 70px 0px 89px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.learn-more_first-content_image-header {
    margin-bottom: 29px;
}

.learn-more_first-content_main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.learn-more_first-content_main-header {
    max-width: 280px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 52px;
}

.learn-more_first-content_main-header_title {
    font-family: Minion Pro;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #36434B;
    margin-bottom: 29px;
}

.learn-more_first-content_main-header_title > span {
    color: #FEA65A;
}

.learn-more_first-content_main-header_subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: #36434B;
    margin-bottom: 34px;
    letter-spacing: 0.02em;
}

.learn-more_first-content_main-header_button {
    /* width: 180px !important; */
    height: 55px !important;
    background: #FEA65A !important;
    box-shadow: 0px 1px 6px rgba(254, 166, 90, 0.302) !important;
    border-radius: 10px !important;
    text-transform: none !important;
    color: #fff !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 140% !important;
    /* display: flex;
    align-items: center; */
}

.learn-more_first-content_main-header_button-image {
    margin-left: 7px;
}

.learn-more_first-content_main-images_deceased {
    max-width: 710px;
    width: 100%;
}

.learn-more_first-content_main-images {
    display: flex;
    align-items: center;
    position: relative;
}

.learn-more_first-content_main-images_upper {
    position: absolute;
    z-index: -1;
    top: -40%;
    right: -70px;
}

.learn-more_first-content_main-images_bottom {
    position: absolute;
    bottom: -16%;
    left: -13%;
    z-index: -1;
}

.learn-more_first-content_main-images_relations {
    position: absolute;
    bottom: -7%;
    left: -20%;
}

.learn-more_first-content_main-images_virtue {
    position: absolute;
    right: 9px;
    top: 35%
}

.learn-more_first-content_main-header_title.mobile {
    display: none;
}

@media (max-width: 1000px) {
    .learn-more_first-content_main-images_relations {
        bottom: -10%;
        left: -18%;
    }

    .learn-more_first-content_main-images_virtue {
        right: 5px;
        top: 35%!important;
    }
}

@media (max-width: 850px) {
    .learn-more_first-content {
        padding: 0;
        padding-top: 28px;
    }

    .learn-more_first {
        margin-bottom: 120px;
    }

    .learn-more_first-content_main-header {
        padding: 0;
        margin-bottom: 56px;
        box-sizing: border-box;
        padding: 0px 16px;
        width: 100%;
        max-width: none;
    }

    .learn-more_first-content_image-header {
        align-self: flex-end;
    }

    .learn-more_first-content_main-header_button {
        align-self: center;
    }

    .learn-more_first-content_main-header_title {
        display: none;
    }

    .learn-more_first-content_main-header_title.mobile {
        display: block;
    }

    .learn-more_first-content_main-images_bottom {
        display: none;
    }

    .learn-more_first-content_main {
        flex-direction: column;
    }

    .learn-more_first-content_main-images {
        width: 100%;
    }

    .learn-more_first-content_main-images_deceased {
        width: 100%;
        max-width: none;
    }

    .learn-more_first-content_main-images_relations {
        left: 0;
        width: 52%;
    }

    .learn-more_first-content_main-images_virtue {
        right: 10px;
        top: 17%;
        width: 65%;
    }

    .learn-more_first-content_main-images_upper {
        display: none;

    }
}

@media (max-width: 650px) {
    .learn-more_first-content_main-images_virtue {
        right: 7px;
    }
}

@media (max-width: 500px) {
    .learn-more_first-content_main-images_virtue {
        right: 5px;
    }
}

@media (max-width: 400px) {
    .learn-more_first-content_main-images_virtue {
        right: 3px;
    }
}