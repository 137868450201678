/* Icon */

.notifications .icon-relative {
  position: relative;
}

.notifications .yellow-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  background: #36434b;
  border-radius: 50%;
  border: 1px solid white;
  font-size: 10px;
  font-weight: 600;
  color: white;
  text-align: center;
}

.notification-date-time {
  font-size: 11px;
  font-family: monospace;
  color: #fea65a;
  text-align: right;
  margin: 7px 10px 10px 0px;
}

/* Popover  */
/* .MuiPopover-paper {
  overflow-y: hidden !important;
} */

.notifications-bar {
  box-sizing: border-box;
  width: 320px;
  padding-bottom: 5px;
  max-height: 300px;
  overflow-y: scroll;
}

.headline {
  padding-top: 10px;
  padding-left: 12px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
}

.headline.tabs span {
  cursor: pointer;
}
.headline span {
  display: inline-block;
  margin-right: 33px;
  padding-bottom: 3px;
}
.headline span.active {
  border-bottom: 1px solid #fea65a;
}

.notifications-bar .item {
  border-top: 1.6px solid #ebeded;
}
.notifications-bar .item.new {
  /* border-top: none; */
  background-color: rgb(254 166 90 / 10%);
}
.notifications-bar .notification-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px;
}

.disabled {
  display: none;
}

.frame {
  margin: 7px 0 7px 11px;
  background: #2a343a;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.ava-frame {
  width: 100%;
  height: 100%;
  background-image: url('../../../img/LoGo_main_notification.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.ava-frame-for-url {
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.notifications-bar .notification-card .logo223 {
  width: 70%;
  height: 100%;
  border-radius: 30%;
}
.notifications-bar .notification-card .user {
  height: 100%;
  border-radius: 50%;
}

.notifications-bar .notification-card .text {
  flex: 1;
  font-family: 'Roboto';
  font-size: 12px;
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  word-wrap: break-word;
  word-break: break-word;
}

.notifications-bar .notification-card .text .yellow {
  color: #fea65a;
}
.notifications-bar .notification-card .settings {
  text-align: center;
  margin-top: 12px;
  padding-left: 12px;
  padding-right: 5px;
}
.notifications-bar .notification-card .settings .dots {
  margin-bottom: 10px;
  cursor: pointer;
  padding-right: 4px;
}
.notifications-bar .notification-card .settings .time {
  color: #6c757d;
  font-size: 13px;
}
.notifications-bar .buttons {
  text-align: right;
  margin-right: 7px;
  padding-bottom: 10px;
}
.notifications-bar .buttons button {
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 12px;
  padding: 8px 22px;
  border: 1px solid #fea65a;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.notifications-bar .buttons .white {
  color: #fea65a;
  background: white;
  margin-right: 16px;
}
.notifications-bar .buttons .yellow {
  color: white;
  background: #fea65a;
}
.buttons_status {
  width: calc(100% - 7px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 7px;
  font-weight: 600;
}
.buttons_status.accepted {
  color: #4c8d34;
}
.buttons_status.rejected {
  color: #ff3333;
}
.buttons_status img {
  margin-right: 5px;
}
.noItems-notification {
  margin: 20px auto;
  width: fit-content;
  color: #fea65a;
}
