.add-new-image {
  border-radius: 2px;
  border: 1px solid #fea65a;
  background: #fff;
  display: flex;
  width: 138px;
  height: 138px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-new-image > span {
  color: #fea65a;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 750px) {
  .add-new-image {
    width: 100px;
    height: 100px;
  }
}
