.step-2-content-wrapper {
  display: flex;
  justify-content: center;
  margin: 70px 0px;
}

.grave-connections-item {
  min-height: 340px;
  width: 270px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #36434b;
  padding: 12px;
}

.directions {
  width: 200px;
  margin: auto 0;
}

.left-to-right,
.right-to-left {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
}

.direction-span-wrapper {
  text-align: center;
}

.direction-span-wrapper > span {
  font-family: 'Minion Pro';
  font-weight: 500;
  font-size: 17px;
  color: #36434b;
}

.save-grave-connection {
  text-align: center;
  margin: 60px 0px 0px;
}

.helper-span-second-step {
  text-align: center;
  margin: 35px 0px 40px;
}

.helper-span-second-step > span {
  font-family: 'Minion Pro';
  font-size: 18px;
  color: #36434b;
}

.save-grave-connection > button {
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fea65a;
  width: 150px;
  height: 50px;
  margin-bottom: 15px;
  font-family: 'Minion Pro';
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}

.save-grave-connection > button:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.save-grave-connection > button:disabled {
  background: #d8d8d8;
  opacity: none;
  transition: none;
}

.choose-relation-item {
  background: #f5f5f5;
  border-radius: 6px;
  width: 100% !important;
}

@media (max-width: 700px) {
  .step-2-content-wrapper {
    align-items: center;
    flex-direction: column;
  }

  .directions {
    display: flex;
    align-items: center;
    height: 150px;
    width: auto;
  }

  .left-to-right,
  .right-to-left {
    transform: rotate(90deg);
    width: 50px;
  }

  .direction-span-wrapper > span {
    word-break: break-all;
  }

  .direction-span-wrapper {
    width: 50%;
  }

  .save-grave-connection {
    text-align: center;
    margin: -25px 0px 0px;
  }
}
