.stepper-section {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.stepper-section-test {
  position: relative;
}

.stepper-section-button {
  width: 50px;
  height: 50px;
  background: #9aa8b2;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: sticky;
  font-family: 'Roboto';
  font-size: 26px;
  font-weight: 400;
  line-height: 20px;
  color: #404d56;
}

.stepper-section-button.active {
  background: #fea65a;
  color: #fff;
}

.stepper-section-button.active > svg path {
  fill: #ffffff;
  stroke: #ffffff;
}

.stepper-section-dashed {
  width: 167px;
  border: 1px solid #9aa8b2;
  margin: 0 5px;
}

.stepper-section-dashed.active {
  border: 1px solid #fea65a;
}

.stepper-section-button_number {
  position: absolute;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
  color: #fea65a;
  opacity: 0.1;
  bottom: 27px;
  right: 25px;
  z-index: 0;
}

@media screen and (max-width: 750px) {
  .stepper-section {
    margin-bottom: 28px;
  }

  .stepper-section-button {
    width: 40px;
    height: 40px;
  }

  .stepper-section-button > svg {
    width: 22px;
    height: 21px;
  }

  .stepper-section-button_number {
    font-size: 40px;
    line-height: 47px;
    bottom: 20px;
    right: 17px;
  }
}

@media screen and (max-width: 580px) {
  .stepper-section-dashed {
    width: 100px;
  }
}

@media screen and (max-width: 420px) {
  .stepper-section-dashed {
    width: 20px;
  }
}
