.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
}

.user-name {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: 1.3;
}

.user-relation {
  color: #8d9093;
  font-family: 'Roboto';
  font-size: 14px;
}

.user-role {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 1.3;
}
