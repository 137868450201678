.second-step-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
  margin-top: 33px;
  flex-wrap: wrap;
}

.first-step-back {
  border: 1px solid #404d56;
  padding: 16px 42px;
  background-color: transparent;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #404d56;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
}

.next-step-button {
  border: none;
  background-color: #404d56;
  padding: 17px 42px;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  flex: 1;
}

/* .second-step-buttons > .first-step-button {
  margin-top: 0 !important;
} */

.second-step_extra-reason-input {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0px 10px;
  border: none;
  outline: none;
  background: #f5f5f5;
  border-radius: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #36434b;
}

.MuiAutocomplete-popupIndicator {
  padding: 8px !important;
}

.input-element-list {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  margin-bottom: 38px;
}

.title {
  color: #36434b;
  font-family: 'Roboto' Condensed;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
}

/* @media screen and (max-width: 750px) {
  .first-step-back {
    width: 166px;
    height: 50px;
  }

  .first-step-button {
    width: 166px;
    height: 50px;
  }
} */
