.upload-image-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.upload-image-header > span {
  color: #19262d;
  font-family: 'Roboto' Condensed;
  font-size: 31.25px;
  font-weight: 500;
}

.upload-image-header > img {
  cursor: pointer;
  transition-duration: 0.3s;
}

.upload-image-header > img:hover {
  opacity: 0.6;
}

.upload-image-subheader {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}

.upload-image-subheader > span {
  color: #404d56;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
}

.upload-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 200px;
}

.text-container {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.text-container > span {
  color: #404d56;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

.text-subheading {
  color: #55636b;
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.cropper-modal_modal {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.cropper-modal_modal > div:last-child > div {
  border-radius: 10px !important;
}

.save-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.save-btn {
  border-radius: 8px;
  background: #404d56;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  padding: 12px 32px;
  text-transform: uppercase;
}

.save-btn:disabled {
  border-radius: 8px;
  cursor: not-allowed;
  background: #cccccc;
  color: #404d56;
  border: none;
  cursor: pointer;
  text-align: center;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  padding: 12px 32px;
  text-transform: uppercase;
}

.uploading-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 12px;
}

@media screen and (max-width: 480px) {
  .uploading-images {
    grid-template-columns: repeat(1, 1fr);
  }
}
