.container {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
}

.user-name {
  font-family: 'Roboto' Condensed;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #36434b;
  cursor: pointer;
  transition: ease 0.3s;
  width: max-content;
  border-bottom: 1px solid transparent;
}

.user-name:hover {
  border-color: #2a3237;
  color: #2a3237;
}

.loading {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
}

.reaction {
  position: absolute;
  left: 27px;
  top: 27px;
}

.btn-container {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  overflow-x: scroll;
  padding: 0px 12px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.btn-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.btn-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  padding: 6px 24px;
  color: #9f9f9f;
  background: transparent;
  transition: 0.3s ease all;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  justify-content: center;
  text-transform: capitalize;
}

.btn:hover {
  color: #36434b;
  background: #eee;
}

.active {
  background: #36434b !important;
  color: #fff !important;
}

@media screen and (min-width: 540px) {
  .btn {
    padding: 6px 32px;
  }
}
