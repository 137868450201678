.search-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
  align-items: center;
}

.search-section_logo {
  width: 68px;
  height: 54px;
  background: #2a343a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 12px;
}

.search-menu {
  display: flex;
  flex-direction: row;
  width: 285px;
}

.search-section-search {
  width: 70%;
  height: 34px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fafafa;
  position: relative;
  border: 1px solid;
  border-color: #d8d8d8;
  background-color: #fafafa;
  transition: width 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 8px;
}

@media screen and (max-width: 750px) {
  .search-section-search {
    width: 50%;
  }

  .focused {
    width: 75%;
  }

  .search-menu {
    flex-direction: column;
    width: 100%;
  }

  .search-section {
    justify-content: start;
  }
}

@media screen and (max-width: 532px) {
  .search-section_logo {
    width: 56px;
    height: 46px;
  }
}

@media screen and (max-width: 440px) {
  /* .search-section {
    width: 40%;
  } */

  .search-section_logo {
    height: 50px;
    width: 50px;
  }
}
