.sub-menu {
  /* max-width: 1280px; */
  width: 90%;
  margin: 0 auto;
  /* background: #f2f5f8; */
}

.sub-menu_button,
.sub-menu_button__response {
  width: 130px;
  height: 40px;
  text-transform: initial !important;
  color: #fea65a !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  border-radius: 10px !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 8px !important;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 24px 0px;
}

.sub-menu_button {
  position: absolute !important;
  right: 5px !important;
}

.sub-menu_button.active,
.sub-menu_button__response.active {
  background: #f5f5f5 !important;
}

.sub-menu_img {
  margin-right: 10px;
}

.MuiTab-textColorPrimary {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 15px !important;
  color: #36434b !important;
  text-transform: initial !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #36434b !important;
}

.sub-menu > div > .MuiTabs-scroller.MuiTabs-scrollable > span {
  background-color: #fea65a !important;
}

.MuiTabScrollButton-root {
  width: 0 !important;
}

.MuiTabs-flexContainer {
  justify-content: center !important;
}

.MuiBox-root-30 {
  padding: 62px 0 !important;
}

.MuiTabs-flexContainer {
  align-items: center !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 10px !important;
  }
}

@media (max-width: 800px) {
  .sub-menu_button {
    margin-left: 20px !important;
    position: inherit !important;
  }
}

@media (max-width: 750px) {
  .sub-menu_button {
    margin-left: 20px !important;
    position: inherit !important;
    display: none !important;
  }
  .MuiTabs-flexContainer {
    display: inline-table !important;
  }

  .MuiTabs-scrollButtonsDesktop {
    display: flex !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 2rem;
  }

  /* .MuiTabScrollButton-root {
    width: 20px !important;
  } */

  .button-container {
    display: flex;
  }
}
