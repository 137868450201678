.comment-box-text-area {
    width: 100%;
    min-height: 80px !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #36434B;
    padding: 15px;
    outline: none;
    resize: none;
    overflow: auto;
    border: 2px solid #e5e5e5;
    border-radius: 7px;
    margin-bottom: 10px;
    background: #FFF;
}

.comment-box-footer {
    display: flex;
    margin-bottom: 10px;
}