.upgrade-plan-prompt {
  width: 500px;
  height: 241px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upgrade-plan-prompt_cancel {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 13px;
}

.upgrade-plan-prompt_body_subheading {
  font-family: Roboto Condensed;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #19262d;
  margin-bottom: 32px;
}

.upgrade-plan-prompt_body_wrapper {
  display: block;
  max-width: 80%;
}
.upgrade-plan-prompt_body_content {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #404d56;
  margin-bottom: 24px;
}

.upgrade-plan-prompt_body_content > span > a {
  color: #3ca0ff;
  text-decoration: none;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.rootVerify {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #404d56;
  color: #fff;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 9px 24px;
}

.rootDiscard {
  cursor: pointer;
  border: none;
  padding: 8px 24px;
  border-radius: 8px;
  border: 1px solid #404d56;
  background: #fff;
  color: #404d56;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}

@media (max-width: 800px) {
  .upgrade-plan-prompt {
    width: 94%;
  }

  .upgrade-plan-prompt_body_subheading {
    font-size: 21px;
  }
}
